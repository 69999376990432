import React, { useEffect, useState, useContext } from "react";
import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Autocomplete, TextField } from '@mui/material';
import { UserContext } from '../context/UserProvider';
import BuscarTerceros from '../componentes/Buscar2/Terceros'
import BuscarCentrodeCosto from '../componentes/Buscar2/CentrodeCosto'
import BuscarTipodeDocumento from '../componentes/Buscar2/TipodeDocumento'
import BuscarCuentas from '../componentes/Buscar2/Cuentas'
import BuscarProductos from '../componentes/Buscar2/Productos'

const Filtro = (props) => {
  const { user, cerrarSession } = useContext(UserContext);
  const [usuario, setDataUser] = useState([]);
  const [cajas, setcajas] = useState([]);
  const [centrocostos, setcentrocostos] = useState([]);
  const [comprobantes, setcomprobantes] = useState([]);
  const [abrirterceros, setabrirterceros] = useState(false);
  const [abrirterceros2, setabrirterceros2] = useState(false);

  const [abrircuenta, setabrircuenta] = useState(false);
  const [abrircuenta2, setabrircuenta2] = useState(false);

  const [abrircentrodecosto, setabrircentrodecosto] = useState(false);
  const [abrircentrodecosto2, setabrircentrodecosto2] = useState(false);

  const [abrirtipodoc, setabrirtipodoc] = useState(false);
  const [abrirtipodoc2, setabrirtipodoc2] = useState(false);

  const [abrirproducto, setabrirproducto] = useState(false);
  const [abrirproducto2, setabrirproducto2] = useState(false);

  const [tipoacu, settipoacu] = useState([{
    codigo: "ventas",
    nombre: "Ventas"
  },
  {
    codigo: "compras",
    nombre: "Compras"
  },
  {
    codigo: "entradasespeciales",
    nombre: "Entradas Especiales"
  },
  {
    codigo: "salidasespeciales",
    nombre: "Salidas Especiales"
  }]);
  const [mayorarticulo, setmayorarticulo] = useState([{
    codigo: "mayor0",
    nombre: "Mayor a Cero"
  },
  {
    codigo: "mayorigual0",
    nombre: "Mayor o Igual a Cero"
  },
  {
    codigo: "menor0",
    nombre: "Menor de Cero"
  },
  {
    codigo: "menorigual0",
    nombre: "Mayor o Igual a Cero"
  },
  {
    codigo: "igual0",
    nombre: "Igual a Cero"
  },
  {
    codigo: "diferente0",
    nombre: "Diferente de Cero"
  }]);
  const [tipotercero, settipotercero] = useState([{
    codigo: "cliente",
    nombre: "cliente"
  },
  {
    codigo: "proveedor",
    nombre: "proveedor"
  }]);
  const [tipodocumentos, settipodocumentos] = useState([]);
  const [terceros, setterceros] = useState([]);
  const [vendedores, setvendedores] = useState([]);
  const [Plandecuentass, setPlandecuentass] = useState([]);
  const [Inarticuloss, setInarticuloss] = useState([]);
  const [clasificaciones, setclasificaciones] = useState([]);
  const [Sucursals, setSucursals] = useState([]);
  const [Conplantillainformes, setConplantillainformes] = useState([]);
  const [Bodegas, setBodegas] = useState([]);
  const [codtercinicial, setcodtercinicial] = useState("");
  const [codtercfinal, setcodtercfinal] = useState("");



  useEffect(() => {
    let dt = JSON.parse(user)
    setDataUser(dt)
    obtenerPlantillaEncModels('');
    buscarvendedor();
    // buscarterceros();
    buscartipodocumentos();
    obtenerCajas();
    obtenerCtcentrocostos('');
    obtenerComprobantes('');
    obtenerPlandecuentass('');
    // obtenerInarticuloss('');
    obtenerConclasiarticuloss('');
    obtenerSucursals('');
    obtenerBodegas('')
  }, [])

  const obtenerPlantillaEncModels = async (coincidencia) => {
    try {


      let dt = JSON.parse(user)
      let response = await fetch(props.servidor + '/PlantillaEncModelapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

      if (response.ok) {
        let data = await response.json()
        setConplantillainformes(data)
        // setPendiente(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const obtenerBodegas = async (coincidencia) => {
    try {


      let dt = JSON.parse(user)
      let response = await fetch(props.servidor + '/Bodegaapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

      if (response.ok) {
        let data = await response.json()
        setBodegas(data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const obtenerConclasiarticuloss = async (coincidencia) => {
    try {


      let dt = JSON.parse(user)
      let response = await fetch(props.servidor + '/Clasiarticulosapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');
      if (response.ok) {
        let data = await response.json()

        setclasificaciones(data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const obtenerSucursals = async (coincidencia) => {
    try {


      let dt = JSON.parse(user)
      let response = await fetch(props.servidor + '/Sucursalapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

      if (response.ok) {
        let data = await response.json()
        setSucursals(data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const obtenerPlandecuentass = async (coincidencia) => {
    try {


      let dt = JSON.parse(user)
      let response = await fetch(props.servidor + '/Plandecuentasapi?llave=' + dt.usuario.llaveempresa + '');

      if (response.ok) {
        let data = await response.json()
        setPlandecuentass(data)
        // setPendiente(false)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const obtenerCtcentrocostos = async (coincidencia) => {
    try {


      let dt = JSON.parse(user)
      let response = await fetch(props.servidor + '/Ctcentrocostoapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

      if (response.ok) {
        let data = await response.json()
        setcentrocostos(data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const obtenerComprobantes = async (coincidencia) => {
    try {


      let dt = JSON.parse(user)
      let response = await fetch(props.servidor + '/Ctcomprobantesapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

      if (response.ok) {
        let data = await response.json()
        setcomprobantes(data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const buscarvendedor = async () => {
    try {


      let dt = JSON.parse(user)
      let response = await fetch(props.servidor + '/Vendedorapi/?llave=' + dt.usuario.llaveempresa + '');

      if (response.ok) {
        let data = await response.json()
        setvendedores(data)
      }
    } catch (error) {
      console.log(error)
    }

  }
  const buscarterceros = async () => {
    try {


      let dt = JSON.parse(user)
      let response = await fetch(props.servidor + '/Caclientesapi/?llave=' + dt.usuario.llaveempresa + '');

      if (response.ok) {
        let data = await response.json()
        setterceros(data)
      }
    } catch (error) {
      console.log(error)
    }

  }
  const buscartipodocumentos = async () => {
    try {


      let dt = JSON.parse(user)
      let response = await fetch(props.servidor + '/Fatipodocapi/?llave=' + dt.usuario.llaveempresa + '');

      if (response.ok) {
        let data = await response.json()
        settipodocumentos(data)
      }
    } catch (error) {
      console.log(error)
    }

  }
  const obtenerInarticuloss = async (coincidencia) => {
    try {


      let dt = JSON.parse(user)
      let response = await fetch(props.servidor + '/Inarticulosapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

      if (response.ok) {
        let data = await response.json()
        setInarticuloss(data)
        // setPendiente(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const obtenerCajas = async () => {
    try {


      let dt = JSON.parse(user)
      let response = await fetch(props.servidor + '/ConcajaApi/?llave=' + dt.usuario.llaveempresa + '');

      if (response.ok) {
        let data = await response.json()
        setcajas(data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal style={{ maxWidth: '1600px', width: '85%' }} size={"lg"} isOpen={props.verModal} >
      <ModalBody>
        <Row>
          {props.tipoinformepyg != undefined &&
            <Col sm={6}  >
              <div className="card  shadow" style={{ borderLeft: '0.25rem solid #28a745' }}>
                <div className="card-body p-2">
                  <div>Tipo Informe</div>
                  <div className="row no-gutters align-items-center">
                    <Col sm={6}>
                      <Autocomplete
                        onChange={(e, value) => {
                          props.settipoinformepyg(value ? value.encabezado.codigo.replace(/^-/, '') : "-")
                        }}
                        disablePortal
                        options={Conplantillainformes}
                        getOptionLabel={(clasi) => clasi.encabezado.codigo + " - " + clasi.encabezado.nombre}
                        sx={{ width: 250 }}
                        value={Conplantillainformes.filter(p => p.encabezado.codigo == props.tipoinformepyg)[0]}
                        renderInput={(params) => <TextField {...params} label="" />}
                      />
                    </Col>

                  </div>
                </div>
              </div>
            </Col>}
          {props.cuentainicioseleccionado != undefined &&
            // <Col sm={6}  >
            //   <div className="card  shadow" style={{ borderLeft: '0.25rem solid #28a745' }}>
            //     <div className="card-body p-2">
            //       <div>{props.tipo ? props.tipo == "balancegeneral" ? "Cuentas de Utilidad y Perdida" : "Cuenta" : "Cuenta"}</div>
            //       <div className="row no-gutters align-items-center">
            //         <Col sm={6}>
            //           <Autocomplete
            //             onChange={(e, value) => {
            //               props.setcuentainicioseleccionado(value ? value.codcuenta : "-")
            //             }}
            //             disablePortal
            //             options={Plandecuentass}
            //             getOptionLabel={(clasi) => clasi.codcuenta + " - " + clasi.descripcion}
            //             sx={{ width: 250 }}
            //             value={Plandecuentass.filter(p => p.codcuenta == props.cuentainicioseleccionado)[0]}
            //             renderInput={(params) => <TextField {...params} label="Inicio" />}
            //           />
            //         </Col>
            //         <Col sm={6}>
            //           <Autocomplete
            //             onChange={(e, value) => {
            //               props.setcuentafinseleccionado(value ? value.codcuenta : "-")
            //             }}
            //             disablePortal
            //             options={Plandecuentass}
            //             getOptionLabel={(clasi) => clasi.codcuenta + " - " + clasi.descripcion}
            //             sx={{ width: 250 }}
            //             value={Plandecuentass.filter(p => p.codcuenta == props.cuentafinseleccionado)[0]}
            //             renderInput={(params) => <TextField {...params} label="Fin" />}
            //           />
            //         </Col>
            //       </div>
            //     </div>
            //   </div>
            // </Col>
            <Col sm={6} >
              <div className="card  shadow" style={{ borderLeft: '0.25rem solid #28a745' }}>
                <div className="card-body p-2">
                  <div>Cuenta</div>
                  <div className="row no-gutters align-items-center">
                    <Col sm={6}>
                      <Input style={{ width: '200px' }} type="text" onKeyUp={(e) => {
                        if (e.key == "Enter" || e.target.value.includes('?')) {
                          setabrircuenta(true)
                        }
                      }} onChange={(e) => {
                        props.setcuentainicioseleccionado(e.target.value != "" ? e.target.value.replace(/^-/, '') : "-")
                      }} placeholder="Presione Enter Para Buscar" name="acreedor" value={props.cuentainicioseleccionado} />
                    </Col>
                    <Col sm={6}>
                      <Input style={{ width: '200px' }} type="text" onKeyUp={(e) => {
                        if (e.key == "Enter" || e.target.value.includes('?')) {
                          setabrircuenta2(true)
                        }
                      }} onChange={(e) => {
                        props.setcuentafinseleccionado(e.target.value != "" ? e.target.value.replace(/^-/, '') : "-")
                      }} placeholder="Presione Enter Para Buscar" name="acreedor" value={props.cuentafinseleccionado} />
                    </Col>
                  </div>
                </div>
              </div>
            </Col>
          }
          {props.nivel != undefined &&
            <Col sm={6}  >
              <div className="card  shadow" style={{ borderLeft: '0.25rem solid #28a745' }}>
                <div className="card-body p-2">
                  <div>Nivel</div>
                  <div className="row no-gutters align-items-center">
                    <Col sm={6}>
                      <FormGroup>
                        <Input value={props.nivel} bsSize="sm" placeholder="Inicio" type="text" className="form-control form-control-sm"
                          onChange={(e) => {
                            props.setnivel(e.target.value)
                          }}></Input>

                      </FormGroup>

                    </Col>
                  </div>
                </div>
              </div>
            </Col>}
          {props.mayora != undefined &&
            <Col sm={6}  >
              <div className="card  shadow" style={{ borderLeft: '0.25rem solid #28a745' }}>
                <div className="card-body p-2">
                  <div>Articulos Con existencia</div>
                  <div className="row no-gutters align-items-center">
                    <Col sm={6}>
                      <Autocomplete
                        onChange={(e, value) => {
                          props.setmayora(value ? value.codigo.replace(/^-/, '') : "-")
                        }}
                        disablePortal
                        options={mayorarticulo}
                        getOptionLabel={(clasi) => clasi.nombre}
                        sx={{ width: 250 }}
                        value={mayorarticulo.filter(p => p.codigo == props.mayora)[0]}
                        renderInput={(params) => <TextField {...params} label="Inicio" />}
                      />
                    </Col>
                  </div>
                </div>
              </div>
            </Col>}
          {props.tipotercero != undefined &&
            <Col sm={6}  >
              <div className="card  shadow" style={{ borderLeft: '0.25rem solid #28a745' }}>
                <div className="card-body p-2">
                  <div>Tipo Tercero</div>
                  <div className="row no-gutters align-items-center">
                    <Col sm={6}>
                      <Autocomplete
                        onChange={(e, value) => {
                          props.settipotercero(value ? value.codigo.replace(/^-/, '') : "-")
                        }}
                        disablePortal
                        options={tipotercero}
                        getOptionLabel={(clasi) => clasi.nombre}
                        sx={{ width: 250 }}
                        value={tipotercero.filter(p => p.codigo == props.tipotercero)[0]}
                        renderInput={(params) => <TextField {...params} label="Inicio" />}
                      />
                    </Col>
                  </div>
                </div>
              </div>
            </Col>}

          {props.tipoacumulado != undefined &&
            <Col sm={6}  >
              <div className="card  shadow" style={{ borderLeft: '0.25rem solid #28a745' }}>
                <div className="card-body p-2">
                  <div>Seleccion</div>
                  <div className="row no-gutters align-items-center">
                    <Col sm={6}>
                      <Autocomplete
                        onChange={(e, value) => {
                          props.settipoacumulado(value ? value.codigo.replace(/^-/, '') : "-")
                        }}
                        disablePortal
                        options={tipoacu}
                        getOptionLabel={(clasi) => clasi.nombre}
                        sx={{ width: 250 }}
                        value={tipoacu.filter(p => p.codigo == props.tipoacumulado)[0]}
                        renderInput={(params) => <TextField {...params} label="Inicio" />}
                      />
                    </Col>
                  </div>
                </div>
              </div>
            </Col>}
          {props.cajainicioseleccionada != undefined &&
            <Col sm={6}  >
              <div className="card  shadow" style={{ borderLeft: '0.25rem solid #28a745' }}>
                <div className="card-body p-2">
                  <div>Caja</div>
                  <div className="row no-gutters align-items-center">
                    <Col sm={6}>
                      <Autocomplete
                        onChange={(e, value) => {
                          props.setcajainicioseleccionada(value ? value.codigo.replace(/^-/, '') : "-")
                        }}
                        disablePortal
                        options={cajas}
                        getOptionLabel={(clasi) => clasi.nombre}
                        sx={{ width: 250 }}
                        value={cajas.filter(p => p.codigo == props.cajainicioseleccionada)[0]}
                        renderInput={(params) => <TextField {...params} label="Inicio" />}
                      />
                    </Col>
                    <Col sm={6}>
                      <Autocomplete
                        onChange={(e, value) => {
                          props.setcajafinseleccionada(value ? value.codigo.replace(/^-/, '') : "-")
                        }}
                        disablePortal
                        options={cajas}
                        getOptionLabel={(clasi) => clasi.nombre}
                        sx={{ width: 250 }}
                        value={cajas.filter(p => p.codigo == props.cajafinseleccionada)[0]}
                        renderInput={(params) => <TextField {...params} label="Fin" />}
                      />
                    </Col>
                  </div>
                </div>
              </div>
            </Col>}
          {props.referenciainicioseleccionado != undefined &&
            // <Col sm={6} >
            //   <div className="card  shadow" style={{ borderLeft: '0.25rem solid #28a745' }}>
            //     <div className="card-body p-2">
            //       <div>Referencia</div>
            //       <div className="row no-gutters align-items-center">
            //         <Col sm={6}>
            //           <Autocomplete
            //             onChange={(e, value) => {
            //               props.setreferenciainicioseleccionado(value ? value.codigo : "-")
            //             }}
            //             disablePortal
            //             options={Inarticuloss}
            //             getOptionLabel={(clasi) => clasi.codigo + " - " + clasi.nombre}
            //             sx={{ width: 250 }}
            //             value={Inarticuloss.filter(p => p.codigo == props.referenciainicioseleccionado)[0]}
            //             renderInput={(params) => <TextField {...params} label="Inicio" />}
            //           />
            //         </Col>
            //         <Col sm={6}>
            //           <Autocomplete
            //             onChange={(e, value) => { props.setreferenciafinseleccionado(value ? value.codigo : "-") }}
            //             disablePortal
            //             options={Inarticuloss}
            //             getOptionLabel={(clasi) => clasi.codigo + " - " + clasi.nombre}
            //             sx={{ width: 250 }}
            //             value={Inarticuloss.filter(p => p.codigo == props.referenciafinseleccionado)[0]}
            //             renderInput={(params) => <TextField {...params} label="Fin" />}
            //           />
            //         </Col>
            //       </div>
            //     </div>
            //   </div>
            // </Col>

            <Col sm={6} >
              <div className="card  shadow" style={{ borderLeft: '0.25rem solid #28a745' }}>
                <div className="card-body p-2">
                  <div>Producto</div>
                  <div className="row no-gutters align-items-center">
                    <Col sm={6}>
                      <Input style={{ width: '200px' }} type="text" onKeyUp={(e) => {
                        if (e.key == "Enter" || e.target.value.includes('?')) {
                          setabrirproducto(true)
                        }
                      }} onChange={(e) => {
                        props.setreferenciainicioseleccionado(e.target.value != "" ? e.target.value.replace(/^-/, '') : "-")
                      }} placeholder="Presione Enter Para Buscar" name="acreedor" value={props.referenciainicioseleccionado} />
                    </Col>
                    <Col sm={6}>
                      <Input style={{ width: '200px' }} type="text" onKeyUp={(e) => {
                        if (e.key == "Enter" || e.target.value.includes('?')) {
                          setabrirproducto2(true)
                        }
                      }} onChange={(e) => {

                        props.setreferenciafinseleccionado(e.target.value != "" ? e.target.value.replace(/^-/, '') : "-")
                      }}

                        placeholder="Presione Enter Para Buscar" name="acreedor" value={props.referenciafinseleccionado} />
                    </Col>
                  </div>
                </div>
              </div>
            </Col>

          }
          {props.tipodocumentoinicioseleccionado != undefined &&
            <Col sm={6} >
              <div className="card  shadow" style={{ borderLeft: '0.25rem solid #28a745' }}>
                <div className="card-body p-2">
                  <div>Tipo de Documento</div>
                  <div className="row no-gutters align-items-center">
                    <Col sm={6}>
                      <Autocomplete
                        onChange={(e, value) => {
                          props.settipodocumentoinicioseleccionado(value ? value.codigo.replace(/^-/, '') : "-")
                        }}
                        disablePortal
                        options={tipodocumentos}
                        getOptionLabel={(clasi) => clasi.codigo + " - " + clasi.descripcion}
                        sx={{ width: 250 }}
                        value={tipodocumentos.filter(p => p.codigo == props.tipodocumentoinicioseleccionado)[0]}
                        renderInput={(params) => <TextField {...params} label="Inicio" />}
                      />
                    </Col>
                    <Col sm={6}>
                      <Autocomplete
                        onChange={(e, value) => { props.settipodocumentofinseleccionado(value ? value.codigo.replace(/^-/, '') : "-") }}
                        disablePortal
                        options={tipodocumentos}
                        getOptionLabel={(clasi) => clasi.descripcion+ " - " + clasi.descripcion}
                        sx={{ width: 250 }}
                        value={tipodocumentos.filter(p => p.codigo == props.tipodocumentofinseleccionado)[0]}
                        renderInput={(params) => <TextField {...params} label="Fin" />}
                      />
                    </Col>
                  </div>
                </div>
              </div>
            </Col>}
          {props.documentoinicioseleccionado != undefined &&
            <Col sm={6} >
              <div className="card  shadow" style={{ borderLeft: '0.25rem solid #28a745' }}>
                <div className="card-body p-2">
                  <div>Documento</div>
                  <div className="row no-gutters align-items-center">
                    <Col sm={6}>
                      <FormGroup>
                        <Input bsSize="sm" placeholder="Inicio" type="text" className="form-control form-control-sm"
                          onChange={(e) => { }}></Input>

                      </FormGroup>

                    </Col>
                    <Col sm={6}>
                      <FormGroup>
                        <Input bsSize="sm" placeholder="Fin" type="text" className="form-control form-control-sm"
                          onChange={(e) => { }}></Input>

                      </FormGroup>
                    </Col>
                  </div>
                </div>
              </div>
            </Col>}
          {props.terceroinicioseleccionado != undefined &&


            <Col sm={6} >
              <div className="card  shadow" style={{ borderLeft: '0.25rem solid #28a745' }}>
                <div className="card-body p-2">
                  <div>Tercero</div>
                  <div className="row no-gutters align-items-center">
                    <Col sm={6}>
                      <Input style={{ width: '200px' }} type="text" onKeyUp={(e) => {
                        if (e.key == "Enter" || e.target.value.includes('?')) {


                          setabrirterceros(true)
                        }
                      }} onChange={(e) => {
                        props.setterceroinicioseleccionado(e.target.value != "" ? e.target.value.replace(/^-/, '') : "-")
                      }} placeholder="Presione Enter Para Buscar" name="acreedor" value={props.terceroinicioseleccionado} />
                    </Col>
                    <Col sm={6}>
                      <Input style={{ width: '200px' }} type="text" onKeyUp={(e) => {
                        if (e.key == "Enter" || e.target.value.includes('?')) {
                          setabrirterceros2(true)
                        }
                      }} onChange={(e) => {
                        props.settercerofinseleccionado(e.target.value != "" ? e.target.value.replace(/^-/, '') : "-")
                      }}

                        placeholder="Presione Enter Para Buscar" name="acreedor" value={props.tercerofinseleccionado} />
                    </Col>
                  </div>
                </div>
              </div>
            </Col>

          }
          {props.vendedorinicioseleccionado != undefined &&
            <Col sm={6} >
              <div className="card  shadow" style={{ borderLeft: '0.25rem solid #28a745' }}>
                <div className="card-body p-2">
                  <div>Vendedor</div>
                  <div className="row no-gutters align-items-center">
                    <Col sm={6}>
                      <Autocomplete
                        onChange={(e, value) => { props.setvendedorinicioseleccionado(value ? value.codigo.replace(/^-/, '') : "-") }}
                        disablePortal
                        options={vendedores}
                        getOptionLabel={(clasi) => clasi.nombre}
                        sx={{ width: 250 }}
                        value={vendedores.filter(p => p.codigo == props.vendedorinicioseleccionado)[0]}
                        renderInput={(params) => <TextField {...params} label="Inicio" />}
                      />
                    </Col>
                    <Col sm={6}>
                      <Autocomplete
                        onChange={(e, value) => { props.setvendedorfinseleccionado(value ? value.codigo.replace(/^-/, '') : "-") }}
                        disablePortal
                        options={vendedores}
                        getOptionLabel={(clasi) => clasi.nombre}
                        sx={{ width: 250 }}
                        value={vendedores.filter(p => p.codigo == props.vendedorfinseleccionado)[0]}
                        renderInput={(params) => <TextField {...params} label="Fin" />}
                      />
                    </Col>
                  </div>
                </div>
              </div>
            </Col>}
          {props.centrodecostoinicioseleccionado != undefined &&
            <Col sm={6}  >
              <div className="card  shadow" style={{ borderLeft: '0.25rem solid #28a745' }}>
                <div className="card-body p-2">
                  <div>Centro de Costo</div>
                  <div className="row no-gutters align-items-center">
                    <Col sm={6}>
                      <Autocomplete
                        onChange={(e, value) => {
                          props.setcentrodecostoinicioseleccionado(value ? value.codigo.replace(/^-/, '') : "-")
                        }}
                        disablePortal
                        options={centrocostos}
                        getOptionLabel={(clasi) => clasi.codigo}
                        sx={{ width: 250 }}
                        value={centrocostos.filter(p => p.codigo == props.centrodecostoinicioseleccionado)[0]}
                        renderInput={(params) => <TextField {...params} label="Inicio" />}
                      />
                    </Col>
                    <Col sm={6}>
                      <Autocomplete
                        onChange={(e, value) => {
                          props.setcentrodecostofinseleccionado(value ? value.codigo.replace(/^-/, '') : "-")
                        }}
                        disablePortal
                        options={centrocostos}
                        getOptionLabel={(clasi) => clasi.codigo}
                        sx={{ width: 250 }}
                        value={centrocostos.filter(p => p.codigo == props.centrodecostofinseleccionado)[0]}
                        renderInput={(params) => <TextField {...params} label="Fin" />}
                      />
                    </Col>
                  </div>
                </div>
              </div>
            </Col>}
          {props.comprobanteinicioseleccionado != undefined &&
            <Col sm={6}  >
              <div className="card  shadow" style={{ borderLeft: '0.25rem solid #28a745' }}>
                <div className="card-body p-2">
                  <div>Comprobante</div>
                  <div className="row no-gutters align-items-center">
                    <Col sm={6}>
                      <Autocomplete
                        onChange={(e, value) => {
                          props.setcomprobanteinicioseleccionado(value ? value.codigo.replace(/^-/, '') : "-")
                        }}
                        disablePortal
                        options={comprobantes}
                        getOptionLabel={(clasi) => clasi.codigo + " - " + clasi.nombre}
                        sx={{ width: 250 }}
                        value={comprobantes.filter(p => p.codigo == props.setcomprobanteinicioseleccionado)[0]}
                        renderInput={(params) => <TextField {...params} label="Inicio" />}
                      />
                    </Col>
                    <Col sm={6}>
                      <Autocomplete
                        onChange={(e, value) => {
                          props.setcomprobantefinseleccionado(value ? value.codigo.replace(/^-/, '') : "-")
                        }}
                        disablePortal
                        options={comprobantes}
                        getOptionLabel={(clasi) => clasi.codigo + " - " + clasi.nombre}
                        sx={{ width: 250 }}
                        value={comprobantes.filter(p => p.codigo == props.setcomprobantefinseleccionado)[0]}
                        renderInput={(params) => <TextField {...params} label="Fin" />}
                      />
                    </Col>
                  </div>
                </div>
              </div>
            </Col>}
          {props.clasificacionarticulo1 != undefined &&
            <Col sm={6}  >
              <div className="card  shadow" style={{ borderLeft: '0.25rem solid #28a745' }}>
                <div className="card-body p-2">
                  <div>Clas 1</div>
                  <div className="row no-gutters align-items-center">
                    <Col sm={6}>
                      <Autocomplete
                        onChange={(e, value) => {
                          props.setclasificacionarticulo1(value ? value.codigo.replace(/^-/, '') : "-")
                        }}
                        disablePortal
                        options={clasificaciones.filter(p => p.conclasificacion == 'Clasificación1')}
                        getOptionLabel={(clasi) => clasi.codigo + " - " + clasi.nombre}
                        sx={{ width: 250 }}
                        value={clasificaciones.filter(p => p.codigo == props.clasificacionarticulo1 && p.conclasificacion == 'Clasificación1')[0]}
                        renderInput={(params) => <TextField {...params} label="Clasificacion 1" />}
                      />
                    </Col>

                  </div>
                </div>
              </div>
            </Col>}
          {props.clasificacionarticulo2 != undefined &&
            <Col sm={6}  >
              <div className="card  shadow" style={{ borderLeft: '0.25rem solid #28a745' }}>
                <div className="card-body p-2">
                  <div>Clas 2</div>
                  <div className="row no-gutters align-items-center">
                    <Col sm={6}>
                      <Autocomplete
                        onChange={(e, value) => {
                          props.setclasificacionarticulo2(value ? value.codigo.replace(/^-/, '') : "-")
                        }}
                        disablePortal
                        options={clasificaciones.filter(p => p.conclasificacion == 'Clasificación2')}
                        getOptionLabel={(clasi) => clasi.codigo}
                        sx={{ width: 250 }}
                        value={clasificaciones.filter(p => p.codigo == props.clasificacionarticulo2 && p.conclasificacion == 'Clasificación2')[0]}
                        renderInput={(params) => <TextField {...params} label="Clasificacion 2" />}
                      />
                    </Col>

                  </div>
                </div>
              </div>
            </Col>}
          {props.sucursal != undefined &&
            <Col sm={6}  >
              <div className="card  shadow" style={{ borderLeft: '0.25rem solid #28a745' }}>
                <div className="card-body p-2">
                  <div>Sucursal</div>
                  <div className="row no-gutters align-items-center">
                    <Col sm={6}>
                      <Autocomplete
                        onChange={(e, value) => {
                          props.setsucursal(value ? value.nit.replace(/^-/, '') : "-")
                        }}
                        disablePortal
                        options={Sucursals}
                        getOptionLabel={(clasi) => clasi.nit}
                        sx={{ width: 250 }}
                        value={Sucursals.filter(p => p.nit == props.sucursal)[0]}
                        renderInput={(params) => <TextField {...params} label="Sucursal" />}
                      />
                    </Col>

                  </div>
                </div>
              </div>
            </Col>}
          {props.bodega != undefined &&
            <Col sm={6}  >
              <div className="card  shadow" style={{ borderLeft: '0.25rem solid #28a745' }}>
                <div className="card-body p-2">
                  <div>Bodega</div>
                  <div className="row no-gutters align-items-center">
                    <Col sm={6}>
                      <Autocomplete
                        onChange={(e, value) => {
                          props.setbodega(value ? value.codigo.replace(/^-/, '') : "-")
                        }}
                        disablePortal
                        options={Bodegas}
                        getOptionLabel={(clasi) => clasi.codigo+" - "+clasi.nombre}
                        sx={{ width: 250 }}
                        value={Bodegas.filter(p => p.codigo == props.bodega)[0]}
                        renderInput={(params) => <TextField {...params} label="Bodega" />}
                      />
                    </Col>

                  </div>
                </div>
              </div>
            </Col>}
          {props.coincidencianombre != undefined &&
            <Col sm={6}  >
              <div className="card  shadow" style={{ borderLeft: '0.25rem solid #28a745' }}>
                <div className="card-body p-2">
                  <div>Coincidencia</div>
                  <div className="row no-gutters align-items-center">
                    <Col sm={6}>
                      <Input onChange={(e) => {
                        props.setcoincidencianombre(e.target.value)
                      }} />

                    </Col>

                  </div>
                </div>
              </div>
            </Col>}
          {props.clasificaciontercero1 != undefined &&
            <Col sm={6}  >
              <div className="card  shadow" style={{ borderLeft: '0.25rem solid #28a745' }}>
                <div className="card-body p-2">
                  <div>Clasificacion 1</div>
                  <div className="row no-gutters align-items-center">
                    <Col sm={6}>
                      <Input onChange={(e) => {
                        props.setclasificaciontercero1(e.target.value)
                      }} />

                    </Col>

                  </div>
                </div>
              </div>
            </Col>}

          {props.ciudadadminimpuestos != undefined &&
            <Col sm={6}  >
              <div className="card  shadow" style={{ borderLeft: '0.25rem solid #28a745' }}>
                <div className="card-body p-2">
                  <div>Ciudad Admnistracion Impuestos</div>
                  <div className="row no-gutters align-items-center">
                    <Col sm={6}>
                      <Input onChange={(e) => {
                        props.setciudadadminimpuestos(e.target.value)
                      }} />

                    </Col>

                  </div>
                </div>
              </div>
            </Col>}
          {props.codigoadminimpuestos != undefined &&
            <Col sm={6}  >
              <div className="card  shadow" style={{ borderLeft: '0.25rem solid #28a745' }}>
                <div className="card-body p-2">
                  <div>Codigo Admnistracion Impuestos</div>
                  <div className="row no-gutters align-items-center">
                    <Col sm={6}>
                      <Input onChange={(e) => {
                        props.setcodigoadminimpuestos(e.target.value)
                      }} />

                    </Col>

                  </div>
                </div>
              </div>
            </Col>}
          {props.manejaloteencabezado != undefined &&
            <Col sm={6}  >
              <div className="card  shadow" style={{ borderLeft: '0.25rem solid #28a745' }}>
                <div className="card-body p-2">
                  <FormGroup>
                    <Input
                      type='checkbox'
                      checked={props.manejaloteencabezado}
                      value={props.manejaloteencabezado}
                      onClick={(e) => {
                        props.setmanejaloteencabezado(!props.manejaloteencabezado)
                      }}
                    />
                    <Label check>Lote encabezado</Label>
                    {/* <Label style={{ fontSize: '13px' }}>Impo Consumo</Label>
                                       <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="imptosn" value={Fatipodoc.imptosn}  >
                                           <option value="no">No</option>
                                           <option value="si">Si</option>
                                       </Input> */}
                  </FormGroup>
                </div>
              </div>
            </Col>}
          {props.manejaloteporproducto != undefined &&
            <Col sm={6}  >
              <div className="card  shadow" style={{ borderLeft: '0.25rem solid #28a745' }}>
                <div className="card-body p-2">
                  <FormGroup>
                    <Input
                      type='checkbox'
                      checked={props.manejaloteporproducto}
                      value={props.manejaloteporproducto}
                      onClick={(e) => {
                        props.setmanejaloteporproducto(!props.manejaloteporproducto)
                      }}
                    />
                    <Label check>Lote Producto</Label>
                    {/* <Label style={{ fontSize: '13px' }}>Impo Consumo</Label>
                                       <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="imptosn" value={Fatipodoc.imptosn}  >
                                           <option value="no">No</option>
                                           <option value="si">Si</option>
                                       </Input> */}
                  </FormGroup>
                </div>
              </div>
            </Col>}

          <BuscarTerceros tituloencabezado={"Buscar Cuentas"} abrir={abrirterceros} servidor={props.servidor} setabrirbanco={(ab) => { setabrirterceros(ab) }} seleccionar={(value) => {
            // handleChangegrillafaencmovi('', value, 'acreedor', 'codigo')
            props.setterceroinicioseleccionado(value ? value.codigo.replace(/^-/, '') : "-")
            setabrirterceros(false)
            setcodtercinicial(value ? value.codigo : "")
            // buscarterceronombre(value.codigo)
          }} />
          <BuscarTerceros tituloencabezado={"Buscar Cuentas"} abrir={abrirterceros2} servidor={props.servidor} setabrirbanco={(ab) => { setabrirterceros2(ab) }} seleccionar={(value) => {
            // handleChangegrillafaencmovi('', value, 'acreedor', 'codigo')
            props.settercerofinseleccionado(value ? value.codigo.replace(/^-/, '') : "-")
            setabrirterceros2(false)
            setcodtercinicial(value ? value.codigo : "")
            // buscarterceronombre(value.codigo)
          }} />
          <BuscarCuentas tituloencabezado={"Buscar Cuentas"} abrir={abrircuenta} servidor={props.servidor} setabrirbanco={(ab) => { setabrircuenta(ab) }} seleccionar={(value) => {
            // handleChangegrillafaencmovi('', value, 'acreedor', 'codigo')
            props.setcuentainicioseleccionado(value ? value.codcuenta.replace(/^-/, '') : "-")
            setabrircuenta(false)
            // setcodtercinicial(value ? value.codigo : "")
            // buscarterceronombre(value.codigo)
          }} />
          <BuscarCuentas tituloencabezado={"Buscar Cuentas"} abrir={abrircuenta2} servidor={props.servidor} setabrirbanco={(ab) => { setabrircuenta2(ab) }} seleccionar={(value) => {
            // handleChangegrillafaencmovi('', value, 'acreedor', 'codigo')
            props.setcuentafinseleccionado(value ? value.codcuenta.replace(/^-/, '') : "-")
            setabrircuenta2(false)
            // setcodtercinicial(value ? value.codigo : "")
            // buscarterceronombre(value.codigo)
          }} />
          <BuscarProductos tituloencabezado={"Buscar Cuentas"} abrir={abrirproducto} servidor={props.servidor} setabrirbanco={(ab) => { setabrirproducto(ab) }} seleccionar={(value) => {
            // handleChangegrillafaencmovi('', value, 'acreedor', 'codigo')
            props.setreferenciainicioseleccionado(value ? value.codigo.replace(/^-/, '') : "-")
            setabrirproducto(false)
            // setcodtercinicial(value ? value.codigo : "")
            // buscarterceronombre(value.codigo)
          }} />
          <BuscarProductos tituloencabezado={"Buscar Cuentas"} abrir={abrirproducto2} servidor={props.servidor} setabrirbanco={(ab) => { setabrirproducto2(ab) }} seleccionar={(value) => {
            // handleChangegrillafaencmovi('', value, 'acreedor', 'codigo')
            props.setreferenciafinseleccionado(value ? value.codigo.replace(/^-/, '') : "-")
            setabrirproducto2(false)
            // setcodtercinicial(value ? value.codigo : "")
            // buscarterceronombre(value.codigo)
          }} />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => { props.cerrarModal() }}>Cerrar</Button>
      </ModalFooter>
    </Modal>
  )
}
export default Filtro;