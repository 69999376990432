import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardHeader, CardBody, Button, Table, Input, Row, Col, InputGroup, InputGroupText, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormatMoney } from 'format-money-js'

// import { DataContext } from "../../Context/Data";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2'

toast.configure();

const ItemCart = (props) => {
  const [fm2, setfm2] = useState(new FormatMoney({ symbol: '', decimals: 2 }));
  const [fila, setfila] = useState([]);
  const [fm3, setfm3] = useState(new FormatMoney({ symbol: '$', decimals: 2 }));
  const [abrirdescripcionadicional, setabrirdescripcionadicional] = useState(false);

  const handleChangegrillafaencmovi = async (e) => {
    if (e.target.name == "desadicional") {

    } else {
      if (isNaN(Number(e.target.value))) {
        if (!e.target.value.includes("+")) {
          Swal.fire(
            'Opps!',
            'Solo se aceptan Numeros',
            'error'
          )
          return
        } else {
          return
        }
      }
      if (e.target.value.includes("+")) {
        return
      }
      e.target.setAttribute('cambio', 'si')
    }
    if (e.target.name == "precio") {
      var puedecamviarlistadeprecio = true
      props.permisosusuario.forEach(element => {
        if (element.tipo == "nodejarcambiaeprecio" && element.acceso[0] == "1") {
          var proo = props.menus.find(p => p.inarticulo.codigo == props.Movimiento.famovimiento[fila].referencia)
          if (proo) {
            if (proo.inarticulo.produccion != "domicilio") {
              puedecamviarlistadeprecio = false

            }
          } else {
            puedecamviarlistadeprecio = false

          }
        }
      });
      if (!puedecamviarlistadeprecio) {
        Swal.fire(
          'Opps!',
          'no tiene Permiso Para Cambiar el Precio',
          'error'
        )
        return
      }
    }


    var _Movimiento = { ...props.Movimiento }
    var subtabla = e.target.getAttribute('tabla')
    var _datos = { ...props.Movimiento }[subtabla]
    var _enviodatos = _datos.map((dato, index) => {
      if (index == fila) {
        var dat = {
          ...dato,
          [e.target.name]: e.target.name == "cantidad" ? Number(e.target.value + "") : e.target.value
        }
        return dat
      } else {
        return dato
      }
    })

    var total = calcularsubtotalitem(_enviodatos[fila])
    var _enviodatos2 = _enviodatos.map((dato, index) => {
      if (index == fila) {
        var dat = {
          ...dato,
          ['subtotal']: total
        }
        return dat
      } else {
        return dato
      }
    })
    var totales = props.calculartotalesmovimiento(_enviodatos2)

    _Movimiento[subtabla] = _enviodatos2
    // setMovimientos(_Movimientos)

    props.setMovimiento(_Movimiento)
    console.log(props.Movimiento)


  }
  const calcularsubtotalitem = (item) => {
    var base = Number(item.cantidad + "") * Number(item.precio + "")
    var descuento = base * (Number(item.pordescuento + "") / 100)
    var basecondescuento = base - descuento
    var iva = basecondescuento * (Number(item.poriva + "") / 100)
    var impoconsumo = basecondescuento * (Number(item.porimptoconsumo + "") / 100)
    var retefuente = basecondescuento * (Number(item.poretefte + "") / 100)
    var total = basecondescuento + iva + impoconsumo - retefuente
    return total
  }


  const decrease = (_id) => {
    var _Movimiento = { ...props.Movimiento }
    var _datos = { ...props.Movimiento }['famovimiento']


    _datos = _datos.map((dato, index) => {
      if (dato.referencia == _id) {
        var dat2 = {
          ...dato,
          ['cantidad']: dato.cantidad == 1 ? dato.cantidad : dato.cantidad - 1
        }
        var total = calcularsubtotalitem(dat2)
        dat2.subtotal = total
        return dat2
      } else {
        return dato
      }
    })


    var totales = props.calculartotalesmovimiento(_datos)

    _Movimiento['famovimiento'] = _datos
    props.setMovimiento(_Movimiento)

    // alert('decreased');
  };
  const increase = (_id) => {
    var _Movimiento = { ...props.Movimiento }
    var _datos = { ...props.Movimiento }['famovimiento']


    _datos = _datos.map((dato, index) => {
      if (dato.referencia == _id) {
        var dat2 = {
          ...dato,
          ['cantidad']: dato.cantidad + 1
        }
        var total = calcularsubtotalitem(dat2)
        dat2.subtotal = total
        return dat2
      } else {
        return dato
      }
    })


    var totales = props.calculartotalesmovimiento(_datos)

    _Movimiento['famovimiento'] = _datos
    props.setMovimiento(_Movimiento)

    // alert('decreased');
  };
  // const reservedCase = (id) => {
  //   console.log({ id });
  //   if (table.id) {
  //     const updatedReservedCase = {
  //       reserved: "true",
  //     };
  //     axios.patch(`/tables/update/${id}`, updatedReservedCase);
  //   }
  //   // setTables(
  //   //   tables.map((item) => {
  //   //     if (item.tId === table.tId) {
  //   //       return {
  //   //         ...item,
  //   //         reserved: !item.reserved,
  //   //       };
  //   //     }
  //   //     return item;
  //   //   })
  //   // );
  // };

  // useEffect(() => {
  //   console.log({ cart });
  // }, [cart]);

  return (
    <Card className="mb-3 shadow bg-white rounded">


      {props.Movimiento.famovimiento.length === 0 ? (
        <CardBody>No hay Productos</CardBody>
      ) : (
        <CardBody>
          <Row>
            <Col sm={8}>
              <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                <InputGroupText className="labelmovtotalmediopago">Propina</InputGroupText>
                {/* propina porcentaje */}
                <Input onChange={(e) => {
                  try {
                    var _Movimiento = { ...props.Movimiento }
                    _Movimiento.porimptoconsumo = Number(e.target.value)
                    _Movimiento.convoridoc = Math.round(props.total2 * (_Movimiento.porimptoconsumo / 100))
                    props.setMovimiento(_Movimiento)
                    props.calculartotalesmovimiento(props.Movimiento.famovimiento, _Movimiento.convoridoc)

                  } catch (error) {
                    var _Movimiento = { ...props.Movimiento }
                    _Movimiento.porimptoconsumo = 0
                    _Movimiento.convoridoc = 0
                    props.setMovimiento(_Movimiento)
                  }
                }}
                  value={props.Movimiento.porimptoconsumo} placeholder="Porcentaje" />
                {/* propina valor */}
                <Input onChange={(e) => {
                  var _Movimiento = { ...props.Movimiento }
                  _Movimiento.convoridoc = Number(e.target.value)
                  _Movimiento.porimptoconsumo = 0
                  props.setMovimiento(_Movimiento)
                  props.calculartotalesmovimiento(props.Movimiento.famovimiento, _Movimiento.convoridoc)
                }} value={props.Movimiento.convoridoc} placeholder="Valor" />
              </InputGroup>

            </Col>
          </Row>
          <Table id="tabladeordenesdomicilio" size="sm" responsive hover>
            <thead className="thead">
              <tr style={{ lineHeight: '0.5' }}>
                <th>Nombre</th>
                <th>Cantidad</th>
                <th>Precio Un</th>
                <th>Impo</th>
                <th>Total</th>
                <th>Accion</th>
              </tr>
            </thead>
            <tbody className="tbody">
              {props.Movimiento.famovimiento.map((item, index) => (
                <>
                  <tr className="tr" key={item.referencia} style={{ lineHeight: '0.5' }}>
                    <td style={{ padding: '2px 5px', lineHeight: '1' }}>
                      {item.descrip}
                    </td>
                    <td data-label="Quantity" className="td align-middle" style={{ padding: '2px 5px', lineHeight: '1' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                          size="sm"
                          className="itemcarttablebutton"
                          onClick={() => decrease(item.referencia)}
                          disabled={item.cantidad === 0}
                          style={{ fontSize: '10px', padding: '2px 4px' }}
                        >
                          <span>-</span>
                        </Button>
                        <Input
                          onChange={handleChangegrillafaencmovi}
                          tabla='famovimiento'
                          name='cantidad'
                          onFocus={(e) => { setfila(index) }}
                          value={item.cantidad}
                          style={{ width: '50px', fontSize: '10px', padding: '2px' }}
                        />
                        <Button
                          size="sm"
                          className="itemcarttablebutton"
                          onClick={() => increase(item.referencia)}
                          style={{ fontSize: '10px', padding: '2px 4px' }}
                        >
                          <span>+</span>
                        </Button>
                      </div>
                    </td>
                    <td style={{ padding: '2px 5px', lineHeight: '1' }}>
                      <Input
                        onFocus={(e) => {
                          setfila(index)
                          e.target.value = item.precio
                        }}
                        onChange={handleChangegrillafaencmovi}
                        tabla='famovimiento'
                        className="inputmovgrilla"
                        name='precio'
                        style={{
                          width: '100px', // Reduce el ancho del input
                          fontSize: '12px', // Ajusta el tamaño de fuente
                          padding: '2px', // Reduce el padding del input
                          boxSizing: 'border-box' // Asegura que el padding no afecte el ancho total
                        }}
                        value={fm2.from(item.precio)}
                      />
                    </td>

                    <td style={{ padding: '2px 5px', lineHeight: '1' }}>
                      {item.porimptoconsumo ? item.porimptoconsumo : 0}
                    </td>
                    <td style={{ padding: '2px 5px', lineHeight: '1', width: '100px', maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {item.subtotal ? fm2.from(item.subtotal) : 0}
                    </td>
                    <td style={{ padding: '2px 5px', lineHeight: '1', textAlign: 'center' }}>
                      <div style={{ display: 'flex', justifyContent: 'center', gap: '4px' }}>
                        <Button
                          className="itemcarttablebutton"
                          size="sm"
                          color="danger"
                          onClick={() => { props.eliminarProducto(index) }}
                          style={{ fontSize: '12px', padding: '2px 4px' }}
                        >
                          {" "}
                          X
                        </Button>
                        <Button
                          className="itemcarttablebutton"
                          size="sm"
                          color="primary"
                          onClick={() => {
                            setfila(index)
                            setabrirdescripcionadicional(true)
                          }}
                          style={{ fontSize: '12px', padding: '2px 4px' }}
                        >
                          {" "}
                          O
                        </Button>
                      </div>
                    </td>


                  </tr>
                  <Modal size="lg" isOpen={abrirdescripcionadicional}>
                    <ModalHeader>
                      Descripcion Adicional
                    </ModalHeader>
                    <ModalBody>
                      <Input sm="lg" onChange={handleChangegrillafaencmovi} value={props.Movimiento.famovimiento[fila] && props.Movimiento.famovimiento[fila].desadicional} tabla='famovimiento' type="textarea" name="desadicional" />
                    </ModalBody>
                    <ModalFooter>
                      <Button size="sm" color="primary" onClick={() => { setabrirdescripcionadicional(false) }}>Listo</Button>
                    </ModalFooter>
                  </Modal>
                </>

              ))}
            </tbody>
          </Table>

          <Button
            color="primary"
            size="sm"
            className="mt-2 not-allowed"
            // disabled={check.includes(0)}
            onClick={() => {

              props.terminarVenta()
              // addToOrders();
              // if (userData.adminCheck === true) {
              //   // reservedCase(table.id);
              // }
              // refreshPage();
            }}
          >
            GUARDAR
          </Button>
          {props.Movimiento.ndocumento != 0 &&
            <Button
              color="primary"
              size="sm"
              className="mt-2 not-allowed"
              // disabled={check.includes(0)}
              onClick={() => {

                props.print()
                // addToOrders();
                // if (userData.adminCheck === true) {
                //   // reservedCase(table.id);
                // }
                // refreshPage();
              }}
            >
              COMANDA
            </Button>
          }
          {props.Movimiento.ndocumento != 0 &&
            <Button
              color="primary"
              size="sm"
              className="mt-2 not-allowed"
              // disabled={check.includes(0)}
              onClick={() => {

                props.terminarVenta2()
                // addToOrders();
                // if (userData.adminCheck === true) {
                //   // reservedCase(table.id);
                // }
                // refreshPage();
              }}
            >
              PAGAR
            </Button>
          }
          {props.Movimiento.ndocumento != 0 &&
            <Button
              color="primary"
              size="sm"
              className="mt-2 not-allowed"
              // disabled={check.includes(0)}
              onClick={() => {

                props.print23()
                // addToOrders();
                // if (userData.adminCheck === true) {
                //   // reservedCase(table.id);
                // }
                // refreshPage();
              }}
            >
              FACTURA ELECTRONICA
            </Button>
          }
          {props.Movimiento.ndocumento != 0 &&
            <Button
              color="primary"
              size="sm"
              className="mt-2 not-allowed"
              // disabled={check.includes(0)}
              onClick={() => {

                props.print2()
                // addToOrders();
                // if (userData.adminCheck === true) {
                //   // reservedCase(table.id);
                // }
                // refreshPage();
              }}
            >
              FACTURA
            </Button>
          }
          <Button
            color="primary"
            size="sm"
            className="mt-2 not-allowed"
            // disabled={check.includes(0)}
            onClick={() => {

              props.reiniciar()
              // addToOrders();
              // if (userData.adminCheck === true) {
              //   // reservedCase(table.id);
              // }
              // refreshPage();
            }}
          >
            NUEVO
          </Button>
        </CardBody>
      )}
      <CardHeader>
        {" "}
        <div className="flexbetween">
          <h5 className="classheaderspan">Tipo : {props.mesa}</h5>
          <h5> Cantidad de Productos : {props.Movimiento.famovimiento.length}</h5>
        </div>
        <Button onClick={() => { props.setmostrarimagenes(true) }}></Button>
      </CardHeader>
    </Card>
  );
};

export default ItemCart;
