import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
import MovimientoFacturasCompras from './Reportes/Movimiento/MovimientosPorConcepto'
import ComprasProductoProveedor from './Reportes/Movimiento/MovimientosDetalleconCarteraRapido'

const informmm = {
    codigo: 0,
    informe: ''
}

const informescomprasss = [
    {
        codigo: 1,
        informe: "Movimientos Facturas de Compras",
    }, {
        codigo: 2,
        informe: "Compras X Producto X Porveedor",
    }
]
const informesventasss = [
    {
        informe: "Movimientos Facturas de Venta",
    }, {
        informe: "Ventas X Producto X Cliente X Vendedor",
    }
]
const Pais = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Pais, setPais] = useState(informmm);

    const [usuario, setDataUser] = useState([]);
    const [editar, seteditar] = useState(0);
    const [verModalPais, setVerModalPais] = useState(false);

    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
    }, [])
    const columns = [
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModalPais(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
        {
            name: 'Informe',
            selector: row => row.informe,
            sortable: true,
        },
        {
            name: '',
            selector: row => "",
            sortable: true,
        },
        {
            name: '',
            selector: row => "",
            sortable: true,
        }

    ];
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const abrirEditarModalPais = (data) => {
        var enviodata = { ...data }
        var _Pais = {
            codigo: enviodata.codigo,
            nombre: enviodata.nombre,
        }
        setPais(_Pais);
        setVerModalPais(!verModalPais);
        seteditar(true)
    }
    const cerrarModalPais = () => {
        setPais(informmm)
        setVerModalPais(!verModalPais);
        seteditar(false)
    }
    const guardarCambios = async () => {
        let response;
        if (!editar) {
            response = await fetch("" + props.servidor + "/Paisapi?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(Pais)
            })

        } else {
            response = await fetch("" + props.servidor + "/Paisapi?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(Pais)
            })
        }

        if (response.ok) {
            setPais(informmm)
            setVerModalPais(!verModalPais);
            Swal.fire(
                'Guardado Exitosamente!',
                'El Pais fue guardado exitosamente',
                'success'
            )
        } else {

        }

    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Informes Compras
                </CardHeader>
                <CardBody>
                    <DataTable
                        columns={columns}
                        data={informescomprasss}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" style={{ maxWidth: '1500px', width: '95%' }} isOpen={verModalPais}>
                <ModalBody>
                    {Pais.codigo == 1 && <MovimientoFacturasCompras tipodoctipodoc={"compra"} rapido={true} 
                     caja={props.caja} permisosusuario={props.permisosusuario} servidor={props.servidor} />}
                     {Pais.codigo == 2 && <ComprasProductoProveedor tipodoctipodoc={"compra"} rapido={true} 
                     caja={props.caja} permisosusuario={props.permisosusuario} servidor={props.servidor} />}
                     
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="danger" onClick={cerrarModalPais}>Cerrar</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}
export default Pais;
