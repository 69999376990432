
import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Swal from 'sweetalert2'
import Autosuggest from 'react-autosuggest';
import { useContext, useState, useEffect } from "react";
import Buscar from '../componentes/Buscar'
import "./css/Venta.css"
import { UserContext } from "../context/UserProvider";
import { PermisoContext } from "../context/PermisosProvider";

import BuscarMovimiento from '../componentes/BuscarCartera'
import BuscarMovimiento2 from '../componentes/Buscarmovimiento'
import BuscarComprobante from '../componentes/Buscar2/Comprobantes'
import BuscarCuentas from '../componentes/Buscar2/Cuentas'
import { FormatMoney } from 'format-money-js'
import { jsPDF } from "jspdf";

const tabespacio = '\u00A0';

const municipios = [
    {
        municipio: "Barranquilla",
        departamento: "Atlantico",
        codigo: ""
    },
    {
        municipio: "Barranquilla",
        departamento: "Atlantico",
        codigo: "08001"
    },
    {
        municipio: "Oiba",
        departamento: "Santander",
        codigo: "68500"
    },
    {
        municipio: "Santa Marta",
        departamento: "Magdalena",
        codigo: "47001"
    },
    {
        municipio: "Funza",
        departamento: "Cundinamarca",
        codigo: "25286"
    },
    {
        municipio: "Bogota",
        departamento: "Bogota",
        codigo: "11001"
    },
    {
        municipio: "Popayan",
        departamento: "",
        codigo: "19001"
    },
    {
        municipio: "FACATATIVÁ",
        departamento: "",
        codigo: "25269"
    },
    {
        municipio: "MADRID",
        departamento: "",
        codigo: "25430"
    },
    {
        municipio: "QUIBDÓ",
        departamento: "CHOCÓ",
        codigo: "27001"
    },
    {
        municipio: "JAMUNDÍ",
        departamento: "VALLE DEL CAUCA",
        codigo: "76364"
    },
    {
        municipio: "ENVIGADO",
        departamento: "ANTIOQUIA",
        codigo: "05226"
    },
    {
        municipio: "PUERTO LIBERTADOR",
        departamento: "CORDOBA",
        codigo: "25580"
    },
    {
        municipio: "SAN GIL",
        departamento: "SANTANDER",
        codigo: "68679"
    },
    {
        municipio: "BARRANCAS",
        departamento: "La Guajira",
        codigo: "44078"
    }

]
const modelo = {
    usuario: {
        id: "",
        cedula: "",
        nitempresa: "",
        nombre: "",
        nombreempresa: "",
        contraseña: "",
        llaveempresa: "",
        llaveusuario: "",
        cargo: "",
        departamento: "",
        Eliminado: "",
    },
    empresa: {
        empresa: {
            id: "",
            nit: "",
            nombre: "",
            descripcion: "",
            telefono: "",
            direccion: "",
            email: "",
            celular: "",
            llave: "",
            servidor: "",
            basededatos: "",
            activo: "",
            fechaultimopago: "",
            maximafechadeuso: "",
            Eliminado: "",
        },
        config: {
            carpeta_documentos: "",
            carpeta_bd: "",
            contribuyente_nit: "",
            contribuyente_razon_social: "",
            contribuyente_tipo: "",
            contribuyente_responsabilidad_fiscal: "",
            contribuyente_regimen_fiscal: "",
            contribuyente_matricula_mercantil: "",
            direccion_contribuyente_linea: "",
            direccion_contribuyente_municipio: "",
            direccion_contribuyente_departamento: "",
            direccion_contribuyente_pais: "",
            contribuyente_email: "",
            contribuyente_telefono: "",
            software_en_pruebas: "",
            usar_set_pruebas: "",
            identificador_set_pruebas: "",
            identificador_software: "",
            rango_numero_resolucion: "",
            rango_fecha_resolucion: "",
            rango_vigencia_desde: "",
            rango_vigencia_hasta: "",
            rango_clave_tecnica: "",
            rango_prefijo: "",
            rango_desde: "",
            rango_hasta: "",
            ruta_certificado: "",
            clave_certificado: "",
            encabezado_pdf: "",
            ruta_logo: "",
            smtp_host: "",
            smtp_puerto: "",
            smtp_ssl: "",
            smtp_usuario: "",
            smtp_clave: "",
            sucursal: "",
            pin_software: "",
            especialidad: "",
            Eliminado: "",
        },
        modulos: {
            FACTURA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            INVENTARIO: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CARTERA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            FACTURAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CONTABILIDAD: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            TESORERIA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            COMPRA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            RADIAN: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            MAESTROS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            ADMINISTRACION: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            DATOSBASICOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }, ARTICULOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }



        }
    }
}
const modelomovimiento = {
    tipodoc: '',
    tercero: "",
    codicli: "",
    vendedor: "",
    bruto: "",
    iva: "",
    dscto: "",
    retefte: "",
    reteica: "",
    reteiva: "",
    neto: "",
    nombrecli: "",
    nombreven: "",
    fecha: new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2),
    horafecha: "",
    doc: "",
    cedula: "",
    ctabono: [
        // {
        //     tipodoc: "",
        //     doc: "",
        //     tercero: "",
        //     codicli: "",
        //     vendedor: "",
        //     fecha: "",
        //     compañia2: "",
        //     tipodoc2: "",
        //     doc2: "",
        //     ncuota: "",
        //     vlcuota: "",
        //     bruto: "",
        //     iva: "",
        //     dscto: "",
        //     retefte: "",
        //     reteica: "",
        //     reteiva: "",
        //     neto: "",
        //     detalle: "",
        //     nomusuario: "",
        //     estacion: "",
        //     nombrecli: "",
        //     nombreven: "",
        //     horafecha: "",
        //     periodo: "",
        //     observacion: "",
        //     despiefac: "",
        //     impconsumo: "",
        //     cuotai: "",
        //     ncuotasi: "",
        //     diferidasi: "",
        //     apartirdei: "",
        //     tipocre: "",
        //     ncuotasf: "",
        //     tasa: "",
        //     diferidas: "",
        //     apartirde: "",
        //     fechpost: "",
        //     banco: "",
        //     efectivo: "",
        //     cheque: "",
        //     tdebito: "",
        //     tcredito: "",
        //     descuentoc: "",
        //     beneficiario: "",
        //     impreso: "",
        //     anulado: "",
        //     estado: "",
        //     borradopor: "",
        //     anuladopor: "",
        //     cedula: "",
        //     modificpor: "",
        //     cree: "",
        //     creeporc: "",
        //     ccosto: "",
        //     bancodb: "",
        //     bancocr: "",
        // }
    ]


}
const modelofacturas = [
    {
        tipodoc: '',
        doc: '',
        tercero: '',
        codicli: '',
        fecha: '',
        vlint: '',
        abono: '',
        vlcuota: '',
        valcuota: '',
        ncuota: '',
        fechavenc: '',
        vlintant: '',
        vlcuotant: '',
        debitoint: '',
        debito: '',
        creditoint: '',
        credito: '',
        vlrfactur: '',
        vlrdct: '',
        vlriva: '',
        vlrfet: '',
        retiva: '',
        vlrica: '',
        vendedor: '',
        estado: '',
        cedula: '',
        cuenta: '',
        ccosto: '',
        pagos: '',
        dolar: '',
        devol: '',
        horafecha: '',
    }
]
const modelotipodocumento = {
    codigo: "",
    descripcion: "",
    tipodoc: "",
    tdpro: "",
    trasauto: "",
    tipoprod: "",
    actuacu: "",
    comactacu: "",
    rentab: "",
    afecinv: "",
    serial: "",
    descuentoc: "",
    actcosto: "",
    numitem: "",
    despiece: "",
    exportacion: "",
    afecar: "",
    rcauto: "",
    causadoc: "",
    afecaj: "",
    formapago: "si",
    afeban: "",
    docban: "",
    muesdescbanc: "",
    asicont: "",
    consuni: "",
    conscom: "",
    precsug: "",
    descsug: "",
    cupocred: "",
    permexisne: "",
    conpunreor: "",
    facdebcos: "",
    camprecven: "",
    camdesc: "",
    cambioiva: "",
    cambioimp: "",
    confacven: "",
    consecfac: "",
    camdescenc: "",
    camdescpp: "",
    pass1: "",
    pass2: "",
    pass3: "",
    pass4: "",
    pass5: "",
    pass6: "",
    pass7: "",
    pass8: "",
    pass9: "",
    pass10: "",
    ivaincluido: "",
    busplu: "",
    ccosto: "",
    centrocosto: "",
    tcostofijo: "",
    comprob: "",
    impto: "",
    tbodegasn: "",
    tbodega: "",
    tbodegafija: "",
    descsn: "si",
    descporc: "",
    ivasn: "si",
    ivaporc: "",
    rete: "si",
    ica: "si",
    reteiva: "si",
    imptosn: "si",
    imptoporc: "",
    captionimpto: "",
    enviacorreo: "",
    ctrlmov: "",
    docauto: "",
    tdpro1: "",
    tdpro2: "",
    filtroart: "",
    facturapap: "",
    mvendedor: "si",
    captionvend: "",
    docu1: "si",
    captiondoc1: "",
    val_doc1: "",
    docu2: "si",
    captiondoc2: "",
    observa: "",
    obslinea: "",
    mdevolucio: "",
    mediospago: "",
    puntoventa: "",
    modidoc: "",
    modidocenc: "",
    noimpdupl: "",
    rentabilid: "",
    redondeofac: "",

}
const modeloVendedor = {
    codigo: "",
    nombre: ""
}
const modeloTerceros = {
    tercero: "",
    clase: "",
    codigo: "",
    cedula: "",
    digito: "",
    razsoc: "",
    nom1: "",
    nom2: "",
    ape1: "",
    ape2: "",
    codigomun: "",
    coddepar: "",
    codpais: "",
    email: "",
    contacto: "",
    beneficiario: "",
    repleg: "",
    reg_merc: "",
    direccion: "",
    tel1: "",
    tel2: "",
    tel3: "",
    barrio: "",
    fechnac: "",
    interes: "",
    cupocre: "",
    plazo: "",
    vendedor: "",
    ccosto: "",
    precsug: "",
    actecono: "",
    feching: "",
    fechulne: "",
    regtrib: "",
    resfis: "",
    desccom: "",
    desccom2: "",
    desccom3: "",
    desccom4: "",
    autoreteft: "",
    estado: "",
    salant1: "",
    saldeb1: "",
    salcre1: "",
    venretefuente: "",
    venbasefte: "",
    venporcfte: "",
    veniva: "",
    venbaseiva: "",
    venporciva: "",
    venreteiva: "",
    venbasereteiva: "",
    venporcreteiva: "",
    venreteica: "",
    venbaseica: "",
    venporica: "",
    salact1: "",
    ciudad: "",
    pais: "",
    clas1: "",
    clas2: "",
    clas3: "",
    clas4: "",
    clas5: "",
    reteica: "",
    personat: "",
    auto: "",
    salant2: "",
    saldeb2: "",
    salcre2: "",
    salact2: "",
    brutop1: "",
    brutop2: "",
    ivap: "",
    desctop1: "",
    desctop2: "",
    reteftep: "",
    reteivap: "",
    reteica2p: "",
    imptoconsumop: "",
    brutoa1: "",
    brutoa2: "",
    ivaa: "",
    desctoa1: "",
    desctoa2: "",
    retftea: "",
    reteivaa: "",
    reteicaa: "",
    imptoconsa: "",
    creadopor: "",
    borradopor: "",
    modificpor: "",
    municipio: "",
    cambioimp: "",
    flete: "",
    cumplemes: "",
    cumpledia: "",
    cumpleano: "",
    horafecha: "",
    sexo: "",
    zona: "",
    clas6: "",
    clas7: "",
    clas8: "",
    clas9: "",
    clas10: "",
    autorexpro: "",
    cod_postal: "",
    nompais: "",
    campo: "",
    comretefte: "",
    combasefte: "",
    comporcfte: "",
    comreteiva: "",
    combasereteiva: "",
    comporcreteiva: "",
    comiva: "",
    combaseiva: "",
    comporciva: "",
    comreteica: "",
    combaseica: "",
    comporica: "",
    vencree: "",
    venbacree: "",
    venporcree: "",
    comcree: "",
    combacree: "",
    comporcree: "",
    Eliminado: "",
}
const Venta = (props) => {
    const { user } = useContext(UserContext)
    const { permisos } = useContext(PermisoContext)
    const [abrircomprobantes, setabrircomprobantes] = useState(false);
    const [abrircuentas, setabrircuentas] = useState(false);
    const [comprobante, setcomprobante] = useState("");
    const [crucecontable, setcrucecontable] = useState([]);
    const [tipodocseleccionado, settipodocseleccionado] = useState("");
    const [dataUser, setDataUser] = useState(modelo)
    const [Movimientos, setMovimientos] = useState([]);
    const [facturaselecinmovi, setfacturaselecinmovi] = useState([]);
    const [Movimiento, setMovimiento] = useState(modelomovimiento);
    const [facturas, setfacturas] = useState(modelofacturas);
    const [tipodocumento, settipodocumento] = useState(modelotipodocumento)
    const [busquedatipodocumentos, setbusquedatipodocumentos] = useState([]);
    const [verModaltipodocumentos, setVerModaltipodocumentos] = useState(false);
    const [tipodocumentos, settipodocumentos] = useState([]);
    const [fechahoy, setfechahoy] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2),);
    const [Roles, setRoles] = useState([]);
    const [tercero, settercero] = useState(modeloTerceros)
    const [busquedaterceros, setbusquedaterceros] = useState([]);
    const [verModalterceros, setVerModalterceros] = useState(false);
    const [terceros, setterceros] = useState([]);
    const [vendedor, setvendedor] = useState(modeloVendedor)
    const [busquedavendedors, setbusquedavendedors] = useState([]);
    const [verModalvendedors, setVerModalvendedors] = useState(false);
    const [vendedors, setvendedors] = useState([]);
    const [bruto, setbruto] = useState(0)
    const [descuento, setdescuento] = useState(0)
    const [subTotal, setSubTotal] = useState(0)
    const [oingresos, setoingresos] = useState(0)
    const [cree, setcree] = useState(0)
    const [reteiva, setreteiva] = useState(0)
    const [retefuente, setretefuente] = useState(0)
    const [reteica, setreteica] = useState(0)
    const [total, setTotal] = useState(0)
    const [abrirdetalle, setabrirdetalle] = useState(false)
    const [abrirfacturabono, setabrirfacturabono] = useState(false)
    const [movimientostercero, setmovimientostercero] = useState([])
    const [movimientostercero2, setmovimientostercero2] = useState([])
    const [fila, setfila] = useState([]);
    const [nuevo, setnuevo] = useState(false);
    const [editar, seteditar] = useState(false);
    const [busquedaMovimientos, setbusquedaMovimientos] = useState([]);
    const [verModalMovimientos, setVerModalMovimientos] = useState(false);
    const [fm, setfm] = useState(new FormatMoney({ symbol: '' }));
    const [fm1, setfm1] = useState(new FormatMoney({ symbol: '', decimals: 1 }));
    const [fm2, setfm2] = useState(new FormatMoney({ symbol: '', decimals: 2 }));
    const [fm3, setfm3] = useState(new FormatMoney({ symbol: '$', decimals: 2 }));
    const [Mediodepagos, setMediodepagos] = useState([]);
    const [abrirmediosdepago, setabrirabrirmediosdepago] = useState(false);
    const [previsualizar, setprevisualizar] = useState(false);
    const [puedoanular, setpuedoanular] = useState(false);
    const [abrirmodelocontable, setabrirmodelocontable] = useState(false);
    const [mediodepago, setmediodepago] = useState([]);
    const [observacion, setobservacion] = useState("");

    const reestablecer = () => {

    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        buscartodastipodocumentos('');
        if (dt.empresa.modulos.ARTICULOS.electronico) {
            // buscartodasterceros('');
        }
        buscartodasvendedors('');
        obtenerMediodepagos('');

        props.permisosusuario.forEach(element => {
            if (element.tipo == "anular" && element.acceso[0] == "2") {
                setpuedoanular(true)
            }
        });
    }, [])

    const previsualizarpdfelectronica = () => {

        const doc = new jsPDF('p', 'pt', 'a4');
        doc.page = 1; // use this as a counter.
        console.log(doc.getFontList())
        var escala = (doc.internal.pageSize.width - 10) / document.getElementById('pruebapdfventaelectronica').clientWidth
        doc.html(document.querySelector("#pruebapdfventaelectronica"), {
            callback: function (pdf) {
                doc.autoPrint();

                doc.output('dataurlnewwindow', { filename: 'comprobante.pdf' });
            },
            html2canvas: {
                scale: escala
            }
        })
    }

    const handleChange = (e) => {
        if (tipodocumento.codigo != "") {
            if (e.target.name == 'fecha' && tipodocumento.ctrlmov == 'si') {
                var nopuedecambiarfecha = true
                props.permisosusuario.forEach(element => {
                    if (element.tipo == "cambiarfecha" && element.acceso[0] == "2") {
                        nopuedecambiarfecha = false
                    }
                });
                if (nopuedecambiarfecha) {
                    Swal.fire(
                        'Restringido!',
                        'No puede cambiar la fecha',
                        'error'
                    )
                    var _Movimiento = { ...Movimiento }
                    _Movimiento[e.target.name] = fechahoy
                    setMovimiento(_Movimiento)
                    setRoles([])

                    return
                }

            }
            let value = e.target.value
            console.log(e.target)
            var _Movimiento = { ...Movimiento }
            _Movimiento[e.target.name] = value
            setMovimiento(_Movimiento)
            setRoles([])
        } else {
            Swal.fire(
                'Opps!',
                'Tiene que seleccionar un Tipo de Documento primero',
                'error'
            )
        }



    }


    const obtenerMediodepagos = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Mediodepagoapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                var dataenvio = data.filter(p => p.semanejaencaja == "Si")
                dataenvio = dataenvio.map((dato) => {
                    var dat = {
                        ...dato,
                        ['valor']: 0,
                        ['concepto']: tipodocumento.codigo,
                        ['documento']: Number(Movimiento.ndocumento + ""),
                        ['caja']: props.caja,
                        ['fecha']: Movimiento.fecha,
                        ['afecta']: 'debito'
                    }
                    return dat
                })
                setMediodepagos(dataenvio)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const seleccionartipodocumentos2 = (e) => {
        var tii = e.target.value.toUpperCase()

        let value = tipodocumentos.find(p => p.codigo == tii)
        if (value) {

            settipodocseleccionado(value.codigo)
            setRoles([])
            settipodocumento(value)
        } else {
            Swal.fire(
                'Opps!',
                'Tipo de Documento no Existe',
                'error'
            )
        }
    }
    const seleccionartipodocumentos = (e) => {
        let value = e

        settipodocseleccionado(value.codigo)
        setRoles([])
        cerrarModaltipodocumentos()
        settipodocumento(value)
    }
    const filtrartipodocumentos = async (coincidencia) => {
        var _codigomuns = tipodocumentos.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.descripcion.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedatipodocumentos(_codigomuns)
    }
    const cerrarModaltipodocumentos = () => {
        setVerModaltipodocumentos(!verModaltipodocumentos);
        setbusquedatipodocumentos(tipodocumentos)
    }
    const filtrarterceros = async (coincidencia) => {
        var _codigomuns = await buscarcoincidenciasterceros(coincidencia)
        setbusquedaterceros(_codigomuns)
    }

    const buscarcoincidenciasterceros = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Caclientesapi/unicocoincidecnias/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                var dataj = []
                if (tipodocumento.afecar == "credito") {
                    dataj = data.filter(e => e.tercero == "cliente")

                } else {
                    if (tipodocumento.afecar == "debito") {
                        dataj = data.filter(e => e.tercero == "proveedor")

                    } else {
                        dataj = data

                    }
                }

                return dataj
            }
        } catch (error) {
            Swal.fire(
                'Opps!',
                'No se pudo buscar los terceros',
                'error'
            )
            return []
        }
    }
    const cerrarModalterceros = () => {
        setVerModalterceros(false);
        setbusquedaterceros(terceros)
    }
    const buscartodasterceros = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Caclientesapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setterceros(dataJson)
                    setbusquedaterceros(dataJson.filter(e => e.tercero == "proveedor"))

                    console.log(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setterceros([])
                    setbusquedaterceros([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const buscarmovimientostercero = (tercero2) => {
        try {
            var tipotercero = "cliente"
            if (tipodocumento.afecar == "debito") {
                tipotercero = "proveedor"

            }

            var tercero = tercero2
            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/TesoreriaApi/anticipovivo/' + tercero.codigo + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    buscarmovimientosterceroenbusqueda(tercero2)
                    setmovimientostercero(dataJson)
                    var listaabonos = []
                    dataJson.forEach(mov => {
                        var abono = {
                            tipodoc: mov.documentorefcon,
                            doc: mov.documentoref,
                            codicli: mov.acreedor,
                            saldo: mov.valor
                        }
                        listaabonos.push(abono);
                    });
                    var _Movimiento = { ...Movimiento }
                    _Movimiento['ctabono'] = listaabonos
                    _Movimiento['codicli'] = tercero.codigo
                    _Movimiento['nombrecli'] = tercero.razsoc
                    _Movimiento['cedula'] = tercero.cedula
                    setMovimiento(_Movimiento)

                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setterceros([])
                    setbusquedaterceros([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const buscartodoslosmovimientos2 = (coincidencia, nume, index) => {
        try {

            let dt = JSON.parse(user)
            var url = props.servidor + '/Movimientoapi/' + coincidencia + '/' + nume + '?llave=' + dt.usuario.llaveempresa + ''
            const api = fetch(props.servidor + '/Movimientoapi/optimizado/unicoinmovipagos/' + coincidencia + '/' + nume + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setfacturaselecinmovi(dataJson)
                    var _ctabono = Movimiento.ctabono.map((dato, index) => {
                        if (dato.tipodoc2 == coincidencia && dato.doc2 == nume) {
                            var dat = {
                                ...dato,
                                ['iva']: Number(dataJson.iva + ""),
                                ['retefte']: Number(dataJson.retefuente + ""),
                                ['reteica']: Number(dataJson.ica + ""),
                                ['reteiva']: Number(dataJson.reteiva + "")
                            }
                            return dat
                        } else {
                            return dato
                        }

                    })
                    var _mo = { ...Movimiento }
                    _mo.ctabono = _ctabono
                    setMovimiento(_mo)
                    console.log(dataJson)
                }).catch((error) => {

                    setfacturaselecinmovi({})
                })
        } catch (error) {
            console.log(error)
        }
    }
    const buscarmovimientosterceroenbusqueda = (tercero, _movimientos) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/TesoreriaApi/saldovivo/' + tercero.codigo + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {

                    setmovimientostercero2(dataJson)

                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setterceros([])
                    setbusquedaterceros([])
                })
        } catch (error) {
            console.log(error)
        }
    }

    const buscarcaclientesunico2 = async (coincidencia, tdoc) => {
        try {
            var tipotercero = "cliente"
            if (tdoc.afecar == "debito") {
                tipotercero = "proveedor"

            }

            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Caclientesapi/unico/' + coincidencia + '/' + tipotercero + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            console.log(error)
        }
    }
    const buscarcaclientesunico = async (coincidencia) => {
        try {
            var tipotercero = "cliente"
            if (tipodocumento.afecar == "debito") {
                tipotercero = "proveedor"

            }

            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Caclientesapi/unico/' + coincidencia + '/' + tipotercero + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            console.log(error)
        }
    }
    const buscarcajapordocumento = async (concepto, numero) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/CajaApi/porconcepto/' + concepto + '/' + numero + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            console.log(error)
        }
    }
    const buscarmodelocontable = async (concepto, concepto2) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/ContabilidadApi/buscarmodelocruce/' + concepto + '/' + concepto2 + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            console.log(error)
        }
    }
    const buscarsaldo = async (tipodoc, doc) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/CtabonoApi/saldo/' + tipodoc + '/' + doc + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            console.log(error)
        }
    }
    const buscarmesfactura = (fecha) => {
        var meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
        var dia = fecha.split("-")[1]
        var mesNumero = parseInt(dia, 10); // Extraer el número del mes y convertirlo a entero
        var nombreMes = meses[mesNumero - 1];
        return nombreMes
    }
    const buscarmesfactura2 = (fecha) => {
        var meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
        var partesFecha = fecha.split("-");
        var ano = parseInt(partesFecha[0], 10);
        var mesNumero = parseInt(partesFecha[1], 10);
        var dia = parseInt(partesFecha[2], 10);

        // Manejar el caso especial si el mes actual es enero
        if (mesNumero === 1) {
            mesNumero = 12; // Diciembre
            ano--; // Restar 1 al año
        } else {
            mesNumero--; // Obtener el mes anterior
        }

        var nombreMesAnterior = meses[mesNumero - 1];
        return nombreMesAnterior
    }
    const seleccionarterceros = (e) => {
        if (Number(Movimiento.doc + "") == 0 || Movimiento.doc == '') {
            let value = e
            var _Movimiento = { ...Movimiento }
            setRoles([])
            cerrarModalterceros()
            settercero(value)
            buscarmovimientostercero(value)

        }


    }
    const guardarmodelocontable = async () => {
        let response;
        console.log(crucecontable)
        var _mov = crucecontable.map((dato) => {
            var dat = {
                ...dato,
                ['codigo']: tipodocumento.codigo,
                ['nombre']: tipodocumento.codigo,
            }
            return dat

        })
        let dt = JSON.parse(user)

        response = await fetch("" + props.servidor + "/ContabilidadApi/modelocruce/" + tipodocumento.codigo + "/" + comprobante + '?llave=' + dt.usuario.llaveempresa, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(_mov)
        })

        if (response.ok) {
            setabrirmodelocontable(false)

            Swal.fire(
                'Guardado Exitosamente!',
                'Modelo Contable Guardado',
                'success'
            )
        } else {
            Swal.fire(
                'Error al Guardar!',
                'mensaje',
                'error'
            )
        }

    }
    const creargrillaabono = (tercero2) => {
        try {

            var listaabonos = []
            var abono = {
                tipodoc: tipodocumento.codigo,
                doc: 0,
                tercero: tercero2.tercero,
                codicli: tercero2.codigo,
                vendedor: "",
                fecha: "",
                compañia2: "",
                tipodoc2: tipodocumento.codigo,
                doc2: 0,
                ncuota: "1",
                vlcuota: "",
                bruto: 0,
                saldo: 0,
                efectivo: 0,
                iva: "",
                dscto: "",
                retefte: "",
                reteica: "",
                reteiva: "",
                neto: "",
                detalle: "",
                nomusuario: "",
                estacion: "",
                nombrecli: tercero2.razsoc,
                nombreven: "",
                horafecha: "",
                periodo: "",
                observacion: "",
                despiefac: "",
                impconsumo: "",
                cuotai: "",
                ncuotasi: "",
                diferidasi: "",
                apartirdei: "",
                tipocre: "",
                ncuotasf: "",
                tasa: "",
                diferidas: "",
                apartirde: "",
                fechpost: "",
                banco: "",
                cheque: "",
                tdebito: "",
                tcredito: "",
                descuentoc: "",
                beneficiario: "",
                impreso: "",
                anulado: "",
                estado: "",
                borradopor: "",
                anuladopor: "",
                cedula: tercero2.cedula,
                modificpor: "",
                cree: "",
                creeporc: "",
                ccosto: "",
                bancodb: "",
                bancocr: "",
            }
            listaabonos.push(abono);
            var _Movimiento = { ...Movimiento }
            _Movimiento['ctabono'] = listaabonos
            _Movimiento['codicli'] = tercero2.codigo
            _Movimiento['nombrecli'] = tercero2.razsoc
            _Movimiento['cedula'] = tercero2.cedula
            setMovimiento(_Movimiento)

        } catch (error) {
            console.log(error)
        }
    }


    const cambiarterceros = async (e) => {
        try {
            var tipotercero = "cliente"
            // if (tipodocumento.afecar == "debito") {
            //     tipotercero = "proveedor"

            // }

            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Caclientesapi/unico/' + e.target.value + '/' + tipotercero + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                var data2 = data.caclientes
                if (data2.codigo != '') {
                    seleccionarterceros(data2)
                } else {
                    Swal.fire(
                        'Opps!',
                        'Tercero no existe',
                        'error'
                    )
                    return
                }

                // setPendiente(false)
            }

        } catch (error) {
            console.log(error)
        }
    }


    const buscarnombremun = (codigo) => {
        var muni = municipios.filter(p => p.codigo == codigo)
        if (muni.length != 0) {
            return muni[0].municipio + " - " + muni[0].departamento
        } else {

            return "Barranquilla" + " - " + "Atlantico"
        }
    }
    const filtrarvendedors = async (coincidencia) => {
        var _codigomuns = vendedors.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedavendedors(_codigomuns)
    }
    const cerrarModalvendedors = () => {
        setVerModalvendedors(!verModalvendedors);
        setbusquedavendedors(vendedors)
    }
    const buscartodasvendedors = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Vendedorapi/nombres/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setvendedors(dataJson)
                    setbusquedavendedors(dataJson)
                    console.log(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setvendedors([])
                    setbusquedavendedors([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const seleccionarvendedors = (e) => {
        let value = e
        var _Movimiento = { ...Movimiento }
        _Movimiento['vendedor'] = value.codigo
        _Movimiento['nombreven'] = value.nombre
        setMovimiento(_Movimiento)
        setRoles([])
        cerrarModalvendedors()
    }

    const handleChangegrillafaencmovi = async (e) => {
        if (e.target.name != 'detalle') {
            if (isNaN(Number(e.target.value))) {
                Swal.fire(
                    'Opps!',
                    'Solo se aceptan Numeros',
                    'error'
                )
                return
            }
        }

        var _Movimiento = { ...Movimiento }
        var subtabla = e.target.getAttribute('tabla')
        var _datos = { ...Movimiento }[subtabla]
        var neto = 0
        var _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                if (e.target.name == "bruto") {
                    var dat = {
                        ...dato,
                        [e.target.name]: e.target.value
                    }
                    return dat
                } else {
                    var dat = {
                        ...dato,
                        [e.target.name]: e.target.value
                    }
                    return dat
                }
            } else {
                return dato
            }
        })


        neto = Number(_enviodatos[fila].bruto + "") + Number(_enviodatos[fila].iva + "") - Number(_enviodatos[fila].dscto + "") - Number(_enviodatos[fila].retefte + "") - Number(_enviodatos[fila].reteica + "") - Number(_enviodatos[fila].reteiva + "") - Number(_enviodatos[fila].cree + "")
        _enviodatos = _enviodatos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    ['neto']: neto
                }
                return dat
            } else {
                return dato
            }
        })

        _Movimiento[subtabla] = _enviodatos
        setMovimiento(_Movimiento)
        totalesabono(_Movimiento)
        console.log(Movimiento)
        setRoles({ codig: '' })
        console.log(Roles)

    }
    const totalesabono = (_Movimiento) => {
        var total = 0
        var toingresos = 0
        var tdescuento = 0
        var tretefuente = 0
        var treteiva = 0
        var treteica = 0
        var tcree = 0
        var tneto = 0
        _Movimiento.ctabono.map((dato) => {
            total = total + Number(dato.bruto + "")
            toingresos = toingresos + Number(dato.iva + "")
            tdescuento = tdescuento + Number(dato.dscto + "")
            tretefuente = tretefuente + Number(dato.retefte + "")
            treteiva = treteiva + Number(dato.reteiva + "")
            treteica = treteica + Number(dato.reteica + "")
            tcree = tcree + Number(dato.cree + "")
            tneto = tneto + Number(dato.neto + "")
        })
        setbruto(tneto)
        setoingresos(toingresos)
        setdescuento(tdescuento)
        setretefuente(tretefuente)
        setreteiva(treteiva)
        setreteica(treteica)
        setcree(tcree)
        setTotal(total)
    }
    const totaltotal = () => {
        var tot = 0
        for (let i = 0; i < Movimiento.ctabono.length; i++) {
            const element = Movimiento.ctabono[i];
            if (element.neto != 0) {
                tot = tot + Number(element.bruto + '')

            }
        }
        return tot
    }
    const totaltotalsaldo = () => {
        var tot = 0
        for (let i = 0; i < Movimiento.ctabono.length; i++) {
            const element = Movimiento.ctabono[i];
            if (element.neto != 0) {
                tot = tot + Number(element.saldo + '')

            }
        }
        return tot
    }
    const guardarabono = () => {
        try {
            var dddd = new Date(Movimiento.fecha)
            if (parseInt(Movimiento.fecha.split("-")[0]) > 2000) {

            } else {
                Swal.fire(
                    'Opps!',
                    'Fecha no Existe',
                    'error'
                )
                return
            }
        } catch (error) {
            Swal.fire(
                'Opps!',
                'Fecha no Existe',
                'error'
            )
            return
        }
        if (Movimiento.ctabono[0]) {
            if (Movimiento.ctabono[0].saldo < 0) {
                Swal.fire(
                    'Opps!',
                    'Saldo del Anticipo no puede quedar Negativo',
                    'error'
                )
                return
            }
            if (Movimiento.ctabono[0].bruto > Movimiento.ctabono[0].efectivo) {
                Swal.fire(
                    'Opps!',
                    'Valor de Factura No puede ser Menor que el Cruce',
                    'error'
                )
                return
            }
        } else {
            Swal.fire(
                'Opps!',
                'No hay nada',
                'error'
            )
            return
        }
        terminarVenta()
    }
    const terminarVenta = () => {

        if (Movimiento.length < 1) {
            Swal.fire(
                'Opps!',
                'No existen productos',
                'error'
            )
            return
        }

        var _movimiento = { ...Movimiento }
        var abonos = _movimiento.ctabono.map((dato, index) => {
            var dat = {
                ...dato,
                ['efectivo']: dato.efectivo ? Number(dato.efectivo) : 0,
                ['bruto']: Number(dato.bruto ? dato.bruto : 0),
                ['neto']: Number(dato.bruto ? dato.bruto : 0),
                ['fecha']: Movimiento.fecha,
                ['ncuota']: "1",
            }
            delete dat.saldo
            return dat
        })
        var abonocompleto = {
            abono: abonos.filter(p => p.neto != 0),
            caja: [Mediodepagos]
        }
        var _Mediosdepago = Mediodepagos.map((dato) => { return dato })
        var mediopagoenvio = _Mediosdepago.map((dato) => {
            var dat = {
                ['caja']: props.caja,
                ['fecha']: Movimiento.fecha,
                ['concepto']: dato.concepto,
                ['numero']: Number(Movimiento.doc + ""),
                ['formapago']: dato.codigo,
                ['afecta']: dato.afecta,
                ['valor']: dato.valor
            }
            return dat
        })
        var devolucion = {
            ['caja']: props.caja,
            ['fecha']: Movimiento.fecha,
            ['concepto']: tipodocumento.codigo,
            ['numero']: Number(Movimiento.doc + ""),
            ['formapago']: 'devolucion',
            ['afecta']: 'credito',
            ['valor']: Number(Movimiento.fletes + "")
        }
        var efectivo2 = {
            ['caja']: props.caja,
            ['fecha']: Movimiento.fecha,
            ['concepto']: tipodocumento.codigo,
            ['numero']: Number(Movimiento.doc + ""),
            ['formapago']: 'efectivo',
            ['afecta']: 'debito',

            ['valor']: Number(Movimiento.efectivo + "")
        }
        mediopagoenvio.push(devolucion)
        mediopagoenvio.push(efectivo2)
        mediopagoenvio = mediopagoenvio.map((dat) => {
            if (dat.valor) {
                return dat
            }
        })
        mediopagoenvio = mediopagoenvio.filter(p => p != undefined)
        abonocompleto.caja = mediopagoenvio
        let dt = JSON.parse(user)
        var nuevv = true
        var entre1 = ""
        var entre2 = ""
        var entre3 = ""
        if (Number(Movimiento.doc + "")) {
            entre1 = "1"
            if (Number(Movimiento.doc + "") != 0) {
                entre2 = "2"
                nuevv = false
            }
        } else {
            entre3 = "3"
        }

        for (let i = 0; i < abonocompleto.abono.length; i++) {
            abonocompleto.abono[i].observacion = observacion

        }
        const api = fetch(props.servidor + '/TesoreriaApi/GenerarCruce?llave=' + dt.usuario.llaveempresa + "&usuario=" + dt.usuario.cedula + '', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(abonocompleto)
        })
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then(async (dataJson) => {
                // reestablecer();
                var data = dataJson;

                Swal.fire(
                    'Exito!',
                    'Se creo el Cruce',
                    'success'
                )
                setMovimiento(modelomovimiento)
                settipodocseleccionado("")
                setcomprobante("")
                settipodocumento(modelotipodocumento)
                setcrucecontable([])
                setobservacion("")
            }).catch((error) => {
                Swal.fire(
                    'Opps!',
                    'No se pudo crear ' + tipodocumento.descripcion,
                    'error'
                )
                // setMovimiento(modelomovimiento)
                // settipodocseleccionado("")
                // setcomprobante("")
                // settipodocumento(modelotipodocumento)
                // setcrucecontable([])
                // console.log("No se pudo enviar la venta ", error)
            })

    }
    const buscartodastipodocumentos = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Fatipodocapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {

                    settipodocumentos(dataJson)
                    setbusquedatipodocumentos(dataJson)
                    console.log(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    settipodocumentos([])
                    setbusquedatipodocumentos([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const eliminarProducto = (id) => {
        var _Movimiento = { ...Movimiento }
        var _datos = { ...Movimiento }['ctabono']
        let _enviodatos = _datos.map((dato, index) => {
            if (index == id) {
            } else {
                return dato
            }
        })
        _Movimiento['ctabono'] = _enviodatos.filter(p => p != undefined)
        // setMovimientos(_Movimientos)

        setMovimiento([])
        setMovimiento(_Movimiento)
        console.log(Movimiento)
        setRoles({ codig: '' })
        console.log(Roles)

        // let listaproductos = productos.filter(p => p.idProducto != id)

        // setProductos(listaproductos)

        // calcularTotal(listaproductos)
    }

    const handleChangeformapago = (e) => {
        if (tipodocumento.codigo != "") {

            let value = e.target.value
            console.log(e.target)
            var _Movimiento2 = { ...Movimiento }
            var _Movimiento = Mediodepagos.map((dato) => { return dato })
            _Movimiento = _Movimiento.map((dato) => {
                if (e.target.name == dato.codigo) {
                    var dat = {
                        ...dato,
                        ['valor']: Number(value + "")
                    }
                    return dat
                } else {
                    return dato
                }
            })
            // if (e.target.name == 'descuentoc') {
            //     _Movimiento2['descuentoc'] = Number(value + "")
            // }
            var tott = total
            if (e.target.name == "efectivo") {
                _Movimiento2['efectivo'] = Number(value + "")
                tott = Number(value + "")
                var efec = tott
                _Movimiento.map((dato) => {
                    efec = efec + Number(dato.valor + "")

                })
                efec = efec - total
                _Movimiento2['fletes'] = (efec > 0) ? (efec) : 0

            } else {
                var efec = tott
                _Movimiento.map((dato) => {
                    efec = efec - Number(dato.valor + "")

                })

                _Movimiento2['efectivo'] = (efec >= 0) ? efec : 0
                _Movimiento2['fletes'] = (efec <= 0) ? (efec * (-1)) : 0
            }

            setMediodepagos(_Movimiento)
            setMovimiento(_Movimiento2)
            setRoles([])
        } else {
            Swal.fire(
                'Opps!',
                'Tiene que seleccionar un Tipo de Documento primero',
                'error'
            )
        }



    }
    const filtrarMovimientos = async (coincidencia) => {
        var _codigomuns = Movimientos.filter((dato) => {
            if (dato.tipodoc.toUpperCase().includes(coincidencia.toUpperCase()) || dato.nombrecli.toUpperCase().includes(coincidencia.toUpperCase()) || (dato.codicli + "").toUpperCase().includes(coincidencia.toUpperCase()) || (dato.doc + "").toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedaMovimientos(_codigomuns)
    }
    const cerrarModalMovimientos = () => {
        setVerModalMovimientos(!verModalMovimientos);
        // setbusquedaMovimientos(Movimientos)
    }
    const seleccionarMovimientosfacturabono = async (e) => {

        var _Movimientos = { ...Movimiento }
        var yahaymovimiento = false
        _Movimientos.ctabono.map((dato, index) => {
            if (dato.doc2) {
                if (dato.doc2 != 0) {
                    yahaymovimiento = true
                }
            } else {

            }
        })
        if (yahaymovimiento) {
            Swal.fire(
                'Opps!',
                'Ya tiene un cruce relacionado',
                'error'
            )
            return
        }
        var datos = _Movimientos.ctabono.map((dato, index) => {
            if (fila == index) {
                var dat = {
                    ...dato,
                    ['doc2']: e.documentoref,
                    ['tipodoc2']: e.tipodoc,
                    ['saldo']: ((dato.saldo - e.valor) >= 0) ? (dato.saldo - e.valor) : 0,
                    ['bruto']: ((dato.saldo - e.valor) >= 0) ? e.valor : dato.saldo,
                    ['efectivo']: e.valor,
                    ['saldosinm']: dato.saldo,
                }
                return dat
            } else {
                return dato
            }
        })
        _Movimientos.ctabono = datos
        setMovimiento(_Movimientos)
        setabrirfacturabono(false)
        totalesabono(_Movimientos)
        // .then((dataJson) => {
        //     _movimiento.famovimiento = dataJson
        //     // calculartotalesmovimiento(dataJson)
        //     setMovimiento(_movimiento)
        //     cerrarModalMovimientos()
        //     seteditar(true)
        //     setnuevo(false)
        //     console.log(dataJson)
        // }).catch((error) => {
        //     Swal.fire(
        //         'Opps!',
        //         'No pudieron cargar los movimientos',
        //         'error'
        //     )
        //     setMovimientos([])
        //     setbusquedaMovimientos([])
        // })

    }
    const seleccionarMovimientos = async (e) => {

        var _movimiento = e
        var _movimientos = e
        _movimientos.ctabono = []
        var tdoc = {}
        tipodocumentos.map((dato, index) => {
            if (dato.codigo.toUpperCase() == _movimiento.tipodoc.toUpperCase()) {
                settipodocumento(dato)
                tdoc = dato
            }

        })
        var tercero = await buscarcaclientesunico2(_movimiento.codicli, tdoc)
        var _movv = []
        Movimientos.map((dato, index) => {
            if (dato.tipodoc.toUpperCase() == _movimiento.tipodoc.toUpperCase() && dato.doc == _movimiento.doc) {

                _movv.push(dato)
            }

        })
        var caja = []
        if (tdoc.afecaj == "suma" || tdoc.afecaj == "resta") {
            caja = await buscarcajapordocumento(_movimiento.tipodoc, _movimiento.doc)

        }
        setmediodepago(caja)
        _movv = _movv.map((dato) => {
            var dat = {
                ...dato,

            }
            delete dat.ctabono
            return dat
        });
        _movimientos.ctabono = _movv
        buscarmovimientosterceroenbusqueda(tercero.caclientes, _movimientos)

        // .then((dataJson) => {
        //     _movimiento.famovimiento = dataJson
        //     // calculartotalesmovimiento(dataJson)
        //     setMovimiento(_movimiento)
        //     cerrarModalMovimientos()
        //     seteditar(true)
        //     setnuevo(false)
        //     console.log(dataJson)
        // }).catch((error) => {
        //     Swal.fire(
        //         'Opps!',
        //         'No pudieron cargar los movimientos',
        //         'error'
        //     )
        //     setMovimientos([])
        //     setbusquedaMovimientos([])
        // })

    }

    const buscartodoslosmovimientos = (coincidencia) => {
        try {
            let dt = JSON.parse(user)
            var url = props.servidor + '/CtabonoApi/encabezados?llave=' + dt.usuario.llaveempresa + ''
            const api = fetch(props.servidor + '/CtabonoApi/encabezados?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setMovimientos(dataJson)
                    setbusquedaMovimientos(dataJson)
                    console.log(dataJson)
                }).catch((error) => {
                    Swal.fire(
                        'Opps!',
                        'No pudieron cargar los movimientos',
                        'error'
                    )
                    setMovimientos([])
                    setbusquedaMovimientos([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <Row>

            <Col sm={12}  >
                <Row>

                    {(true) && <Col sm={5}>
                        <Button onClick={async () => {
                            var yahaymovimiento = {}
                            var _Movimientos = { ...Movimiento }
                            _Movimientos.ctabono.map((dato, index) => {
                                if (dato.doc2) {
                                    if (dato.doc2 != 0) {
                                        yahaymovimiento = dato
                                    }
                                }
                            })
                            if (yahaymovimiento.doc2) {
                                var modelobuscado = await buscarmodelocontable(yahaymovimiento.tipodoc, yahaymovimiento.tipodoc2)
                                if (modelobuscado.length == 0) {
                                    var cruu = []
                                    var cru1 = {
                                        codigo: "",
                                        descripcion: "",
                                        codigo2: yahaymovimiento.tipodoc,
                                        descripcion2: "",
                                        comprob: "",
                                        comprob2: "",
                                        tipodoc: "",
                                        nombre: "",
                                        nombre2: yahaymovimiento.tipodoc2,
                                        cuenta: "",
                                        resdet: "",
                                        dbcr: "credito",
                                        transfer: "simanejatercero",
                                        transfercont: "",
                                        ccosto: "",
                                        iva: "",
                                        tipoitem: "",
                                        transfer2: "",
                                        codter: "",
                                        porc: 0,
                                        tipomod: "",
                                        tipodocd: "",
                                        horafecha: "",
                                        retefte: "",
                                        ica: "",
                                        reteiva: "",
                                        ccostop: "",
                                        modcont: "",
                                        detalle: "nombretercero",

                                    }
                                    var cru2 = {
                                        codigo: "",
                                        descripcion: "",
                                        codigo2: yahaymovimiento.tipodoc2,
                                        descripcion2: "",
                                        comprob: "",
                                        comprob2: "",
                                        tipodoc: "",
                                        nombre: "",
                                        nombre2: yahaymovimiento.tipodoc,
                                        cuenta: "",
                                        resdet: "",
                                        dbcr: "debito",
                                        transfer: "simanejatercero",
                                        transfercont: "",
                                        ccosto: "",
                                        iva: "",
                                        tipoitem: "",
                                        transfer2: "",
                                        codter: "",
                                        porc: 0,
                                        tipomod: "",
                                        tipodocd: "",
                                        horafecha: "",
                                        retefte: "",
                                        ica: "",
                                        reteiva: "",
                                        ccostop: "",
                                        modcont: "",
                                        detalle: "nombretercero",

                                    }
                                    cruu.push(cru1)
                                    cruu.push(cru2)
                                    setcrucecontable(cruu)

                                } else {
                                    var tipod = tipodocumentos.find(p => p.codigo == modelobuscado[0].codigo)
                                    settipodocumento(tipod)
                                    settipodocseleccionado(modelobuscado[0].codigo)
                                    setcrucecontable(modelobuscado)
                                    setcomprobante(modelobuscado[0].comprob)
                                }
                            } else {
                                Swal.fire(
                                    'Opps!',
                                    'No tiene un cruce relacionado',
                                    'error'
                                )
                                return
                            }
                            setabrirmodelocontable(true)
                        }} style={{ fontSize: '12px', backgroundColor: '#188BAD' }} block >
                            <i className="fa fa-cloud"></i> Configurar Modelos Contables</Button>
                    </Col>}
                    {(true) && <Col sm={5}>
                        <Button onClick={() => { guardarabono() }} style={{ fontSize: '12px', backgroundColor: '#188BAD' }} block >
                            <i className="fa fa-cloud"></i> Cruzar Documentos Seleccionados</Button>
                    </Col>}



                    <Col sm={1}>
                        <Button style={{ fontSize: '12px', backgroundColor: '#FE0000', color: 'black' }} block onClick={() => { props.setctabonos(false) }} >
                            <i className="fa fa-times"></i></Button>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col sm={12}>
                        <Card>
                            <CardHeader style={{ backgroundColor: '#4e73df', color: "white" }}>
                                <Row>
                                    <Col sm={2}>
                                        {"Tercero" + tabespacio}
                                    </Col>

                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={3}>
                                        <FormGroup inline={true}>
                                            <Label>Tercero</Label>
                                            <InputGroup>
                                                <Input value={Movimiento.codicli} bsSize="sm"
                                                    onKeyDown={(e) => {

                                                        if (e.key == "Enter") {
                                                            if (dataUser.empresa.modulos.ARTICULOS.electronico) {
                                                                buscartodasterceros('');
                                                            }
                                                            setbusquedaterceros(terceros)
                                                            setVerModalterceros(true)

                                                        } else {


                                                        }
                                                    }} onBlur={(e) => {
                                                        cambiarterceros(e)
                                                    }}
                                                    onChange={(e) => {
                                                        var _Movimiento = { ...Movimiento }
                                                        _Movimiento['codicli'] = e.target.value
                                                        setMovimiento(_Movimiento)
                                                    }} name="codicli" />
                                                <Button color="primary" size="sm" className="mr-2"
                                                    onClick={() => {
                                                        if (dataUser.empresa.modulos.ARTICULOS.electronico) {
                                                            buscartodasterceros('');
                                                        }
                                                        setbusquedaterceros(terceros)
                                                        setVerModalterceros(true)

                                                    }}
                                                >
                                                    <i className="fas fa-pen-alt"></ i >
                                                </Button>
                                            </InputGroup>

                                        </FormGroup>
                                    </Col>


                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>-</Label>
                                            <Input onChange={handleChange} value={Movimiento.nombrecli} type="text" bsSize="sm" name="nombrecli" />
                                        </FormGroup>
                                    </Col>



                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Fecha</Label>
                                            <Input name="fecha" value={Movimiento.fecha} onChange={handleChange} type="date" bsSize="sm" />
                                        </FormGroup>
                                    </Col>



                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardHeader style={{ backgroundColor: '#4e73df', color: "white" }}>
                                Movimientos
                            </CardHeader>
                            <CardBody>

                                <Row>
                                    <Col sm={12}>
                                        <div className="table-responsive" style={{ height: '300px', overflow: 'auto' }}>

                                            <Table striped size="sm">
                                                <thead>
                                                    <tr>
                                                        <th>Concepto</th>
                                                        <th>Concecutivo</th>
                                                        <th>Saldo</th>
                                                        <th>Conc. Referencia </th>
                                                        <th>Doc. Referencia </th>
                                                        <th>Bruto</th>
                                                        <th>Neto</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (Movimiento.length < 1) ? (
                                                            <tr>
                                                                <td colSpan="5">Sin Movimientos</td>
                                                            </tr>
                                                        ) :
                                                            (
                                                                Movimiento.ctabono.map((item, index) => (
                                                                    <tr key={index}>

                                                                        <td>{item.tipodoc}</td>
                                                                        <td>{item.doc}</td>
                                                                        <td>{fm2.from(item.saldo)}</td>
                                                                        <td>{item.tipodoc2}</td>
                                                                        <td>{item.doc2}</td>
                                                                        <td><Input style={{ width: '200px' }} onFocus={(e) => {
                                                                            setfila(index)
                                                                            e.target.select()
                                                                        }}
                                                                            value={item.bruto ? item.bruto : 0} onChange={(e) => {
                                                                                var _movimiento = { ...Movimiento }
                                                                                var datos = _movimiento.ctabono.map((dato, index) => {
                                                                                    if (index == fila && (dato.doc2 != 0 && dato.doc2 != undefined)) {
                                                                                        var dat = {
                                                                                            ...dato,
                                                                                            ['bruto']: Number(e.target.value),
                                                                                            ['saldo']: Number(dato.saldosinm + "") - Number(e.target.value),
                                                                                        }
                                                                                        return dat
                                                                                    } else {
                                                                                        return dato
                                                                                    }
                                                                                })
                                                                                _movimiento.ctabono = datos
                                                                                setMovimiento(_movimiento)
                                                                            }}></Input></td>
                                                                        <td>{fm2.from(item.neto)}</td>
                                                                        <td>
                                                                            <Button style={{ backgroundColor: '#4e73df', color: "white" }} size="sm"
                                                                                onClick={() => {
                                                                                    setfila(index)
                                                                                    setabrirfacturabono(true)
                                                                                }}
                                                                            >
                                                                                <i className="fas fa-search-plus"></i>
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            )


                                                    }
                                                </tbody>
                                            </Table>
                                        </div>

                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Col sm={12}>
                    <label>Observaciones</label>
                    <Input type="textarea" value={observacion} onChange={(e) => { setobservacion(e.target.value) }} name={"observacion"}></Input>
                </Col>
            </Col >


            {
                puedoanular && <Col sm={1}>
                    <Button style={{ fontSize: '12px', backgroundColor: '#FE0000', color: 'white' }} block onClick={() => {
                        let dt = JSON.parse(user)
                        var _movimiento = { ...Movimiento }

                        var abonos = _movimiento.ctabono.map((dato, index) => {
                            var efff = dato.efectivo ? dato.efectivo : '0'
                            var dat = {
                                ...dato,
                                ['efectivo']: Number(efff),
                                ['vlcuota']: Number(dato.vlcuota),
                                ['bruto']: Number(dato.bruto),
                                ['iva']: Number(dato.iva),
                                ['dscto']: Number(dato.dscto),
                                ['retefte']: Number(dato.retefte),
                                ['reteica']: Number(dato.reteica),
                                ['reteiva']: Number(dato.reteiva),
                                ['neto']: Number(dato.neto),
                                ['despiefac']: Number(dato.despiefac),
                                ['impconsumo']: Number(dato.impconsumo),
                                ['cuotai']: Number(dato.cuotai),
                                ['ncuotasi']: Number(dato.ncuotasi),
                                ['diferidasi']: Number(dato.diferidasi),
                                ['tipocre']: Number(dato.tipocre),
                                ['ncuotasf']: Number(dato.ncuotasf),
                                ['tasa']: Number(dato.tasa),
                                ['diferidas']: Number(dato.diferidas),
                                ['cheque']: Number(dato.cheque),
                                ['tdebito']: Number(dato.tdebito),
                                ['tcredito']: Number(dato.tcredito),
                                ['descuentoc']: Number(dato.descuentoc),
                                ['cree']: Number(dato.cree),
                                ['creeporc']: Number(dato.creeporc),
                                ['fecha']: Movimiento.fecha
                            }
                            delete dat.saldo
                            return dat
                        })

                        const api = fetch("" + props.servidor + "/CtabonoApi/anular/" + dt.usuario.cedula + "?llave=" + dt.usuario.llaveempresa + "", {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json;charset=utf-8'
                            },
                            body: JSON.stringify(abonos)
                        })
                            .then((response) => {
                                return response.ok ? response.json() : Promise.reject(response);
                            })
                            .then(async (dataJson) => {
                                Swal.fire(
                                    'Exito!',
                                    'Se anulo el abono: ',
                                    'success'
                                )

                            }).catch(async (error) => {
                                try {
                                    var ee = await error.json()
                                    Swal.fire(
                                        'Opps!',
                                        'No se pudo anular el abono: ' + ee.mensaje,
                                        'error'
                                    )
                                    console.log("No se pudo enviar la venta ", ee.mensaje)
                                    // guardarlogdeerrores(ee.mensaje)
                                } catch (error2) {
                                    Swal.fire(
                                        'Opps!',
                                        'No se pudo anular el movimiento: ' + 'Error de conexion',
                                        'error'
                                    )
                                    console.log("No se pudo enviar la venta ", error2)
                                    // guardarlogdeerrores('Error de conexion')
                                }

                            })

                    }} >
                        Anular</Button>
                </Col>
            }
            {/* el señor edwin tiene esto para mostrar el nombre de otra cosa que no sea el real y ocultar el nit y correo */}


            <Modal size="lg" style={{ maxWidth: '1300px', width: '95%', color: 'black' }} isOpen={abrirmodelocontable}>

                <ModalBody id={"pruebapdfventaelectronica"} >
                    <Row>
                        <Col sm={3}>
                            <FormGroup inline={true}>
                                <Label>Tipo Doc</Label>
                                <InputGroup >
                                    <Input name="tipodoc" value={tipodocseleccionado} bsSize="sm"
                                        // onChange={(e) => {
                                        //     var _Movimiento = { ...Movimiento }
                                        //     _Movimiento['tipodoc'] = e.target.value

                                        //     setMovimiento(_Movimiento)

                                        // }}
                                        onBlur={(e) => {
                                            // seleccionartipodocumentos2(e)
                                        }}
                                        onKeyDown={(e) => {
                                            buscartodastipodocumentos('');
                                            setbusquedatipodocumentos(tipodocumentos)
                                            setVerModaltipodocumentos(true)

                                        }} />
                                    <Button color="primary" size="sm" className="mr-2"
                                        onClick={() => {
                                            buscartodastipodocumentos('');

                                            setbusquedatipodocumentos(tipodocumentos)
                                            setVerModaltipodocumentos(true)

                                        }}
                                    >
                                        <i className="fas fa-pen-alt"></ i >
                                    </Button>
                                </InputGroup>

                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup inline={true}>
                                <Label>Comprobante</Label>
                                <InputGroup >
                                    <Input name="comprobante" value={comprobante} bsSize="sm"
                                        // onChange={(e) => {
                                        //     var _Movimiento = { ...Movimiento }
                                        //     _Movimiento['tipodoc'] = e.target.value

                                        //     setMovimiento(_Movimiento)

                                        // }}
                                        onBlur={(e) => {
                                            // seleccionartipodocumentos2(e)
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key == "Enter") {

                                                setabrircomprobantes(true)


                                            } else {

                                            }
                                        }} />
                                    <Button color="primary" size="sm" className="mr-2"
                                        onClick={() => {
                                            setabrircomprobantes(true)
                                        }}
                                    >
                                        <i className="fas fa-pen-alt"></ i >
                                    </Button>
                                </InputGroup>

                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={2}>
                            <FormGroup inline={true}>
                                <Label>Nombre</Label>
                                <InputGroup >
                                    <Input name="codigo2" disabled value={crucecontable.length > 0 ? crucecontable[0].codigo2 : ""} bsSize="sm"

                                        onBlur={(e) => {

                                        }}
                                        onKeyDown={(e) => {
                                            // buscartodastipodocumentos('');
                                            // setbusquedatipodocumentos(tipodocumentos)
                                            // setVerModaltipodocumentos(true)

                                        }} />

                                </InputGroup>

                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup inline={true}>
                                <Label>Cuenta</Label>
                                <InputGroup >
                                    <Input name="cuenta" value={crucecontable.length > 0 ? crucecontable[0].cuenta : ""} bsSize="sm"

                                        onBlur={(e) => {

                                        }}
                                        onKeyDown={(e) => {
                                            setabrircuentas(true)
                                            setfila(0)

                                        }} />

                                </InputGroup>

                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup inline={true}>
                                <Label>DB-CR</Label>
                                <InputGroup >
                                    <Input type="select" name="dbcr" value={crucecontable.length > 0 ? crucecontable[0].dbcr : ""} bsSize="sm"
                                        onChange={(e) => {
                                            var datos = crucecontable.map((dato, index) => {
                                                if (0 == index) {
                                                    var dat = {
                                                        ...dato,
                                                        ['dbcr']: e.target.value,
                                                    }
                                                    return dat
                                                } else {
                                                    return dato
                                                }
                                            })
                                            setcrucecontable(datos)
                                        }}
                                    >
                                        <option value="debito">Debito</option>
                                        <option value="credito">Credito</option>
                                    </Input>

                                </InputGroup>

                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup inline={true}>
                                <Label>Transfiere</Label>
                                <InputGroup >
                                    <Input type="select" name="transfer" value={crucecontable.length > 0 ? crucecontable[0].transfer : ""} bsSize="sm"
                                        onChange={(e) => {
                                            var datos = crucecontable.map((dato, index) => {
                                                if (0 == index) {
                                                    var dat = {
                                                        ...dato,
                                                        ['transfer']: e.target.value,
                                                    }
                                                    return dat
                                                } else {
                                                    return dato
                                                }
                                            })
                                            setcrucecontable(datos)
                                        }}
                                    >
                                        <option value="nomanejatercero">No maneja tercero</option>
                                        <option value="simanejatercero">Si maneja tercero</option>
                                        <option value="manejaterceroybase">Maneja tercero y base</option>
                                    </Input>

                                </InputGroup>

                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup inline={true}>
                                <Label>Detalle</Label>
                                <InputGroup >
                                    <Input type="select" name="tipodoc" value={crucecontable.length > 0 ? crucecontable[0].detalle : ""} bsSize="sm"
                                        onChange={(e) => {
                                            var datos = crucecontable.map((dato, index) => {
                                                if (0 == index) {
                                                    var dat = {
                                                        ...dato,
                                                        ['detalle']: e.target.value,
                                                    }
                                                    return dat
                                                } else {
                                                    return dato
                                                }
                                            })
                                            setcrucecontable(datos)
                                        }}
                                    >
                                        <option value="nombretercero">Nombre Tercero</option>
                                        <option value="tipodedocumento">Tipo de Documento</option>
                                        <option value="detalle">Detalle</option>
                                        <option value="nombredecuenta">Nombre de Cuenta</option>
                                    </Input>

                                </InputGroup>

                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup inline={true}>
                                <Label>C. Costo</Label>
                                <InputGroup >
                                    <Input name="ccosto" value={crucecontable.length > 0 ? crucecontable[0].ccosto : ""} bsSize="sm"

                                        onBlur={(e) => {

                                        }}
                                        onKeyDown={(e) => {
                                            // buscartodastipodocumentos('');
                                            // setbusquedatipodocumentos(tipodocumentos)
                                            // setVerModaltipodocumentos(true)

                                        }} />

                                </InputGroup>

                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={2}>
                            <FormGroup inline={true}>
                                <Label>Nombre</Label>
                                <InputGroup >
                                    <Input name="codigo2" disabled value={crucecontable.length > 0 ? crucecontable[1].codigo2 : ""} bsSize="sm"

                                        onBlur={(e) => {

                                        }}
                                        onKeyDown={(e) => {
                                            // buscartodastipodocumentos('');
                                            // setbusquedatipodocumentos(tipodocumentos)
                                            // setVerModaltipodocumentos(true)

                                        }} />

                                </InputGroup>

                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup inline={true}>
                                <Label>Cuenta</Label>
                                <InputGroup >
                                    <Input name="cuenta" value={crucecontable.length > 0 ? crucecontable[1].cuenta : ""} bsSize="sm"

                                        onBlur={(e) => {

                                        }}
                                        onKeyDown={(e) => {
                                            setfila(1)
                                            setabrircuentas(true)

                                            // buscartodastipodocumentos('');
                                            // setbusquedatipodocumentos(tipodocumentos)
                                            // setVerModaltipodocumentos(true)

                                        }} />

                                </InputGroup>

                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup inline={true}>
                                <Label>DB-CR</Label>
                                <InputGroup >
                                    <Input type="select" name="dbcr" value={crucecontable.length > 0 ? crucecontable[1].dbcr : ""} bsSize="sm"
                                        onChange={(e) => {
                                            var datos = crucecontable.map((dato, index) => {
                                                if (1 == index) {
                                                    var dat = {
                                                        ...dato,
                                                        ['dbcr']: e.target.value,
                                                    }
                                                    return dat
                                                } else {
                                                    return dato
                                                }
                                            })
                                            setcrucecontable(datos)
                                        }}
                                    >
                                        <option value="debito">Debito</option>
                                        <option value="credito">Credito</option>
                                    </Input>

                                </InputGroup>

                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup inline={true}>
                                <Label>Transfiere</Label>
                                <InputGroup >
                                    <Input type="select" name="transfer" value={crucecontable.length > 0 ? crucecontable[1].transfer : ""} bsSize="sm"
                                        onChange={(e) => {
                                            var datos = crucecontable.map((dato, index) => {
                                                if (1 == index) {
                                                    var dat = {
                                                        ...dato,
                                                        ['transfer']: e.target.value,
                                                    }
                                                    return dat
                                                } else {
                                                    return dato
                                                }
                                            })
                                            setcrucecontable(datos)
                                        }}
                                    >
                                        <option value="nomanejatercero">No maneja tercero</option>
                                        <option value="simanejatercero">Si maneja tercero</option>
                                        <option value="manejaterceroybase">Maneja tercero y base</option>
                                    </Input>

                                </InputGroup>

                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup inline={true}>
                                <Label>Detalle</Label>
                                <InputGroup >
                                    <Input type="select" name="tipodoc" value={crucecontable.length > 0 ? crucecontable[1].detalle : ""} bsSize="sm"
                                        onChange={(e) => {
                                            var datos = crucecontable.map((dato, index) => {
                                                if (1 == index) {
                                                    var dat = {
                                                        ...dato,
                                                        ['detalle']: e.target.value,
                                                    }
                                                    return dat
                                                } else {
                                                    return dato
                                                }
                                            })
                                            setcrucecontable(datos)
                                        }}
                                    >
                                        <option value="nombretercero">Nombre Tercero</option>
                                        <option value="tipodedocumento">Tipo de Documento</option>
                                        <option value="detalle">Detalle</option>
                                        <option value="nombredecuenta">Nombre de Cuenta</option>
                                    </Input>

                                </InputGroup>

                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup inline={true}>
                                <Label>C. Costo</Label>
                                <InputGroup >
                                    <Input name="ccosto" value={crucecontable.length > 0 ? crucecontable[1].ccosto : ""} bsSize="sm"

                                        onBlur={(e) => {

                                        }}
                                        onKeyDown={(e) => {
                                            // buscartodastipodocumentos('');
                                            // setbusquedatipodocumentos(tipodocumentos)
                                            // setVerModaltipodocumentos(true)

                                        }} />

                                </InputGroup>

                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>


                    <Button size="sm" color="primary" onClick={() => {
                        guardarmodelocontable()
                    }}>Guardar</Button>
                    <Button size="sm" color="primary" onClick={() => {
                        setabrirmodelocontable(false)

                    }}>Cerrar</Button>
                </ModalFooter>
            </Modal>


            <Buscar tituloencabezado={"Buscar Tipo Documento"} filtrar={(coincidencia) => { filtrartipodocumentos(coincidencia) }} busqueda={busquedatipodocumentos} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"descripcion"} dato={""} dato2={""} verModal={verModaltipodocumentos} cerrarModal={() => { cerrarModaltipodocumentos() }} seleccionar={(seleccionado) => {
                seleccionartipodocumentos(seleccionado)
            }} />
            <Buscar tituloencabezado={"Buscar Tercero"} filtrar={(coincidencia) => { filtrarterceros(coincidencia) }} busqueda={busquedaterceros} encabezado={["Codigo", "Razon Social", "Tipo"]} codigo={"codigo"} nombre={"razsoc"} dato={"tercero"} dato2={""} verModal={verModalterceros} cerrarModal={() => { cerrarModalterceros() }} seleccionar={(seleccionado) => {
                seleccionarterceros(seleccionado)
            }} />
            <Buscar tituloencabezado={"Buscar Vendedor"} filtrar={(coincidencia) => { filtrarvendedors(coincidencia) }} busqueda={busquedavendedors} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"nombre"} dato={""} dato2={""} verModal={verModalvendedors} cerrarModal={() => { cerrarModalvendedors() }} seleccionar={(seleccionado) => {
                seleccionarvendedors(seleccionado)
            }} />
            <BuscarMovimiento tituloencabezado={"Buscar Movimientos"} filtrar={(coincidencia) => { filtrarMovimientos(coincidencia) }} busqueda={busquedaMovimientos} encabezado={["Tercero", "Nombre", "Concepto", "Consecutivo"]} codigo={"codicli"} nombre={"nombrecli"} dato={"tipodoc"} dato2={"doc"} verModal={verModalMovimientos} cerrarModal={() => { cerrarModalMovimientos() }} seleccionar={(seleccionado) => {
                seleccionarMovimientos(seleccionado)
            }} />
            <BuscarMovimiento tituloencabezado={"Seleccionar Factura"} filtrar={(coincidencia) => { filtrarMovimientos(coincidencia) }} busqueda={movimientostercero2} encabezado={["Concepto", "Consecutivo", "Valor"]} codigo={"tipodoc"} nombre={"documentoref"} dato={"valor"} dato2={""} verModal={abrirfacturabono} cerrarModal={() => { setabrirfacturabono(false) }} seleccionar={(seleccionado) => {
                seleccionarMovimientosfacturabono(seleccionado)
            }} />
            <BuscarComprobante tituloencabezado={"Buscar Comprobante"} abrir={abrircomprobantes} servidor={props.servidor} setabrirbanco={(ab) => { setabrircomprobantes(ab) }} seleccionar={(value) => {
                // handleChangegrillafaencmovi('', value, 'acreedor', 'codigo')
                setcomprobante(value ? value.codigo : "-")
                setabrircomprobantes(false)
                // setcodtercinicial(value ? value.codigo : "")
                // buscarterceronombre(value.codigo)
            }} />
            <BuscarCuentas tituloencabezado={"Buscar Cuentas"} abrir={abrircuentas} servidor={props.servidor} setabrirbanco={(ab) => { setabrircuentas(ab) }} seleccionar={(value) => {
                // handleChangegrillafaencmovi('', value, 'acreedor', 'codigo')
                var datos = crucecontable.map((dato, index) => {
                    if (fila == index) {
                        var ter = "nomanejatercero"
                        if (value.idanexo == "s") {
                            ter = "simanejatercero"
                        }
                        if (value.idanexo == "b") {
                            ter = "manejaterceroybase"
                        }
                        var dat = {
                            ...dato,
                            ['cuenta']: value.codcuenta,
                            ['transfer']: ter
                        }
                        return dat
                    } else {
                        return dato
                    }
                })
                setcrucecontable(datos)
                setabrircuentas(false)
                // setcodtercinicial(value ? value.codigo : "")
                // buscarterceronombre(value.codigo)
            }} />

        </Row >

    )
}

export default Venta;