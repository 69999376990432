
import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import Swal from 'sweetalert2'
import Autosuggest from 'react-autosuggest';
import { useContext, useState, useEffect } from "react";
import "./css/Venta.css"
import { UserContext } from "../context/UserProvider";
import { jsPDF } from "jspdf";
import { FormatMoney } from 'format-money-js'
import Buscar from '../componentes/Buscar'
import { Autocomplete, TextField } from '@mui/material';
import BuscarMovimiento from '../componentes/Buscarmovimiento'
import BuscarCuentas from '../componentes/Buscar2/Cuentas'
import BuscarCentrodeCosto from '../componentes/Buscar2/CentrodeCosto'
import BuscarTerceros from '../componentes/Buscar2/Terceros'
const tabespacio = '\u00A0';

const modelo = {
    usuario: {
        id: "",
        cedula: "",
        nitempresa: "",
        nombre: "",
        nombreempresa: "",
        contraseña: "",
        llaveempresa: "",
        llaveusuario: "",
        cargo: "",
        departamento: "",
        Eliminado: "",
    },
    empresa: {
        empresa: {
            id: "",
            nit: "",
            nombre: "",
            descripcion: "",
            telefono: "",
            direccion: "",
            email: "",
            celular: "",
            llave: "",
            servidor: "",
            basededatos: "",
            activo: "",
            fechaultimopago: "",
            maximafechadeuso: "",
            Eliminado: "",
        },
        config: {
            carpeta_documentos: "",
            carpeta_bd: "",
            contribuyente_nit: "",
            contribuyente_razon_social: "",
            contribuyente_tipo: "",
            contribuyente_responsabilidad_fiscal: "",
            contribuyente_regimen_fiscal: "",
            contribuyente_matricula_mercantil: "",
            direccion_contribuyente_linea: "",
            direccion_contribuyente_municipio: "",
            direccion_contribuyente_departamento: "",
            direccion_contribuyente_pais: "",
            contribuyente_email: "",
            contribuyente_telefono: "",
            software_en_pruebas: "",
            usar_set_pruebas: "",
            identificador_set_pruebas: "",
            identificador_software: "",
            rango_numero_resolucion: "",
            rango_fecha_resolucion: "",
            rango_vigencia_desde: "",
            rango_vigencia_hasta: "",
            rango_clave_tecnica: "",
            rango_prefijo: "",
            rango_desde: "",
            rango_hasta: "",
            ruta_certificado: "",
            clave_certificado: "",
            encabezado_pdf: "",
            ruta_logo: "",
            smtp_host: "",
            smtp_puerto: "",
            smtp_ssl: "",
            smtp_usuario: "",
            smtp_clave: "",
            sucursal: "",
            pin_software: "",
            especialidad: "",
            Eliminado: "",
        },
        modulos: {
            FACTURA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            INVENTARIO: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CARTERA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            FACTURAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CONTABILIDAD: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            TESORERIA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            COMPRA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            RADIAN: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            MAESTROS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            ADMINISTRACION: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            DATOSBASICOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }, ARTICULOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }



        }
    }
}

const modelotipodocumento = {
    codigo: "",
    descripcion: "",
    tipodoc: "",
    tdpro: "",
    trasauto: "",
    tipoprod: "",
    actuacu: "",
    comactacu: "",
    rentab: "",
    afecinv: "",
    serial: "",
    descuentoc: "",
    actcosto: "",
    numitem: "",
    despiece: "",
    exportacion: "",
    afecar: "",
    rcauto: "",
    causadoc: "",
    afecaj: "nada",
    formapago: "si",
    afeban: "",
    docban: "",
    muesdescbanc: "",
    asicont: "",
    consuni: "",
    conscom: "",
    precsug: "",
    descsug: "",
    cupocred: "",
    permexisne: "",
    conpunreor: "",
    facdebcos: "",
    camprecven: "",
    camdesc: "",
    cambioiva: "",
    cambioimp: "",
    confacven: "",
    consecfac: "",
    camdescenc: "",
    camdescpp: "",
    pass1: "",
    pass2: "",
    pass3: "",
    pass4: "",
    pass5: "",
    pass6: "",
    pass7: "",
    pass8: "",
    pass9: "",
    pass10: "",
    ivaincluido: "",
    busplu: "",
    ccosto: "",
    centrocosto: "",
    tcostofijo: "",
    comprob: "",
    impto: "",
    tbodegasn: "",
    tbodega: "",
    tbodegafija: "",
    descsn: "si",
    descporc: "",
    ivasn: "si",
    ivaporc: "",
    rete: "si",
    ica: "si",
    reteiva: "si",
    imptosn: "si",
    imptoporc: "",
    captionimpto: "",
    enviacorreo: "",
    ctrlmov: "",
    docauto: "",
    tdpro1: "",
    tdpro2: "",
    filtroart: "",
    facturapap: "",
    mvendedor: "si",
    captionvend: "",
    docu1: "si",
    captiondoc1: "",
    val_doc1: "",
    docu2: "si",
    captiondoc2: "",
    observa: "",
    obslinea: "",
    mdevolucio: "",
    mediospago: "",
    puntoventa: "",
    modidoc: "",
    modidocenc: "",
    noimpdupl: "",
    rentabilid: "",
    redondeofac: "",

}

const modelonat = [
    {
        codigo: '1',
        nombre: 'Colgaap'
    }, {
        codigo: '2',
        nombre: 'Axl'
    }, {
        codigo: '3',
        nombre: 'Depreciacion'
    }, {
        codigo: '4',
        nombre: 'Aj.D'
    }, {
        codigo: '5',
        nombre: 'Amortizacion'
    }, {
        codigo: '6',
        nombre: 'Aj.Am'
    }, {
        codigo: '7',
        nombre: 'IFRS/NIC'
    }, {
        codigo: '8',
        nombre: 'Depreciacion IFRS'
    }, {
        codigo: '9',
        nombre: 'Revaluacion'
    }, {
        codigo: '10',
        nombre: 'Depreciacion Revaluacion'
    }, {
        codigo: '11',
        nombre: 'Costo Atribuido'
    }, {
        codigo: '12',
        nombre: 'Depreciacion Costo Atribuido'
    }, {
        codigo: '13',
        nombre: 'Deterioro'
    }
]
const modelocontmovidet = {

    item: '',
    codcuenta: '',
    nat: '1',
    documentoref: '',
    valordeb: '',
    valorcre: '',
    acreedor: '',
    valorbase: '',
    codcentrocostos: '',
    comentarios: '',
    codact: '',//ahora esto es la fecha de conciliacion
    coddif: '',
    codcompro: '',
    tercero: '',
    ano: '',
    periodo: '',
    tipodoc: '',
    ndocumento: '',
    descripcion: '',
    conciliado: '',
    activosfij: '',
    horafecha: '',
    sucursal: '',
}
const modelocontabilidad = {
    tipodoc: '',
    ndocumento: '',
    ano: new Date().getFullYear() + "",
    sucursal: '',
    periodo: ("0" + (new Date().getMonth() + 1)).slice(-2),
    fecha: new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2),
    codcompro: '',
    descripcion: '',
    debito: '',
    credito: '',
    diferencia: '',
    origen: '',
    niifdb: '',
    niifcr: '',
    niifdif: '',
    revelacion: '',
    horafecha: '',
    contmovidet: []
}

const Venta = (props) => {
    const { user } = useContext(UserContext)
    const [dataUser, setDataUser] = useState(modelo)
    const [documentoCliente, setDocumentoCliente] = useState("")
    const [nombreCliente, setNombreCliente] = useState("")
    const [movimiento, setmovimiento] = useState(modelocontabilidad)
    const [movimientos, setmovimientos] = useState(modelocontabilidad)
    const [roles, setRoles] = useState(modelocontabilidad)
    const [tipodocumentos, settipodocumentos] = useState([]);
    const [busquedatipodocumentos, setbusquedatipodocumentos] = useState([]);
    const [verModaltipodocumentos, setVerModaltipodocumentos] = useState(false);
    const [fechahoy, setfechahoy] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [Ctcomprobantess, setCtcomprobantess] = useState([]);
    const [fila, setfila] = useState(0);
    const [busquedaMovimientos, setbusquedaMovimientos] = useState([]);
    const [abriricuentas, setabriricuentas] = useState(false);
    const [abrirterceros, setabrirterceros] = useState(false);
    const [abriricentrodecosto, setabriricentrodecosto] = useState(false);
    const [nombrecliente, setnombrecliente] = useState("");
    const [previsualizarpdfelectronica, setprevisualizarpdfelectronica] = useState(false);



    const [nuevo, setnuevo] = useState(false);
    const [editar, seteditar] = useState(false);
    const [desabilitar, setdesabilitar] = useState(false);
    const [tipodocumento, settipodocumento] = useState(modelotipodocumento)
    const [previsualizar, setprevisualizar] = useState(false)

    const [fm, setfm] = useState(new FormatMoney({ symbol: '' }));
    const [fm1, setfm1] = useState(new FormatMoney({ symbol: '', decimals: 1 }));
    const [fm2, setfm2] = useState(new FormatMoney({ symbol: '', decimals: 2 }));
    const [fm3, setfm3] = useState(new FormatMoney({ symbol: '$', decimals: 2 }));
    const [Plandecuentass, setPlandecuentass] = useState([]);
    const [mensajecuen, setmensajecuen] = useState('');

    const [Caclientess, setCaclientess] = useState([]);
    const [busquedacaclientes, setbusquedacaclientes] = useState([]);
    const [contadorcaclientes, setcontadorcaclientes] = useState(100);
    const [Ctcentrocostos, setCtcentrocostos] = useState([]);
    const [verModalMovimientos, setVerModalMovimientos] = useState(false);

    const reestablecer = () => {

    }
    const guardarmovimiento = () => {
        terminarVenta();
    }
    const cerrarModalMovimientos = () => {
        setVerModalMovimientos(!verModalMovimientos);
        // setbusquedaMovimientos(Movimientos)
    }
    const filtrarMovimientos = async (coincidencia) => {
        try {
            var _codigomuns = movimientos.filter((dato) => {
                if (dato.contmovienc.tipodoc.toUpperCase().includes(coincidencia.toUpperCase()) || (dato.contmovienc.ndocumento + "").toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

            })
            setbusquedaMovimientos(_codigomuns)
        } catch (error) {

        }
    }

    const seleccionarMovimientos = (e) => {

        var _faencmovi = e.contmovienc
        var _movimiento = _faencmovi
        var tipp = {}
        tipodocumentos.map((dato, index) => {
            if (dato.codigo.toUpperCase() == _faencmovi.tipodoc.toUpperCase()) {
                settipodocumento(dato)
                tipp = dato
            }

        })
        // terceros.map((dato, index) => {

        //     if (dato.codigo.toUpperCase() == _faencmovi.cliente.toUpperCase()) {
        //         settercero(dato)
        //     }

        // })

        try {

            let dt = JSON.parse(user)
            var url = props.servidor + '/ContabilidadApi/cuerpo/' + '?llave=' + dt.usuario.llaveempresa + ''
            const api = fetch(props.servidor + '/ContabilidadApi/cuerpo/' + '?llave=' + dt.usuario.llaveempresa + '', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(e)
            })
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then(async (dataJson) => {
                    _movimiento.contmovidet = dataJson
                    var buscli = []
                    _movimiento.contmovidet.forEach(element => {
                        buscli.push(element.acreedor)
                    });
                    var clientes = []
                    for (let i = 0; i < buscli.length; i++) {
                        const element = buscli[i];
                        var client = await buscarcoincidenciasterceros(element)
                        if (client) {
                            for (let j = 0; j < client.length; j++) {
                                const element2 = client[j];
                                clientes.push(element2)
                            }
                        }
                    }
                    setbusquedacaclientes(clientes);

                    // for (let i = 0; i < _movimiento.contmovidet.length; i++) {
                    //     const element = _movimiento.contmovidet[i];
                    //     var artt = listaarticulo
                    //     var e = {}
                    //     e = await buscararticulounico(element.referencia)
                    //     artt[i] = e
                    //     setlistaarticulo(artt)
                    // }

                    // calculartotalesmovimiento(dataJson)
                    setmovimiento(_movimiento)
                    cerrarModalMovimientos()
                    seteditar(true)
                    setnuevo(false)
                    setdesabilitar(true)
                    // if (tipp.modcont != "contabilidad" && tipp.modcont != "contabilidad-tesoreria") {
                    //     seteditar(false)
                    //     setnuevo(false)
                    //     setdesabilitar(false)
                    // } else {
                    //     seteditar(true)
                    //     setnuevo(false)
                    //     setdesabilitar(true)
                    // }

                    console.log(dataJson)
                }).catch((error) => {
                    Swal.fire(
                        'Opps!',
                        'No pudieron cargar los movimientos',
                        'error'
                    )
                    setmovimientos([])
                    setbusquedaMovimientos([])
                })
        } catch (error) {
            console.log(error)
        }

    }


    const obtenerPlandecuentass = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Plandecuentasapi/ultimonivel?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setPlandecuentass(data)
                // setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const totaldebito = () => {
        var debito = 0
        movimiento.contmovidet.forEach(element => {
            if (element.nat == "1" || element.nat == "2" || element.nat == "3" || element.nat == "4" || element.nat == "5" || element.nat == "6") {
                debito = debito + Number(element.valordeb + "")

            }
        });
        return debito
    }
    const buscarterceronombre = async (codigo) => {
        try {
            var client = await buscarcaclientesunico(codigo)
            // return `${acreedor} - ${razsoc}`;
            var puente = codigo + " - " + client.caclientes.razsoc
            console.log(puente)
            setnombrecliente(puente)
            // return ""
        } catch (error) {
            // return ""
        }
    }

    const buscarcentrocostonombre = (codigo) => {
        var caclientes2 = Ctcentrocostos.map((dat) => {
            return dat
        })
        var tercero = caclientes2.filter(e => e.codigo == codigo)
        return tercero.length != 0 ? tercero[0].nombre : ""
    }
    const buscarcuentanombre = (codigo) => {
        var caclientes2 = Plandecuentass.map((dat) => {
            return dat
        })
        var tercero = caclientes2.filter(e => e.codcuenta == codigo)
        return tercero.length != 0 ? tercero[0].descripcion : ""
    }
    const totalcredito = () => {
        var credito = 0
        movimiento.contmovidet.forEach(element => {
            if (element.nat == "1" || element.nat == "2" || element.nat == "3" || element.nat == "4" || element.nat == "5" || element.nat == "6") {
                credito = credito + Number(element.valorcre)
            }
        });
        return credito
    }
    const totaldebitoniff = () => {
        var debito = 0
        movimiento.contmovidet.forEach(element => {
            if (element.nat == "7" || element.nat == "8" || element.nat == "9" || element.nat == "10" || element.nat == "11" || element.nat == "12" || element.nat == "13") {
                debito = debito + Number(element.valordeb + "")

            }
        });
        return debito
    }
    const totalcreditoniff = () => {
        var credito = 0
        movimiento.contmovidet.forEach(element => {
            if (element.nat == "7" || element.nat == "8" || element.nat == "9" || element.nat == "10" || element.nat == "11" || element.nat == "12" || element.nat == "13") {
                credito = credito + Number(element.valorcre)

            }
        });
        return credito
    }
    const obtenerCaclientess = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Caclientesapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setCaclientess(data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const obtenerCtcentrocostos = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Ctcentrocostoapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setCtcentrocostos(data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        buscartodastipodocumentos('');
        obtenerCtcomprobantess('');
        obtenerPlandecuentass('');
        obtenerCtcentrocostos('');

    }, [])
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        if (abriricuentas) {
            obtenerPlandecuentass('');

        }

    }, [abriricuentas])
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        try {
            buscarterceronombre(movimiento.contmovidet[fila].acreedor)

        } catch (error) {

        }



    }, [fila])

    const anular = async (coincidencia, tercero) => {
        try {

            if (!tercero) {
                tercero = "proveedor"
            }
            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/contabilidadApi/anular/' + movimiento.tipodoc + '/' + movimiento.ndocumento + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                Swal.fire(
                    'Listo!',
                    'Anulado correctamente',
                    'success'
                )

            }
        } catch (error) {
            console.log(error)
        }
    }
    const eliminarProducto = (id) => {
        var _Movimiento = { ...movimiento }
        var _datos = { ...movimiento }['contmovidet']
        let _enviodatos = _datos.map((dato, index) => {
            if (index == id) {
            } else {
                return dato
            }
        })
        _Movimiento['contmovidet'] = _enviodatos.filter(p => p != undefined)
        setmovimiento([])
        setmovimiento(_Movimiento)
        console.log(movimiento)
    }


    const obtenerCtcomprobantess = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Ctcomprobantesapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setCtcomprobantess(data)
                // setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const seleccionartipodocumentos = async (e) => {
        let value = e
        var _Movimiento = { ...movimiento }
        _Movimiento['tipodoc'] = value.codigo
        _Movimiento['codcompro'] = value.comprob

        setmovimiento(_Movimiento)
        setRoles([])
        cerrarModaltipodocumentos()
        settipodocumento(value)
    }
    const cerrarModaltipodocumentos = () => {
        setVerModaltipodocumentos(!verModaltipodocumentos);
        setbusquedatipodocumentos(tipodocumentos)
    }
    const filtrartipodocumentos = async (coincidencia) => {
        var _codigomuns = tipodocumentos.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.descripcion.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedatipodocumentos(_codigomuns)
    }
    const buscartodastipodocumentos = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Fatipodocapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    settipodocumentos(dataJson)

                    dataJson = dataJson.filter((dato) => {
                        if (dato.tipodoc == 'contabilidad') {
                            return dato
                        }
                    })
                    setbusquedatipodocumentos(dataJson)
                    console.log(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    settipodocumentos([])
                    setbusquedatipodocumentos([])
                })
        } catch (error) {
            console.log(error)
        }
    }

    const handleChangegrillafaencmovi = async (event, value, name, codi) => {

        var _Movimiento = { ...movimiento }
        var subtabla = "contmovidet"

        var vll = ''
        if (value) {
            vll = value[codi]
        }
        var _datos = { ...movimiento }[subtabla]
        var _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    [name]: vll
                }
                return dat
            } else {
                return dato
            }
        })
        if (name == 'acreedor') {
            _enviodatos = _enviodatos.map((dato, index) => {
                if (index == fila) {
                    var dat = {
                        ...dato,
                        ['tercero']: value ? value['tercero'] : ''
                    }
                    return dat
                } else {
                    return dato
                }
            })
        }
        _Movimiento[subtabla] = _enviodatos
        // setmovimientos(_Movimientos)

        setmovimiento(_Movimiento)
        console.log(movimiento)
        setRoles({ codig: '' })

    }

    const handleChangegrillafaencmovi22 = async (event, value, name, codi) => {

        var _Movimiento = { ...movimiento }
        var subtabla = "contmovidet"

        var vll = event.target.value
        var _datos = { ...movimiento }[subtabla]
        var _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    [name]: vll
                }
                return dat
            } else {
                return dato
            }
        })

        _Movimiento[subtabla] = _enviodatos
        // setmovimientos(_Movimientos)

        setmovimiento(_Movimiento)
        console.log(movimiento)
        setRoles({ codig: '' })

    }

    const handleChangegrillafaencmovi2 = async (event, name) => {

        var _Movimiento = { ...movimiento }
        var subtabla = "contmovidet"
        if (event.target.name != undefined) {
            name = event.target.name
        }
        var _datos = { ...movimiento }[subtabla]
        var _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    [name]: event.target.value
                }
                return dat
            } else {
                return dato
            }
        })

        if (event.target.name == "valordeb" || event.target.name == "valorcre") {
            _enviodatos = _enviodatos.map((dato, index) => {
                if (index == fila) {
                    var value = Plandecuentass.find(p => p.codcuenta == dato['codcuenta'])
                    if (value) {
                        if (value.idanexo == "b") {
                            var dat = {
                                ...dato,
                                ['valorbase']: Number(event.target.value) ? Number(event.target.value) * (100 / (Number(value.porcbase + ""))) : 0
                            }
                            return dat
                        } else {
                            return dato
                        }
                    } else {
                        return dato
                    }

                } else {
                    return dato
                }
            })
        }
        _Movimiento[subtabla] = _enviodatos
        // setmovimientos(_Movimientos)

        setmovimiento(_Movimiento)
        console.log(movimiento)
        setRoles({ codig: '' })

    }
    const handleOpen = async (e) => {
        if (e) {
            var value = e.target.value
            if (value) {
                if (value.length >= 3) {
                    var clientes = await buscarcoincidenciasterceros(value)
                    setbusquedacaclientes(clientes);
                }
            }
        }
    };
    const buscarcaclientesunico = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Caclientesapi/unico/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            console.log(error)
        }
    }
    const buscarcoincidenciasterceros = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Caclientesapi/unicocoincidecnias/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            Swal.fire(
                'Opps!',
                'No se pudo buscar los terceros',
                'error'
            )
            return []
        }
    }
    const handleChange = (e) => {
        // if (tipodocumento.codigo == "") {

        let value = e.target.value
        console.log(e.target)
        var _Movimiento = { ...movimiento }
        _Movimiento[e.target.name] = value
        if (e.target.name == "fecha") {
            try {
                _Movimiento['periodo'] = value.split('-')[1]
                _Movimiento['ano'] = value.split('-')[0]
            } catch (error) {

            }

        }
        setmovimiento(_Movimiento)
        setRoles([])
        // } else {
        //     Swal.fire(
        //         'Opps!',
        //         'Tiene que seleccionar un Tipo de Documento primero',
        //         'error'
        //     )
        // }



    }


    const tipodedocumentoexiste = async (e) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Fatipodocapi/unico/' + e + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                if (data.codigo != '') {
                    return true
                } else {
                    return false
                }

                // setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const verificar = (_movimiento) => {
        var contmovienc = _movimiento.contmovienc
        var famovimiento = _movimiento.contmovidet
        var verificado = true
        var mensg = ''

        if (contmovienc.tipodoc == "" || contmovienc.fecha == "") {
            var verff2 = {
                verificado: false,
                mensaje: 'No hay tipo de docmento seleccionao o Fecha en blanco'
            }
            return verff2
        }

        famovimiento.map((dato, index) => {
            if (dato.codcuenta == "" || dato.nat == "") {
                verificado = false
                mensg = mensg + " " + " Cuenta: " + dato.codcuenta + " esta en blanco o la naturaleza lo esta. Item: " + (index + 1)
            } else {
                var cuents = Plandecuentass.map((dat) => {
                    return dat
                })
                var cuent = cuents.filter(p => p.codcuenta == dato.codcuenta)
                if (cuent.length > 0) {
                    if (cuent[0].idanexo == "s" || cuent[0].idanexo == "b") {
                        if (dato.acreedor == "") {
                            verificado = false
                            mensg = mensg + " " + " Cuenta: " + dato.codcuenta + " si maneja tercero. Item: " + (index + 1)
                        }
                    } else {
                        if (dato.acreedor != "") {
                            verificado = false
                            mensg = mensg + " " + " Cuenta: " + dato.codcuenta + " no maneja tercero. Item: " + (index + 1)
                        }
                    }
                    if (cuent[0].idcentrocosto == "s") {
                        if (dato.codcentrocostos == "") {
                            verificado = false
                            mensg = mensg + " " + " Cuenta: " + dato.codcuenta + " si maneja Centro de Costo. Item: " + (index + 1)
                        }
                    } else {
                        if (dato.codcentrocostos != "") {
                            verificado = false
                            mensg = mensg + " " + " Cuenta: " + dato.codcuenta + " no maneja Centro de Costo. Item: " + (index + 1)
                        }
                    }
                }
            }

        })
        var verff = {
            verificado: verificado,
            mensaje: mensg
        }
        return verff
    }
    const reiniciar = () => {
        setnuevo(false)
        seteditar(false)
        setdesabilitar(false)
        setmovimiento(modelocontabilidad)
    }

    const guardarlogdeerrores = (contenido) => {
        const a = document.createElement("a");
        const archivo = new Blob([contenido], { type: 'text/plain' });
        const url = URL.createObjectURL(archivo);
        a.href = url;
        a.download = 'log';
        a.click();
        URL.revokeObjectURL(url);
    }

    const buscartodoslosmovimientos = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            var url = props.servidor + '/ContabilidadApi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + ''
            const api = fetch(props.servidor + '/ContabilidadApi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setmovimientos(dataJson)
                    setbusquedaMovimientos(dataJson)
                    console.log(dataJson)
                }).catch((error) => {
                    Swal.fire(
                        'Opps!',
                        'No pudieron cargar los movimientos',
                        'error'
                    )
                    setmovimientos([])
                    setbusquedaMovimientos([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const previsualizarpdfelectronica222 = () => {

        const doc = new jsPDF('p', 'pt', 'a4');
        doc.page = 1; // use this as a counter.
        console.log(doc.getFontList())
        var escala = (doc.internal.pageSize.width - 10) / document.getElementById('pruebapdfventaelectronica').clientWidth
        doc.html(document.querySelector("#pruebapdfventaelectronica"), {
            callback: function (pdf) {
                doc.autoPrint();

                doc.output('dataurlnewwindow', { filename: 'comprobante.pdf' });
            },
            html2canvas: {
                scale: escala
            }
        })
    }
    const terminarVenta = () => {
        // if (!cajaabierta) {
        //     Swal.fire(
        //         'Opps!',
        //         'La caja esta cerrada para este dia',
        //         'error'
        //     )
        //     return
        // }
        try {
            var dddd = new Date(movimiento.fecha)
            if (parseInt(movimiento.fecha.split("-")[0]) > 2000) {

            } else {
                Swal.fire(
                    'Opps!',
                    'Fecha no Existe',
                    'error'
                )
                return
            }
        } catch (error) {
            Swal.fire(
                'Opps!',
                'Fecha no Existe',
                'error'
            )
            return
        }
        if (tipodocumento.modcont != "contabilidad" && tipodocumento.modcont != "contabilidad-tesoreria") {
            Swal.fire(
                'Opps!',
                'No puede Editar este Tipo de Documento',
                'error'
            )
            return
        }
        if (Math.round(totaldebito() - totalcredito()) != 0 || Math.round(totaldebitoniff() - totalcreditoniff()) != 0) {
            Swal.fire(
                'Opps!',
                'Asiento Descuadrado',
                'error'
            )
            return
        }
        if (movimiento.contmovidet.length < 1) {
            Swal.fire(
                'Opps!',
                'No existen productos',
                'error'
            )
            return
        }
        var tipodcoumentoecxiste = tipodedocumentoexiste(movimiento.tipodoc)
        if (!tipodcoumentoecxiste) {
            Swal.fire(
                'Opps!',
                'Este tipo de documento no existe',
                'error'
            )
            return
        } var movimientoguardado = {
            contmovienc: { ...movimiento },
            contmovidet: movimiento.contmovidet
        }

        delete movimientoguardado.contmovienc.contmovidet;
        movimientoguardado.contmovienc.ndocumento = Number(movimientoguardado.contmovienc.ndocumento + "")
        movimientoguardado.contmovienc.debito = Number(movimientoguardado.contmovienc.debito + "")
        movimientoguardado.contmovienc.credito = Number(movimientoguardado.contmovienc.credito + "")
        movimientoguardado.contmovienc.diferencia = Number(movimientoguardado.contmovienc.diferencia + "")

        for (let i = 0; i < movimientoguardado.contmovidet.length; i++) {
            movimientoguardado.contmovidet[i].item = 0
            movimientoguardado.contmovidet[i].ndocumento = Number(movimientoguardado.contmovidet[i].ndocumento + "")
            movimientoguardado.contmovidet[i].documentoref = Number(movimientoguardado.contmovidet[i].documentoref + "")
            movimientoguardado.contmovidet[i].valordeb = Number(movimientoguardado.contmovidet[i].valordeb + "")
            movimientoguardado.contmovidet[i].valorcre = Number(movimientoguardado.contmovidet[i].valorcre + "")
            movimientoguardado.contmovidet[i].valorbase = Number(movimientoguardado.contmovidet[i].valorbase + "")
            movimientoguardado.contmovidet[i].activosfij = Number(movimientoguardado.contmovidet[i].activosfij + "")

        }
        movimientoguardado.contmovidet.forEach(element => {

        });
        let dt = JSON.parse(user)
        var verificado = verificar(movimientoguardado)
        if (!verificado.verificado) {
            Swal.fire(
                'Opps!',
                'Datos sin llenar' + ' ' + verificado.mensaje,
                'error'
            )
            return
        }
        const api = fetch("" + props.servidor + "/ContabilidadApi?llave=" + dt.usuario.llaveempresa + "&nuevo=" + nuevo + "", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(movimientoguardado)
        })
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then(async (dataJson) => {
                // reestablecer();
                var data = dataJson;
                if (nuevo) {
                    Swal.fire(
                        '' + movimiento.tipodoc + ' Creado!',
                        'Numero de ' + movimiento.tipodoc + ': ' + data.movimiento.contmovienc.ndocumento,
                        'success'
                    )
                    var _movv = data.movimiento.contmovienc
                    _movv.contmovidet = data.movimiento.contmovidet
                    setmovimiento(_movv)
                } else {
                    Swal.fire(
                        '' + data.movimiento.contmovienc.tipodoc + ' Editado!',
                        'Numero de ' + data.movimiento.contmovienc.tipodoc + ' : ' + data.movimiento.contmovienc.ndocumento,
                        'success'
                    )
                    var _movv = data.movimiento.contmovienc
                    _movv.contmovidet = data.movimiento.contmovidet
                    setmovimiento(_movv)
                }

            }).catch(async (error) => {
                try {
                    var ee = await error.json()
                    Swal.fire(
                        'Opps!',
                        'No se pudo crear el movimiento: ' + ee.mensaje + ' ' + mensajecuen,
                        'error'
                    )
                    console.log("No se pudo enviar la venta ", ee.mensaje)
                    guardarlogdeerrores(ee.mensaje)
                } catch (error) {
                    Swal.fire(
                        'Opps!',
                        'No se pudo crear el movimiento: ' + 'Error de conexion',
                        'error'
                    )
                    console.log("No se pudo enviar la venta ", error)
                    guardarlogdeerrores('Error de conexion')
                }

            })

    }
    return (
        <Row>
            <Col sm={12} >
                <Card style={{ borderColor: '#fff' }}>
                    <CardBody>
                        <Row>
                            <Col sm={2}>
                                <Button style={{ fontSize: '12px', color: 'black' }} color={nuevo ? "warning" : "success"} block onClick={() => {
                                    setnuevo(true)
                                    setdesabilitar(false)
                                    setmovimiento(modelocontabilidad)
                                }} >
                                    <i className="fa fa-plus"></i> Agregar</Button>
                            </Col>
                            {(true) && <Col sm={2}>
                                <Button style={{ fontSize: '12px' }} color="primary" block onClick={() => {
                                    setnuevo(false)
                                    buscartodoslosmovimientos('')
                                    seteditar(true)
                                    setVerModalMovimientos(true)
                                }} >
                                    <i className="fa fa-search-plus"></i> Buscar</Button>
                            </Col>}
                            {(true) && <Col sm={2}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#188BAD' }} block onClick={guardarmovimiento} >
                                    <i className="fa fa-cloud"></i> Guardar</Button>
                            </Col>}
                            {(true) && <Col sm={2}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#F5F5DC', color: 'black' }} block onClick={() => {
                                    setprevisualizar(true)

                                }} >
                                    <i className="fa fa-paper-plane"></i> PDF</Button>
                            </Col>}
                            <Col sm={2}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {
                                    var _movimiento = { ...movimiento }
                                    _movimiento.ndocumento = 0
                                    setmovimiento(_movimiento)
                                    setnuevo(true)
                                    seteditar(false)
                                }} >
                                    <i className="fa fa-clone"></i> Utilizar Plantilla</Button>
                            </Col>

                            <Col sm={1}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#FE0000', color: 'black' }} block onClick={() => { props.setcontabilidad(false) }} >
                                    <i className="fa fa-times"></i></Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            <Col sm={12} className={(nuevo || editar) ? "" : "desabilitar"}>

                <Row className="mb-2">
                    <Col sm={12}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={3}>
                                        <FormGroup inline={true}>
                                            <Label>Tipo Doc</Label>
                                            <InputGroup >
                                                <Input name="concepto" value={movimiento.tipodoc} bsSize="sm" onKeyDown={(e) => {
                                                    if (e.key == "Enter" && e.target.value == "" && movimiento.contmovidet.length < 1) {
                                                        buscartodastipodocumentos('');
                                                        setbusquedatipodocumentos(tipodocumentos)
                                                        setVerModaltipodocumentos(true)


                                                    } else {
                                                        if (movimiento.contmovidet.length > 1) {
                                                            Swal.fire(
                                                                'Opps!',
                                                                'Al tener Movimientos agregados no puede cambiar el tipo de documento',
                                                                'error'
                                                            )
                                                        }
                                                    }
                                                }} />
                                                <Button color="primary" size="sm" className="mr-2"
                                                    onClick={() => {
                                                        if (movimiento.contmovidet.length < 1) {
                                                            buscartodastipodocumentos('');

                                                            setbusquedatipodocumentos(tipodocumentos)
                                                            setVerModaltipodocumentos(true)
                                                        } else {
                                                            Swal.fire(
                                                                'Opps!',
                                                                'Al tener Movimientos agregados no puede cambiar el tipo de documento',
                                                                'error'
                                                            )

                                                        }

                                                    }}
                                                >
                                                    <i className="fas fa-pen-alt"></ i >
                                                </Button>
                                            </InputGroup>

                                        </FormGroup>
                                    </Col>

                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Comprobante</Label>
                                            <Input type="select" bsSize="sm" value={movimiento.codcompro} name="codcompro" onChange={handleChange} >
                                                <option value="">--</option>
                                                {Ctcomprobantess.map((dato) => {
                                                    return <option value={dato.codigo}>{dato.nombre}</option>
                                                })}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Fecha</Label>
                                            <Input type="date" bsSize="sm" value={movimiento.fecha} name="fecha" onChange={handleChange} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Numero de Documento</Label>
                                            <Label style={{ backgroundColor: '#188BAD', width: '200px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{movimiento.ndocumento}</Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Descripcion</Label>
                                            <Input bsSize="sm" value={movimiento.descripcion} name="descripcion" onChange={handleChange} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={2}>
                                        <FormGroup>
                                            <Label>-</Label>
                                            <Button style={{ fontSize: '12px', color: 'black' }} color="success" block
                                                onClick={() => {
                                                    if (movimiento.tipodoc != "") {
                                                        var _Movimiento = { ...movimiento }
                                                        var _datos = { ...movimiento }['contmovidet']
                                                        var _nuevosdatos = _datos.concat(
                                                            {

                                                                item: '',
                                                                codcuenta: '',
                                                                nat: '1',
                                                                documentoref: '',
                                                                valordeb: '',
                                                                valorcre: '',
                                                                acreedor: '',
                                                                valorbase: '',
                                                                codcentrocostos: '',
                                                                comentarios: movimiento.descripcion,
                                                                codact: '',//ahora esto es la fecha de conciliacion
                                                                coddif: '',
                                                                codcompro: _Movimiento.codcompro,
                                                                tercero: '',
                                                                ano: '',
                                                                periodo: '',
                                                                tipodoc: _Movimiento.tipodoc,
                                                                ndocumento: _Movimiento.ndocumento,
                                                                descripcion: '',
                                                                conciliado: '',
                                                                activosfij: '',
                                                                horafecha: '',
                                                                sucursal: '',

                                                            }

                                                        )
                                                        _Movimiento['contmovidet'] = _nuevosdatos
                                                        setmovimiento(_Movimiento)

                                                    } else {
                                                        Swal.fire(
                                                            'Opps!',
                                                            'No ha seleccionado un Tipo de Documento',
                                                            'error'
                                                        )
                                                    }
                                                }} >
                                                <i className="fa fa-plus"></i> Agregar Item</Button>
                                        </FormGroup>
                                    </Col>

                                    <Col sm={2}>
                                        <FormGroup>
                                            <Label style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Año</Label>
                                            <Label style={{ backgroundColor: '#188BAD', width: '130px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{movimiento.ano}</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={2}>
                                        <FormGroup>
                                            <Label style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Periodo</Label>
                                            <Label style={{ backgroundColor: '#188BAD', width: '130px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{movimiento.periodo}</Label>
                                        </FormGroup>
                                    </Col>
                                    {movimiento.anulado == "anulado" && <Col sm={2}>
                                        <div style={{ color: 'red' }}> {movimiento.anulado == "anulado" && "ANULADA"}</div>

                                    </Col>}
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardBody>

                                <Row>
                                    <Col sm={12}>
                                        <div className="table-responsive" style={{ height: '300px', zoom: '70%' }}>

                                            <Table striped size="sm">
                                                <thead>
                                                    <tr>
                                                        <th style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>It</th>
                                                        <th style={{ color: 'black', textAlign: 'center', fontWeight: 'bold', width: '10%' }}>Cuenta</th>
                                                        <th style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Naturaleza</th>
                                                        <th style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Doc.Referencia</th>
                                                        <th style={{ color: 'black', textAlign: 'center', fontWeight: 'bold', width: '200px' }}>Debito</th>
                                                        <th style={{ color: 'black', textAlign: 'center', fontWeight: 'bold', width: '10%' }}>Credito</th>
                                                        <th style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Tercero</th>
                                                        <th style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Valor Base</th>
                                                        <th style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>C.C.</th>
                                                        <th style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Comentarios</th>
                                                        <th style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Activo</th>
                                                        <th style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Diferido</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (movimiento.contmovidet.length < 1) ? (
                                                            <tr  >
                                                                <td style={{ color: 'black', fontWeight: 'bold' }} colSpan="15">Sin movimientos</td>
                                                            </tr>
                                                        ) :
                                                            (
                                                                movimiento.contmovidet.map((item, index) => (
                                                                    <tr key={item.idProducto}>
                                                                        <td>
                                                                            <Button color="danger" size="sm"
                                                                                onClick={() => { eliminarProducto(index) }}
                                                                            >
                                                                                <i className="fas fa-trash-alt"></i>
                                                                            </Button>
                                                                        </td>
                                                                        {/* <td>
                                                                            <Autocomplete
                                                                                onFocus={(e) => { setfila(index) }}
                                                                                onChange={(e, value) => { handleChangegrillafaencmovi(e, value, 'codcuenta', 'codcuenta') }}

                                                                                disablePortal
                                                                                options={Plandecuentass}
                                                                                getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                                                                value={Plandecuentass.filter(p => p.codcuenta == item.codcuenta)[0]}
                                                                                renderInput={(params) => <TextField {...params} size="small" style={{ width: '200px', borderRadius: '4px' }} label="Cuenta" />}
                                                                                maxHeight={200}
                                                                            />
                                                                        </td> */}
                                                                        <td>
                                                                            <Input onFocus={() => { setfila(index) }} style={{ width: '200px' }} type="text" onKeyDown={(e) => {
                                                                                if (e.key == "Enter") {
                                                                                    setabriricuentas(true)
                                                                                }
                                                                            }}
                                                                                onChange={(e) => {
                                                                                    var _Movimiento = { ...movimiento }
                                                                                    var subtabla = "contmovidet"
                                                                                    var _datos = { ...movimiento }[subtabla]
                                                                                    var _enviodatos = _datos.map((dato, index2) => {
                                                                                        if (index2 == index) {
                                                                                            var dat = {
                                                                                                ...dato,
                                                                                                ['codcuenta']: e.target.value
                                                                                            }
                                                                                            return dat
                                                                                        } else {
                                                                                            return dato
                                                                                        }
                                                                                    })
                                                                                    _Movimiento[subtabla] = _enviodatos
                                                                                    setmovimiento(_Movimiento)

                                                                                }}

                                                                                onBlur={async (e) => {

                                                                                    var value = Plandecuentass.find(p => p.codcuenta == e.target.value.toUpperCase())
                                                                                    if (value) {

                                                                                        handleChangegrillafaencmovi('', value, 'codcuenta', 'codcuenta')


                                                                                    } else {
                                                                                        Swal.fire(
                                                                                            'Opps!',
                                                                                            'No Existe la Cuenta',
                                                                                            'error'
                                                                                        )
                                                                                    }
                                                                                }}

                                                                                name="codcuenta" value={item.codcuenta} placeholder="Presione Enter Para Buscar" />
                                                                        </td>
                                                                        {/* <td>
                                                                            <Autocomplete
                                                                                onFocus={(e) => { setfila(index) }}
                                                                                onChange={(e, values) => { handleChangegrillafaencmovi(e, values, 'nat', 'codigo') }}
                                                                                name="tercero"
                                                                                disablePortal
                                                                                options={modelonat}
                                                                                getOptionLabel={(clasi) => clasi.nombre}
                                                                                sx={{ width: 150, height: 50, borderRadius: 4 }}
                                                                                value={modelonat.filter(p => p.codigo == item.nat)[0]}
                                                                                style={{ fontSize: 14 }}
                                                                                renderInput={(params) => <TextField {...params} size="small" name="nat" label="Naturaleza" />}
                                                                                onInputChange={handleOpen}
                                                                            />
                                                                        </td> */}
                                                                        <td>
                                                                            <Input onFocus={() => { setfila(index) }} style={{ width: '200px' }} type="select" onChange={(e) => {
                                                                                handleChangegrillafaencmovi22(e, modelonat, 'nat', 'codigo')
                                                                            }} name="nat" value={item.nat} >
                                                                                {modelonat.map((dat) => {
                                                                                    return <option value={dat.codigo}>{dat.nombre}</option>
                                                                                })}

                                                                            </Input>
                                                                        </td>
                                                                        <td> <Input onFocus={(e) => { setfila(index) }} name="documentoref" value={item.documentoref} tabla={'contmovidet'} onChange={(e) => { handleChangegrillafaencmovi2(e, 'documentoref') }}></Input>
                                                                        </td>
                                                                        <td> <Input style={{ width: '200px' }} onFocus={(e) => {
                                                                            setfila(index)
                                                                            e.target.value = (item.valordeb + "").replace(/\,/g, '')
                                                                        }} onBlur={(e) => {
                                                                            e.target.value = fm2.from(Number(item.valordeb))
                                                                        }} disabled={item.valorcre != "" && item.valorcre != 0 && item.valorcre != "0"} name="valordeb" value={item.valordeb} tabla={'contmovidet'} onChange={(e) => { handleChangegrillafaencmovi2(e, 'valordeb') }}></Input>
                                                                        </td>
                                                                        <td> <Input style={{ width: '200px' }} onBlur={(e) => {
                                                                            e.target.value = fm2.from(Number(item.valorcre))
                                                                        }} onFocus={(e) => {
                                                                            setfila(index)
                                                                            e.target.value = (item.valorcre + "").replace(/\,/g, '')

                                                                        }} disabled={item.valordeb != "" && item.valordeb != 0 && item.valordeb != "0"} name="valorcre" value={item.valorcre} tabla={'contmovidet'} onChange={(e) => { handleChangegrillafaencmovi2(e, 'valorcre') }}></Input>
                                                                        </td>

                                                                        <Input onFocus={() => { setfila(index) }} style={{ width: '200px' }} type="text" onKeyDown={(e) => {
                                                                            if (e.key == "Enter") {
                                                                                setabrirterceros(true)
                                                                            }
                                                                        }} placeholder="Presione Enter Para Buscar" disabled={Plandecuentass.find(p => p.codcuenta == item.codcuenta) ?
                                                                            Plandecuentass.find(p => p.codcuenta == item.codcuenta).idanexo == "n" ? true : false : false} name="acreedor" value={item.acreedor}
                                                                            onChange={(e) => {
                                                                                var _Movimiento = { ...movimiento }
                                                                                var subtabla = "contmovidet"
                                                                                var _datos = { ...movimiento }[subtabla]
                                                                                var _enviodatos = _datos.map((dato, index2) => {
                                                                                    if (index2 == index) {
                                                                                        var dat = {
                                                                                            ...dato,
                                                                                            ['acreedor']: e.target.value
                                                                                        }
                                                                                        return dat
                                                                                    } else {
                                                                                        return dato
                                                                                    }
                                                                                })
                                                                                _Movimiento[subtabla] = _enviodatos
                                                                                setmovimiento(_Movimiento)

                                                                            }}
                                                                            onBlur={async (e) => {

                                                                                if (e.target.value != "") {
                                                                                    var value = await buscarcaclientesunico(e.target.value.toUpperCase())

                                                                                    if (value) {
                                                                                        if (value.caclientes.codigo != "") {
                                                                                            handleChangegrillafaencmovi('', value.caclientes, 'acreedor', 'codigo')
                                                                                            buscarterceronombre(value.caclientes.codigo, value.caclientes.tercero)
                                                                                        } else {

                                                                                            if (!abrirterceros) {
                                                                                                Swal.fire(
                                                                                                    'Opps!',
                                                                                                    'No Existe el acreedor',
                                                                                                    'error'
                                                                                                )
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }} />
                                                                        <td> <Input style={{ width: '200px' }} onFocus={(e) => { setfila(index) }} name="valorbase" value={item.valorbase} tabla={'contmovidet'} onChange={(e) => { handleChangegrillafaencmovi2(e, 'valorbase') }}></Input>
                                                                        </td>
                                                                        {/* <td>
                                                                            <Autocomplete
                                                                                onFocus={(e) => { setfila(index) }}
                                                                                onChange={(e, values) => { handleChangegrillafaencmovi(e, values, 'codcentrocostos', 'codigo') }}
                                                                                name="tercero"
                                                                                disablePortal
                                                                                options={Ctcentrocostos.filter(p => p.subcodigo != '')}
                                                                                getOptionLabel={(clasi) => clasi.nombre}
                                                                                sx={{ width: 150, height: 50, borderRadius: 4 }}
                                                                                value={Ctcentrocostos.filter(p => p.codigo == item.codcentrocostos)[0]}
                                                                                style={{ fontSize: 14 }}
                                                                                renderInput={(params) => <TextField {...params} size="small" name="codcentrocostos" label="Centro de Costo" />}
                                                                                onInputChange={handleOpen}
                                                                            />
                                                                        </td> */}
                                                                        <td>
                                                                            <Input onFocus={() => { setfila(index) }} style={{ width: '200px' }} type="text" onKeyDown={(e) => {
                                                                                if (e.key == "Enter") {
                                                                                    setabriricentrodecosto(true)
                                                                                }
                                                                            }} onBlur={async (e) => {

                                                                                if (e.target.value != "") {

                                                                                    var existe = Ctcentrocostos.find(p => p.codigo == e.target.value)
                                                                                    if (existe) {

                                                                                    } else {
                                                                                        Swal.fire(
                                                                                            'Opps!',
                                                                                            'No Existe el Centro de Costo',
                                                                                            'error'
                                                                                        )
                                                                                    }
                                                                                }
                                                                            }} disabled={Plandecuentass.find(p => p.codcuenta == item.codcuenta) ?
                                                                                Plandecuentass.find(p => p.codcuenta == item.codcuenta).idcentrocosto == "n" ? true : false : false} onChange={(e) => { handleChangegrillafaencmovi2(e, 'codcentrocostos') }} name="codcentrocostos" value={item.codcentrocostos} placeholder="Presione Enter Para Buscar" />
                                                                        </td>
                                                                        <td> <Input style={{ width: '200px' }} onFocus={(e) => { setfila(index) }} name="comentarios" value={item.comentarios} tabla={'contmovidet'} onChange={(e) => { handleChangegrillafaencmovi2(e, 'comentarios') }}></Input>
                                                                        </td>
                                                                        <td> <Input style={{ width: '200px' }} onFocus={(e) => { setfila(index) }} name="activosfij" value={item.activosfij} tabla={'contmovidet'} onChange={(e) => { handleChangegrillafaencmovi2(e, 'activosfij') }}></Input>
                                                                        </td>
                                                                        <td> <Input style={{ width: '200px' }} onFocus={(e) => { setfila(index) }} name="conciliado" value={item.conciliado} tabla={'contmovidet'} onChange={(e) => { handleChangegrillafaencmovi2(e, 'conciliado') }}></Input>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            )


                                                    }
                                                </tbody>
                                            </Table>
                                        </div>

                                    </Col>

                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={2}>
                        <FormGroup>
                            <Label>-</Label>
                            <Button style={{ fontSize: '12px', color: 'black' }} color="success" block
                                onClick={() => {

                                    obtenerPlandecuentass('')
                                }} >
                                <i className="fa fa-plus"></i> Actualizar Cuentas</Button>
                        </FormGroup>
                    </Col>
                    <Col sm={2}>
                        <FormGroup>
                            <Label>-</Label>
                            <Button style={{ fontSize: '12px', color: 'white' }} color="danger" block
                                onClick={async () => {

                                    await anular('')
                                }} >
                                <i className="fa fa-plus"></i> Anular</Button>
                        </FormGroup>
                    </Col>
                </Row>
                <Row >
                    <Col sm={7}>
                        <Card style={{ borderColor: '#188BAD' }}>
                            <CardBody className="mb-2 " >
                                <Col sm={12}>
                                    <div >
                                        <Label style={{ display: 'inline-block', borderRadius: '10px', backgroundColor: '#188BAD', width: '90px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{"Cuenta:"}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '10px', color: 'black', textAlign: 'center', fontWeight: 'bold' }}>{movimiento.contmovidet[fila] ? movimiento.contmovidet[fila].codcuenta + " - " + buscarcuentanombre(movimiento.contmovidet[fila].codcuenta) : ''}</Label>
                                    </div>
                                    <div >
                                        <Label style={{ display: 'inline-block', borderRadius: '10px', backgroundColor: '#188BAD', width: '90px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{"Tercero:"}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '10px', color: 'black', textAlign: 'center', fontWeight: 'bold' }}>{movimiento.contmovidet[fila] ? nombrecliente : ''}</Label>
                                    </div>
                                    <div >
                                        <Label style={{ display: 'inline-block', borderRadius: '10px', backgroundColor: '#188BAD', width: '90px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{"C.Costo:"}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '10px', color: 'black', textAlign: 'center', fontWeight: 'bold' }}>{movimiento.contmovidet[fila] ? movimiento.contmovidet[fila].codcentrocostos + "-" + buscarcentrocostonombre(movimiento.contmovidet[fila].codcentrocostos) : ''}</Label>
                                    </div>
                                    <div >
                                        <Label style={{ display: 'inline-block', borderRadius: '10px', backgroundColor: '#188BAD', width: '90px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{"Comprob:"}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '10px', color: 'black', textAlign: 'center', fontWeight: 'bold' }}>{movimiento.codcompro}</Label>
                                    </div>
                                    <hr></hr>
                                    <div >
                                        <Label style={{ display: 'inline-block', borderRadius: '10px', backgroundColor: '#188BAD', width: '90px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{"Activo:"}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '10px', color: 'black', textAlign: 'center', fontWeight: 'bold' }}></Label>
                                    </div>
                                    <div >
                                        <Label style={{ display: 'inline-block', borderRadius: '10px', backgroundColor: '#188BAD', width: '90px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{"Diferido:"}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '10px', color: 'black', textAlign: 'center', fontWeight: 'bold' }}></Label>
                                    </div>
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={5}>
                        <Card style={{ borderColor: '#188BAD' }}>
                            <CardBody className="mb-2 " >
                                <Col sm={12}>
                                    <div >
                                        <Label style={{ display: 'inline-block', borderRadius: '10px', backgroundColor: '#188BAD', width: '90px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{"Debito:"}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '10px', color: 'black', textAlign: 'center', fontWeight: 'bold' }}>{fm3.from(totaldebito())}</Label>
                                    </div>
                                    <div >
                                        <Label style={{ display: 'inline-block', borderRadius: '10px', backgroundColor: '#188BAD', width: '90px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{"Credito:"}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '10px', color: 'black', textAlign: 'center', fontWeight: 'bold' }}>{fm3.from(totalcredito())}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '60px', color: 'black', textAlign: 'right', fontWeight: 'bold' }}>{"Colgaap"}</Label>


                                    </div>
                                    <div >
                                        <Label style={{ display: 'inline-block', borderRadius: '10px', backgroundColor: '#188BAD', width: '90px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{"Diferencia:"}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '10px', color: 'black', textAlign: 'center', fontWeight: 'bold' }}>{fm3.from(totaldebito() - totalcredito())}</Label>
                                    </div>
                                    <hr></hr>
                                    <div >
                                        <Label style={{ display: 'inline-block', borderRadius: '10px', backgroundColor: '#188BAD', width: '90px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{"Debito:"}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '10px', color: 'black', textAlign: 'center', fontWeight: 'bold' }}>{fm3.from(totaldebitoniff())}</Label>

                                    </div>
                                    <div >
                                        <Label style={{ display: 'inline-block', borderRadius: '10px', backgroundColor: '#188BAD', width: '90px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{"Credito:"}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '10px', color: 'black', textAlign: 'center', fontWeight: 'bold' }}>{fm3.from(totalcreditoniff())}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '60px', color: 'black', textAlign: 'right', fontWeight: 'bold' }}>{"Niif"}</Label>
                                    </div>
                                    <div >
                                        <Label style={{ display: 'inline-block', borderRadius: '10px', backgroundColor: '#188BAD', width: '90px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{"Diferencia:"}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '10px', color: 'black', textAlign: 'center', fontWeight: 'bold' }}>{fm3.from(totaldebitoniff() - totalcreditoniff())}</Label>
                                    </div>
                                </Col>







                            </CardBody>

                        </Card>
                    </Col>
                </Row>
                {tipodocumento.modidoc == "carta 1" &&

                    <Modal size="lg" style={{ maxWidth: '1000px', width: '95%', fontFamily: 'Arial,monoespace' }} isOpen={previsualizar}>

                        <ModalBody id={"pruebapdfventaelectronica"} >
                            <Row >
                                <Col sm={12}>
                                    <Row>
                                        <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal' }}>
                                            {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                        </Col>
                                        <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                            {JSON.parse(user).empresa.empresa.nombre}<br />
                                            {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                            {"Direccion: " + JSON.parse(user).empresa.empresa.direccion}<br />
                                            {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                            {"Email: " + JSON.parse(user).empresa.empresa.email}<br />

                                        </Col>
                                        <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                            {tipodocumento.descripcion.toUpperCase() + " - " + movimiento.ndocumento}<br />
                                            {"Fecha: " + movimiento.fecha}<br />
                                            {"Fecha Venc.: " + movimiento.horafecha}<br />




                                        </Col>
                                        <Col sm={8} style={{ textAlign: 'left', fontWeight: 'normal', color: 'black', fontFamily: 'Kelpt A2 Medio, monospace' }}>
                                            {"Concepto: " + movimiento.descripcion.toUpperCase()}<br />

                                        </Col>

                                    </Row>
                                    <Row >
                                        <Col sm={12}>
                                            <Table size="sm" className='custom-table'>
                                                <thead>
                                                    <tr style={{ fontSize: '10px', color: 'black' }}>
                                                        <th style={{ width: '1%' }}>Cuenta</th>
                                                        <th style={{ width: '5%' }}>C.C.</th>
                                                        <th style={{ width: '15%' }}>DESCRIPCION</th>
                                                        <th style={{ width: '10%' }}>DOC/REF</th>
                                                        <th style={{ width: '10%' }}>DEBITO</th>
                                                        <th style={{ width: '10%' }}>CREDITO</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (movimiento.contmovidet.length < 1) ? (
                                                            <tr>
                                                                <td colSpan="15">Sin Datos</td>
                                                            </tr>
                                                        ) :
                                                            (
                                                                movimiento.contmovidet.map((item, index) => (
                                                                    <tr style={{ fontSize: '13px', color: 'black' }} key={item.item}>

                                                                        <td style={{ textAlign: 'left' }} >{item.codcuenta}</td>
                                                                        <td style={{ textAlign: 'left' }} >{item.codcentrocostos}</td>
                                                                        <td style={{ textAlign: 'left' }} >{item.comentarios.toUpperCase()}</td>
                                                                        <td style={{ textAlign: 'left' }}>{item.documentoref}</td>
                                                                        <td style={{ textAlign: 'left' }} >{Number(item.valordeb + "") == 0 ? "" : fm.from(Number(item.valordeb + ""))}</td>
                                                                        <td style={{ textAlign: 'left' }}>{Number(item.valorcre + "") == 0 ? "" : fm.from(Number(item.valorcre + ""))}</td>



                                                                    </tr>
                                                                ))
                                                            )


                                                    }
                                                </tbody>
                                            </Table>

                                        </Col>

                                    </Row>

                                    <Row>
                                        <Col sm={12} style={{ fontSize: '13px', color: 'black' }}>
                                            <Table size="sm">
                                                <thead>

                                                </thead>
                                                <tbody >
                                                    <tr  >
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Total Debito:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(totaldebito())}</th>
                                                    </tr>

                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Total Credito:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(totalcredito())}</th>
                                                    </tr>



                                                </tbody>
                                            </Table>


                                        </Col>

                                    </Row>
                                    <Row style={{ marginTop: '40px' }}>
                                        <Col sm={2} >

                                        </Col>
                                        <Col sm={2} >

                                        </Col>
                                        <Col sm={2} style={{ border: '1px solid black', width: '300px', color: 'white' }}>

                                        </Col>
                                        <Col sm={1} >

                                        </Col>
                                        <Col sm={2} style={{ border: '1px solid black' }}>

                                        </Col>
                                        <Col sm={3} >

                                        </Col>
                                    </Row >
                                    <Row >
                                        <Col sm={2} >

                                        </Col>
                                        <Col sm={2} >

                                        </Col>
                                        <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                            {JSON.parse(user).empresa.empresa.nombre}<br />
                                        </Col>
                                        <Col sm={1} >

                                        </Col>
                                        <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                            ACEPTADA, FIRMA Y SELLO
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>

                            {/* <Button size="sm" color="primary" onClick={() => {
            setprevisualizarenviocorreo(true)
            // setcorreosenvio(tercero.email)
        }}>Enviar Correo</Button> */}
                            <Button size="sm" color="primary" onClick={() => {

                                previsualizarpdfelectronica222()
                                // setprevisualizarelectronica(false)
                                setprevisualizar(false)

                            }}>PDF</Button>
                            <Button size="sm" color="primary" onClick={() => {
                                setprevisualizar(false)

                            }}>Cerrar</Button>
                        </ModalFooter>
                    </Modal>

                }
                {tipodocumento.modidoc == "carta 2" &&

                    <Modal size="lg" style={{ maxWidth: '1000px', width: '95%', fontFamily: 'Arial,monoespace' }} isOpen={previsualizar}>

                        <ModalBody id={"pruebapdfventaelectronica"} >
                            <Row >
                                <Col sm={12}>
                                    <Row>
                                        <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal' }}>
                                            {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                        </Col>
                                        <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                            {JSON.parse(user).empresa.empresa.nombre}<br />
                                            {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                            {"Direccion: " + JSON.parse(user).empresa.empresa.direccion}<br />
                                            {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                            {"Email: " + JSON.parse(user).empresa.empresa.email}<br />

                                        </Col>
                                        <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                            {tipodocumento.descripcion.toUpperCase() + " - " + movimiento.ndocumento}<br />
                                            {"Fecha: " + movimiento.fecha}<br />
                                            {"Fecha Venc.: " + movimiento.horafecha}<br />




                                        </Col>
                                        <Col sm={8} style={{ textAlign: 'left', fontWeight: 'normal', color: 'black', fontFamily: 'Kelpt A2 Medio, monospace' }}>
                                            {"Concepto: " + movimiento.descripcion.toUpperCase()}<br />

                                        </Col>

                                    </Row>
                                    <Row >
                                        <Col sm={12}>
                                            <Table size="sm" className='custom-table'>
                                                <thead>
                                                    <tr style={{ fontSize: '10px', color: 'black' }}>
                                                        <th style={{ width: '1%' }}>Cuenta</th>
                                                        <th style={{ width: '5%' }}>C.C.</th>
                                                        <th style={{ width: '15%' }}>DESCRIPCION</th>
                                                        <th style={{ width: '10%' }}>DOC/REF</th>
                                                        <th style={{ width: '10%' }}>DEBITO</th>
                                                        <th style={{ width: '10%' }}>CREDITO</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (movimiento.contmovidet.length < 1) ? (
                                                            <tr>
                                                                <td colSpan="15">Sin Datos</td>
                                                            </tr>
                                                        ) :
                                                            (
                                                                movimiento.contmovidet.map((item, index) => (
                                                                    <tr style={{ fontSize: '13px', color: 'black' }} key={item.item}>

                                                                        <td style={{ textAlign: 'left' }} >{item.codcuenta}</td>
                                                                        <td style={{ textAlign: 'left' }} >{item.codcentrocostos}</td>
                                                                        <td style={{ textAlign: 'left' }} >{item.comentarios.toUpperCase()}</td>
                                                                        <td style={{ textAlign: 'left' }}>{item.documentoref}</td>
                                                                        <td style={{ textAlign: 'left' }} >{Number(item.valordeb + "") == 0 ? "" : fm.from(Number(item.valordeb + ""))}</td>
                                                                        <td style={{ textAlign: 'left' }}>{Number(item.valorcre + "") == 0 ? "" : fm.from(Number(item.valorcre + ""))}</td>



                                                                    </tr>
                                                                ))
                                                            )


                                                    }
                                                </tbody>
                                            </Table>

                                        </Col>

                                    </Row>

                                    <Row>
                                        <Col sm={12} style={{ fontSize: '13px', color: 'black' }}>
                                            <Table size="sm">
                                                <thead>

                                                </thead>
                                                <tbody >
                                                    <tr  >
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Total Debito:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(totaldebito())}</th>
                                                    </tr>

                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Total Credito:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(totalcredito())}</th>
                                                    </tr>



                                                </tbody>
                                            </Table>


                                        </Col>

                                    </Row>
                                    <Row style={{ marginTop: '40px' }}>
                                        <Col sm={2} >

                                        </Col>
                                        <Col sm={2} >

                                        </Col>
                                        <Col sm={2} style={{ border: '1px solid black', width: '300px', color: 'white' }}>

                                        </Col>
                                        <Col sm={1} >

                                        </Col>
                                        <Col sm={2} style={{ border: '1px solid black' }}>

                                        </Col>
                                        <Col sm={3} >

                                        </Col>
                                    </Row >
                                    <Row >
                                        <Col sm={2} >

                                        </Col>
                                        <Col sm={2} >

                                        </Col>
                                        <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                            {JSON.parse(user).empresa.empresa.nombre}<br />
                                        </Col>
                                        <Col sm={1} >

                                        </Col>
                                        <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                            ACEPTADA, FIRMA Y SELLO
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>

                            {/* <Button size="sm" color="primary" onClick={() => {
setprevisualizarenviocorreo(true)
// setcorreosenvio(tercero.email)
}}>Enviar Correo</Button> */}
                            <Button size="sm" color="primary" onClick={() => {

                                previsualizarpdfelectronica222()
                                // setprevisualizarelectronica(false)
                                setprevisualizar(false)

                            }}>PDF</Button>
                            <Button size="sm" color="primary" onClick={() => {
                                setprevisualizar(false)

                            }}>Cerrar</Button>
                        </ModalFooter>
                    </Modal>

                }
                {tipodocumento.modidoc == "carta 3" &&

                    <Modal size="lg" style={{ maxWidth: '1000px', width: '95%', fontFamily: 'Arial,monoespace' }} isOpen={previsualizar}>

                        <ModalBody id={"pruebapdfventaelectronica"} >
                            <Row >
                                <Col sm={12}>
                                    <Row>
                                        <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal' }}>
                                            {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                        </Col>
                                        <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                            {JSON.parse(user).empresa.empresa.nombre}<br />
                                            {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                            {"Direccion: " + JSON.parse(user).empresa.empresa.direccion}<br />
                                            {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                            {"Email: " + JSON.parse(user).empresa.empresa.email}<br />

                                        </Col>
                                        <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                            {tipodocumento.descripcion.toUpperCase() + " - " + movimiento.ndocumento}<br />
                                            {"Fecha: " + movimiento.fecha}<br />
                                            {"Fecha Venc.: " + movimiento.horafecha}<br />




                                        </Col>
                                        <Col sm={8} style={{ textAlign: 'left', fontWeight: 'normal', color: 'black', fontFamily: 'Kelpt A2 Medio, monospace' }}>
                                            {"Concepto: " + movimiento.descripcion.toUpperCase()}<br />

                                        </Col>

                                    </Row>
                                    <Row >
                                        <Col sm={12}>
                                            <Table size="sm" className='custom-table'>
                                                <thead>
                                                    <tr style={{ fontSize: '10px', color: 'black' }}>
                                                        <th style={{ width: '1%' }}>Cuenta</th>
                                                        <th style={{ width: '5%' }}>C.C.</th>
                                                        <th style={{ width: '15%' }}>DESCRIPCION</th>
                                                        <th style={{ width: '10%' }}>DOC/REF</th>
                                                        <th style={{ width: '10%' }}>DEBITO</th>
                                                        <th style={{ width: '10%' }}>CREDITO</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (movimiento.contmovidet.length < 1) ? (
                                                            <tr>
                                                                <td colSpan="15">Sin Datos</td>
                                                            </tr>
                                                        ) :
                                                            (
                                                                movimiento.contmovidet.map((item, index) => (
                                                                    <tr style={{ fontSize: '13px', color: 'black' }} key={item.item}>

                                                                        <td style={{ textAlign: 'left' }} >{item.codcuenta}</td>
                                                                        <td style={{ textAlign: 'left' }} >{item.codcentrocostos}</td>
                                                                        <td style={{ textAlign: 'left' }} >{item.comentarios.toUpperCase()}</td>
                                                                        <td style={{ textAlign: 'left' }}>{item.documentoref}</td>
                                                                        <td style={{ textAlign: 'left' }} >{Number(item.valordeb + "") == 0 ? "" : fm.from(Number(item.valordeb + ""))}</td>
                                                                        <td style={{ textAlign: 'left' }}>{Number(item.valorcre + "") == 0 ? "" : fm.from(Number(item.valorcre + ""))}</td>



                                                                    </tr>
                                                                ))
                                                            )


                                                    }
                                                </tbody>
                                            </Table>

                                        </Col>

                                    </Row>

                                    <Row>
                                        <Col sm={12} style={{ fontSize: '13px', color: 'black' }}>
                                            <Table size="sm">
                                                <thead>

                                                </thead>
                                                <tbody >
                                                    <tr  >
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Total Debito:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(totaldebitoniff())}</th>
                                                    </tr>

                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Total Credito:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(totalcreditoniff())}</th>
                                                    </tr>



                                                </tbody>
                                            </Table>


                                        </Col>

                                    </Row>
                                    <Row style={{ marginTop: '40px' }}>
                                        <Col sm={2} >

                                        </Col>
                                        <Col sm={2} >

                                        </Col>
                                        <Col sm={2} style={{ border: '1px solid black', width: '300px', color: 'white' }}>

                                        </Col>
                                        <Col sm={1} >

                                        </Col>
                                        <Col sm={2} style={{ border: '1px solid black' }}>

                                        </Col>
                                        <Col sm={3} >

                                        </Col>
                                    </Row >
                                    <Row >
                                        <Col sm={2} >

                                        </Col>
                                        <Col sm={2} >

                                        </Col>
                                        <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                            {JSON.parse(user).empresa.empresa.nombre}<br />
                                        </Col>
                                        <Col sm={1} >

                                        </Col>
                                        <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                            ACEPTADA, FIRMA Y SELLO
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>

                            {/* <Button size="sm" color="primary" onClick={() => {
setprevisualizarenviocorreo(true)
// setcorreosenvio(tercero.email)
}}>Enviar Correo</Button> */}
                            <Button size="sm" color="primary" onClick={() => {

                                previsualizarpdfelectronica222()
                                // setprevisualizarelectronica(false)
                                setprevisualizar(false)

                            }}>PDF</Button>
                            <Button size="sm" color="primary" onClick={() => {
                                setprevisualizar(false)

                            }}>Cerrar</Button>
                        </ModalFooter>
                    </Modal>

                }
                {(tipodocumento.modidoc != "carta 3" && tipodocumento.modidoc != "carta 2" && tipodocumento.modidoc != "carta 1")
                    &&

                    <Modal size="lg" style={{ maxWidth: '1000px', width: '95%', fontFamily: 'Arial,monoespace' }} isOpen={previsualizar}>

                        <ModalBody id={"pruebapdfventaelectronica"} >
                            <Row >
                                <Col sm={12}>
                                    <Row>
                                        <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal' }}>
                                            {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                        </Col>
                                        <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                            {JSON.parse(user).empresa.empresa.nombre}<br />
                                            {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                            {"Direccion: " + JSON.parse(user).empresa.empresa.direccion}<br />
                                            {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                            {"Email: " + JSON.parse(user).empresa.empresa.email}<br />

                                        </Col>
                                        <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                            {tipodocumento.descripcion.toUpperCase() + " - " + movimiento.ndocumento}<br />
                                            {"Fecha: " + movimiento.fecha}<br />
                                            {"Fecha Venc.: " + movimiento.horafecha}<br />




                                        </Col>
                                        <Col sm={8} style={{ textAlign: 'left', fontWeight: 'normal', color: 'black', fontFamily: 'Kelpt A2 Medio, monospace' }}>
                                            {"Concepto: " + movimiento.descripcion.toUpperCase()}<br />

                                        </Col>

                                    </Row>
                                    <Row >
                                        <Col sm={12}>
                                            <Table size="sm" className='custom-table'>
                                                <thead>
                                                    <tr style={{ fontSize: '10px', color: 'black' }}>
                                                        <th style={{ width: '1%' }}>Cuenta</th>
                                                        <th style={{ width: '5%' }}>C.C.</th>
                                                        <th style={{ width: '15%' }}>DESCRIPCION</th>
                                                        <th style={{ width: '10%' }}>DOC/REF</th>
                                                        <th style={{ width: '10%' }}>DEBITO</th>
                                                        <th style={{ width: '10%' }}>CREDITO</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (movimiento.contmovidet.length < 1) ? (
                                                            <tr>
                                                                <td colSpan="15">Sin Datos</td>
                                                            </tr>
                                                        ) :
                                                            (
                                                                movimiento.contmovidet.map((item, index) => (
                                                                    <tr style={{ fontSize: '13px', color: 'black' }} key={item.item}>

                                                                        <td style={{ textAlign: 'left' }} >{item.codcuenta}</td>
                                                                        <td style={{ textAlign: 'left' }} >{item.codcentrocostos}</td>
                                                                        <td style={{ textAlign: 'left' }} >{item.comentarios.toUpperCase()}</td>
                                                                        <td style={{ textAlign: 'left' }}>{item.documentoref}</td>
                                                                        <td style={{ textAlign: 'left' }} >{Number(item.valordeb + "") == 0 ? "" : fm.from(Number(item.valordeb + ""))}</td>
                                                                        <td style={{ textAlign: 'left' }}>{Number(item.valorcre + "") == 0 ? "" : fm.from(Number(item.valorcre + ""))}</td>



                                                                    </tr>
                                                                ))
                                                            )


                                                    }
                                                </tbody>
                                            </Table>

                                        </Col>

                                    </Row>

                                    <Row>
                                        <Col sm={12} style={{ fontSize: '13px', color: 'black' }}>
                                            <Table size="sm">
                                                <thead>

                                                </thead>
                                                <tbody >
                                                    <tr  >
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Total Debito:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(totaldebito())}</th>
                                                    </tr>

                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Total Credito:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(totalcredito())}</th>
                                                    </tr>



                                                </tbody>
                                            </Table>


                                        </Col>

                                    </Row>
                                    <Row style={{ marginTop: '40px' }}>
                                        <Col sm={2} >

                                        </Col>
                                        <Col sm={2} >

                                        </Col>
                                        <Col sm={2} style={{ border: '1px solid black', width: '300px', color: 'white' }}>

                                        </Col>
                                        <Col sm={1} >

                                        </Col>
                                        <Col sm={2} style={{ border: '1px solid black' }}>

                                        </Col>
                                        <Col sm={3} >

                                        </Col>
                                    </Row >
                                    <Row >
                                        <Col sm={2} >

                                        </Col>
                                        <Col sm={2} >

                                        </Col>
                                        <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                            {JSON.parse(user).empresa.empresa.nombre}<br />
                                        </Col>
                                        <Col sm={1} >

                                        </Col>
                                        <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                            ACEPTADA, FIRMA Y SELLO
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>

                            {/* <Button size="sm" color="primary" onClick={() => {
        setprevisualizarenviocorreo(true)
        // setcorreosenvio(tercero.email)
    }}>Enviar Correo</Button> */}
                            <Button size="sm" color="primary" onClick={() => {

                                previsualizarpdfelectronica222()
                                // setprevisualizarelectronica(false)
                                setprevisualizar(false)

                            }}>PDF</Button>
                            <Button size="sm" color="primary" onClick={() => {
                                setprevisualizar(false)

                            }}>Cerrar</Button>
                        </ModalFooter>
                    </Modal>


                }
            </Col >

            <Buscar tituloencabezado={"Buscar Tipo Documento"} filtrar={(coincidencia) => { filtrartipodocumentos(coincidencia) }} busqueda={busquedatipodocumentos} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"descripcion"} dato={""} dato2={""} verModal={verModaltipodocumentos} cerrarModal={() => { cerrarModaltipodocumentos() }} seleccionar={(seleccionado) => {
                seleccionartipodocumentos(seleccionado)
            }} />
            <BuscarMovimiento tituloencabezado={"Buscar Movimientos"} filtrar={(coincidencia) => { filtrarMovimientos(coincidencia) }} busqueda={busquedaMovimientos} encabezado={["Concepto", "Consecutivo", "Año", "Periodo"]} sub={"contmovienc"} codigo={"tipodoc"} nombre={"ndocumento"} dato={"ano"} dato2={"periodo"} verModal={verModalMovimientos} cerrarModal={() => { cerrarModalMovimientos() }} seleccionar={(seleccionado) => {
                seleccionarMovimientos(seleccionado)
            }} />
            <BuscarCuentas tituloencabezado={"Buscar Cuentas"} abrir={abriricuentas} servidor={props.servidor} setabrirbanco={(ab) => { setabriricuentas(ab) }} seleccionar={(value) => {
                handleChangegrillafaencmovi('', value, 'codcuenta', 'codcuenta')
                setabriricuentas(false)
            }} />
            <BuscarCentrodeCosto tituloencabezado={"Buscar Centro de Costo"} abrir={abriricentrodecosto} servidor={props.servidor} setabrirbanco={(ab) => { setabriricentrodecosto(ab) }} seleccionar={(value) => {
                handleChangegrillafaencmovi('', value, 'codcentrocostos', 'codigo')
                setabriricentrodecosto(false)
            }} />
            <BuscarTerceros tituloencabezado={"Buscar Cuentas"} abrir={abrirterceros} servidor={props.servidor} setabrirbanco={(ab) => { setabrirterceros(ab) }} seleccionar={(value) => {
                handleChangegrillafaencmovi('', value, 'acreedor', 'codigo')
                setabrirterceros(false)
                buscarterceronombre(value.codigo)
            }} />
        </Row >
    )
}

export default Venta;