import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap";
import Swal from 'sweetalert2';
import Tabs from 'react-bootstrap/Tabs';
import { UserContext } from '../context/UserProvider';
import Tab from 'react-bootstrap/Tab';

const modeloBodega = {
    codigo: "electronica 1",
    nombrereal: true,
    nit: true,
    resolucion: false,
    numero: true,
    horafecha: true,
    fecha: false,
    telefono: true,
    divisionencabezado1: true,
    nombrecliente: true,
    direccioncliente: true,
    nitcliente: true,
    divisionencabezado2: true,
    usuariohizofactura: true,
    usuarioactual: false,
    codigovendedor: false,
    nombrevendedor: true,
    margenentreencabezadoeitems: "-15px",
    tamanoletraencabezadogrilla: "10px",
    colorletra: "black",
    espaciadoentreitems: "0.8",
    cantidad: true,
    codigoarticulo: false,
    descripcionarticulo: true,
    ivaarticulo: false,
    porimptoconsumoarticulo: false,
    vlrimptoconsumoarticulo: false,
    valorunitarioarticulo: true,
    valortotalarticulo: true,
    tamanoletratotales: "10px",
    espaciadoentretotales: "0.5",
    totaldescuento: false,
    totaliva: false,
    totalconsumo: false,
    totalretefuente: false,
    totalreteiva: false,
    totalreteica: false,
    mostrarformasdepago: false,
    firmar: false,
    mostrarqr: false,
    mostrarcufe: false,
    tamanoletraformapago: "10px",
    tamanoletraformapagotitulo: "10px",
    tamanoletraencabezado: "10px",
    tamanoletraitemsgrilla: "10px",
    mostrarlogo: true,
    ivadiscriminado: false,
    ponerlineas: false,
    acercartextototales: false,
};

const Bodega = (props) => {
    const { user } = useContext(UserContext);
    const [Bodega, setBodega] = useState(modeloBodega);
    const [Bodegas, setBodegas] = useState([]);
    const [verModalBodega, setVerModalBodega] = useState(false);
    const [editar, setEditar] = useState(false);
    const [key, setKey] = useState('Encabezado');
    const [pendiente, setPendiente] = useState(false);

    const handleChange = (e) => {
        let value = e.target.value;
        var value2 = e.target.value;
        if (value == 'true') {
            value2 = true
        }
        if (value == 'false') {
            value2 = false
        }
        var _Bodega = { ...Bodega };
        _Bodega[e.target.name] = value2;
        setBodega(_Bodega);
    };

    const obtenerBodegas = async (coincidencia) => {
        try {
            let dt = JSON.parse(user);
            let response = await fetch(props.servidor + '/PlantillapdfApi?llave=' + dt.usuario.llaveempresa);
            if (response.ok) {
                let data = await response.json();
                setBodegas(data);
                setPendiente(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        let dt = JSON.parse(user);
        obtenerBodegas('');
    }, [user]);

    const columns = [
        {
            name: 'Código',
            selector: row => row.codigo,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <Button color="primary" size="sm" onClick={() => abrirEditarModalBodega(row)}>
                    <i className="fas fa-pen-alt"></i>
                </Button>
            ),
        }
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const abrirEditarModalBodega = (data) => {
        setBodega(data);
        setVerModalBodega(true);
        setEditar(true);
    };

    const cerrarModalBodega = () => {
        setBodega(modeloBodega);
        setVerModalBodega(false);
        setEditar(false);
        setKey('Encabezado');
    };

    const guardarCambios = async () => {
        let response;
        let url = `${props.servidor}/PlantillapdfApi?llave=${JSON.parse(user).usuario.llaveempresa}`;
        let method = editar ? 'PUT' : 'POST';

        response = await fetch(url, {
            method: method,
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify(Bodega)
        });

        if (response.ok) {
            await obtenerBodegas('');
            cerrarModalBodega();
            Swal.fire('Guardado Exitosamente', 'La plantilla fue guardada con éxito', 'success');
        } else {

            Swal.fire('Error al Guardar', "errores", 'error');
        }
    };

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Plantilla PDF
                </CardHeader>
                <CardBody>
                    <div className="input-group input-group-sm mb-3">
                        <Input id="buscardataBodegas" placeholder='Buscar' />
                        <Button color="primary" className="ml-2" onClick={() => obtenerBodegas(document.getElementById('buscardataBodegas').value)}>Buscar</Button>
                        <Button color="success" className="ml-2" onClick={() => setVerModalBodega(true)}>Nuevo</Button>
                    </div>
                    <DataTable
                        columns={columns}
                        data={Bodegas}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>

            <Modal size="lg" isOpen={verModalBodega}>
                <ModalHeader>Plantilla PDF</ModalHeader>
                <ModalBody>
                    <Tabs activeKey={key} onSelect={(k) => setKey(k)}>

                        <Tab eventKey="Encabezado" title="Encabezado">
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Codigo</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="codigo" value={Bodega.codigo}>
                                            <option value="electronica 1">Electronica 1</option>
                                            <option value="electronica 2">Electronica 2</option>
                                            <option value="electronica 3">Electronica 3</option>
                                            <option value="electronica 4">Electronica 4</option>
                                            <option value="electronica 5">Electronica 5</option>
                                            <option value="electronica 5 responsable de iva">Electronica 5 responsable de iva</option>

                                            <option value="electronica 6">Electronica 6</option>
                                            <option value="electronica 7">Electronica 7</option>
                                            <option value="electronica 8">Electronica 8</option>

                                            <option value="tirilla 1">Tirilla 1</option>
                                            <option value="tirilla 2">Tirilla 2</option>
                                            <option value="tirilla 4">Tirilla 4</option>
                                            {/* tirilla 4 es mas angosta */}
                                            <option value="tirilla 5">Tirilla 5</option>
                                            <option value="tirilla 6">Tirilla 6</option>
                                            <option value="tirilla 7">Tirilla 7</option>
                                            <option value="tirilla 8">Tirilla 8</option>
                                            <option value="tirilla 9">Tirilla 9</option>
                                            <option value="tirilla 10">Tirilla 10</option>
                                            <option value="tirilla 11">Tirilla 11</option>
                                            <option value="tirilla 12">Tirilla 12</option> {/* tirilla 1 sin resolucion */}
                                            <option value="tirilla 13">Tirilla 13</option> {/* tirilla 11 con descriopcion adicional */}
                                            <option value="tirilla 14">Tirilla 14</option> {/* tirilla 11 con qr */}
                                            <option value="tirilla 15">Tirilla 15</option> {/* tirilla 11 con qr */}
                                            <option value="tirilla 16">Tirilla 16</option> {/* tirilla 11 con qr */}
                                            <option value="tirilla 17">Tirilla 17</option> {/* tirilla 11 con qr */}
                                            <option value="tirilla 18">Tirilla 18</option> {/* tirilla 11 con qr */}
                                            <option value="tirilla 19">Tirilla 19</option> {/* tirilla 11 con qr */}
                                            <option value="tirilla 1 Electronica">Tirilla 1 Electronica</option>
                                            <option value="tirilla transporte 1">Tirilla transporte 1</option> {/* tirilla 11 con qr */}
                                            <option value="tirilla Electronica transporte 1">Tirilla Electronica transporte 1</option> {/* tirilla 11 con qr */}
                                            <option value="carta 1">Carta 1</option>
                                            <option value="carta 2">Carta 2</option>
                                            <option value="carta 3">Carta 3</option>
                                            <option value="carta 3 lote">Carta 3 lote</option>

                                            <option value="carta 4">Carta 4</option>
                                            <option value="carta 5">Carta 5</option>
                                            <option value="carta 6">Carta 6</option>
                                            <option value="carta 7">Carta 7</option>
                                            <option value="carta 8">Carta 8</option>
                                            <option value="carta 9">Carta 9</option> {/* carta 5 pero mas grande */}

                                            <option value="comanda 1">Comanda 1</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Nombre</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="nombrereal" value={Bodega.nombrereal}>
                                            <option value={false}>No</option>
                                            <option value={true}>Sí</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>NIT</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="nit" value={Bodega.nit}>
                                            <option value={false}>No</option>
                                            <option value={true}>Sí</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Resolución</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="resolucion" value={Bodega.resolucion}>
                                            <option value={false}>No</option>
                                            <option value={true}>Sí</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Número</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="numero" value={Bodega.numero}>
                                            <option value={false}>No</option>
                                            <option value={true}>Sí</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Hora/Fecha</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="horafecha" value={Bodega.horafecha}>
                                            <option value={false}>No</option>
                                            <option value={true}>Sí</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Teléfono</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="telefono" value={Bodega.telefono}>
                                            <option value={false}>No</option>
                                            <option value={true}>Sí</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>División Encabezado 1</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="divisionencabezado1" value={Bodega.divisionencabezado1}>
                                            <option value={false}>No</option>
                                            <option value={true}>Sí</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>División Encabezado 2</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="divisionencabezado2" value={Bodega.divisionencabezado2}>
                                            <option value={false}>No</option>
                                            <option value={true}>Sí</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Logo</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="mostrarlogo" value={Bodega.mostrarlogo}>
                                            <option value={false}>No</option>
                                            <option value={true}>Sí</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Poner Lineas</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="ponerlineas" value={Bodega.ponerlineas}>
                                            <option value={false}>No</option>
                                            <option value={true}>Sí</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Acercar Texto Totales</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="acercartextototales" value={Bodega.acercartextototales}>
                                            <option value={false}>No</option>
                                            <option value={true}>Sí</option>
                                        </Input>
                                    </FormGroup>
                                </Col>

                            </Row>
                        </Tab>

                        <Tab eventKey="Cliente" title="Cliente">
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Nombre Cliente</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="nombrecliente" value={Bodega.nombrecliente}>
                                            <option value={false}>No</option>
                                            <option value={true}>Sí</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Dirección Cliente</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="direccioncliente" value={Bodega.direccioncliente}>
                                            <option value={false}>No</option>
                                            <option value={true}>Sí</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>NIT Cliente</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="nitcliente" value={Bodega.nitcliente}>
                                            <option value={false}>No</option>
                                            <option value={true}>Sí</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="Usuario y Vendedor" title="Usuario y Vendedor">
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Usuario que Hizo Factura</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="usuariohizofactura" value={Bodega.usuariohizofactura}>
                                            <option value={false}>No</option>
                                            <option value={true}>Sí</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Usuario Actual</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="usuarioactual" value={Bodega.usuarioactual}>
                                            <option value={false}>No</option>
                                            <option value={true}>Sí</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Código Vendedor</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="codigovendedor" value={Bodega.codigovendedor}>
                                            <option value={false}>No</option>
                                            <option value={true}>Sí</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Nombre Vendedor</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="nombrevendedor" value={Bodega.nombrevendedor}>
                                            <option value={false}>No</option>
                                            <option value={true}>Sí</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Tab>
                        <Tab eventKey="Articulos" title="Articulos">


                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Margen Entre Encabezado e Items</Label>
                                        <Input type="text" onChange={handleChange} style={{ fontSize: '13px' }} name="margenentreencabezadoeitems" value={Bodega.margenentreencabezadoeitems} />


                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Tamaño Letra Encabezado Grilla</Label>
                                        <Input type="text" onChange={handleChange} style={{ fontSize: '13px' }} name="tamanoletraencabezadogrilla" value={Bodega.tamanoletraencabezadogrilla} />

                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Color Letra</Label>
                                        <Input type="text" onChange={handleChange} style={{ fontSize: '13px' }} name="colorletra" value={Bodega.colorletra} />

                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Espaciado Entre Items</Label>
                                        <Input type="text" onChange={handleChange} style={{ fontSize: '13px' }} name="espaciadoentreitems" value={Bodega.espaciadoentreitems} />

                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Cantidad</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="cantidad" value={Bodega.cantidad}>
                                            <option value={false}>No</option>
                                            <option value={true}>Sí</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Código Artículo</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="codigoarticulo" value={Bodega.codigoarticulo}>
                                            <option value={false}>No</option>
                                            <option value={true}>Sí</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Descripción Artículo</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="descripcionarticulo" value={Bodega.descripcionarticulo}>
                                            <option value={false}>No</option>
                                            <option value={true}>Sí</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>IVA Artículo</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="ivaarticulo" value={Bodega.ivaarticulo}>
                                            <option value={false}>No</option>
                                            <option value={true}>Sí</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Porcentaje Impuesto Consumo</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="porimptoconsumoarticulo" value={Bodega.porimptoconsumoarticulo}>
                                            <option value={false}>No</option>
                                            <option value={true}>Sí</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Valor Impuesto Consumo</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="vlrimptoconsumoarticulo" value={Bodega.vlrimptoconsumoarticulo}>
                                            <option value={false}>No</option>
                                            <option value={true}>Sí</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Valor Unitario Artículo</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="valorunitarioarticulo" value={Bodega.valorunitarioarticulo}>
                                            <option value={false}>No</option>
                                            <option value={true}>Sí</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Valor Total Artículo</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="valortotalarticulo" value={Bodega.valortotalarticulo}>
                                            <option value={false}>No</option>
                                            <option value={true}>Sí</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="Pie de Pagina" title="Pie de Pagina">
                            <Row>



                                <Row>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label style={{ fontSize: '13px' }}>Tamaño Letra Totales</Label>
                                            <Input type="text" onChange={handleChange} style={{ fontSize: '13px' }} name="tamanoletratotales" value={Bodega.tamanoletratotales} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label style={{ fontSize: '13px' }}>Espaciado Entre Totales</Label>
                                            <Input type="text" onChange={handleChange} style={{ fontSize: '13px' }} name="espaciadoentretotales" value={Bodega.espaciadoentretotales} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label style={{ fontSize: '13px' }}>Total Descuento</Label>
                                            <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="totaldescuento" value={Bodega.totaldescuento}>
                                                <option value={false}>No</option>
                                                <option value={true}>Sí</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label style={{ fontSize: '13px' }}>Total IVA</Label>
                                            <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="totaliva" value={Bodega.totaliva}>
                                                <option value={false}>No</option>
                                                <option value={true}>Sí</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label style={{ fontSize: '13px' }}>Total Iva Discriminado</Label>
                                            <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="ivadiscriminado" value={Bodega.ivadiscriminado}>
                                                <option value={false}>No</option>
                                                <option value={true}>Sí</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label style={{ fontSize: '13px' }}>Total Consumo</Label>
                                            <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="totalconsumo" value={Bodega.totalconsumo}>
                                                <option value={false}>No</option>
                                                <option value={true}>Sí</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label style={{ fontSize: '13px' }}>Total Retefuente</Label>
                                            <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="totalretefuente" value={Bodega.totalretefuente}>
                                                <option value={false}>No</option>
                                                <option value={true}>Sí</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label style={{ fontSize: '13px' }}>Total Reteiva</Label>
                                            <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="totalreteiva" value={Bodega.totalreteiva}>
                                                <option value={false}>No</option>
                                                <option value={true}>Sí</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label style={{ fontSize: '13px' }}>Total Reteica</Label>
                                            <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="totalreteica" value={Bodega.totalreteica}>
                                                <option value={false}>No</option>
                                                <option value={true}>Sí</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label style={{ fontSize: '13px' }}>Mostrar Medios de Pago</Label>
                                            <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="mostrarformasdepago" value={Bodega.mostrarformasdepago}>
                                                <option value={false}>No</option>
                                                <option value={true}>Sí</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label style={{ fontSize: '13px' }}>Firmar</Label>
                                            <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="firmar" value={Bodega.firmar}>
                                                <option value={false}>No</option>
                                                <option value={true}>Sí</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label style={{ fontSize: '13px' }}>Mostrar QR</Label>
                                            <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="mostrarqr" value={Bodega.mostrarqr}>
                                                <option value={false}>No</option>
                                                <option value={true}>Sí</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label style={{ fontSize: '13px' }}>Mostrar CUFE</Label>
                                            <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="mostrarcufe" value={Bodega.mostrarcufe}>
                                                <option value={false}>No</option>
                                                <option value={true}>Sí</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label style={{ fontSize: '13px' }}>Tamaño Letra Forma de Pago</Label>
                                            <Input type="text" onChange={handleChange} style={{ fontSize: '13px' }} name="tamanoletraformapago" value={Bodega.tamanoletraformapago} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label style={{ fontSize: '13px' }}>Tamaño Letra Título Forma de Pago</Label>
                                            <Input type="text" onChange={handleChange} style={{ fontSize: '13px' }} name="tamanoletraformapagotitulo" value={Bodega.tamanoletraformapagotitulo} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label style={{ fontSize: '13px' }}>Tamaño Letra Encabezado</Label>
                                            <Input type="text" onChange={handleChange} style={{ fontSize: '13px' }} name="tamanoletraencabezado" value={Bodega.tamanoletraencabezado} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label style={{ fontSize: '13px' }}>Tamaño Letra Items Grilla</Label>
                                            <Input type="text" onChange={handleChange} style={{ fontSize: '13px' }} name="tamanoletraitemsgrilla" value={Bodega.tamanoletraitemsgrilla} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Row>

                        </Tab>

                    </Tabs>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button color="danger" onClick={cerrarModalBodega}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default Bodega;
