import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState, useContext } from "react";
import { UserContext } from '../../../context/UserProvider';
import * as XLSX from "xlsx"
import { FormatMoney } from 'format-money-js'
import Filtro from '../../../componentes/filtro';
import Movimientos from '../../Venta'

const tabespacio = '\u00A0';

const modeloInicio = [{
    referencia: "",
    descripcion: "",
    ivaporc: "",
    costopromedio: "",
    costoultimo: ""

}]

const ReporteVenta = (props) => {
    const [fechaInicio, setFechaInicio] = useState("2000" + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechaFin, setFechaFin] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechahoy, setfechahoy] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));

    const [pendiente, setPendiente] = useState(false);
    const [ventas, setVentas] = useState(modeloInicio)
    const { user, cerrarSession } = useContext(UserContext);
    const [fm, setfm] = useState(new FormatMoney({ symbol: '$', decimals: 2 }));
    const [fm2, setfm2] = useState(new FormatMoney({ decimals: 0 }));
    const [tipodocumentoinicioseleccionado, settipodocumentoinicioseleccionado] = useState("-");
    const [terceroinicioseleccionado, setterceroinicioseleccionado] = useState("-");
    const [tipodocumentofinseleccionado, settipodocumentofinseleccionado] = useState("-");
    const [tercerofinseleccionado, settercerofinseleccionado] = useState("-");
    const [filtros, setfiltros] = useState(false);
    const [fm3, setfm3] = useState(new FormatMoney({ symbol: '$ ', decimals: 2 }));
    const [tipotercero, settipotercero] = useState("-");
    const [consaldocero, setconsaldocero] = useState("no");
    const [editarorden, seteditarorden] = useState(false);
    const [movimientoenvio, setmovimientoenvio] = useState(false);

    useEffect(() => {
        console.log(new Date())
        console.log(new Date('20230-01-01'))
        console.log((new Date() - new Date('20230-01-01')) / 86400000)
    }, [])
    const buscar = () => {

        setPendiente(true)
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''
        const api = fetch(props.servidor + '/CtabonoApi/movimientoscompletos/' + fechaInicio + '/' + fechaFin + '/' + tipodocumentoinicioseleccionado + '/' + tipodocumentofinseleccionado + '/' + terceroinicioseleccionado + "/" + tercerofinseleccionado + "/" + tipotercero + '?llave=' + dt.usuario.llaveempresa + '')
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                setPendiente(false)
                if (data.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                if (consaldocero == "no") {
                    var dat2 = data.filter(p => (p.vlrfactur + p.debito - p.credito) > 0)
                    setVentas(dat2);

                } else {
                    setVentas(data);

                }
            }).catch((error) => {
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }

    const buscarpdf = () => {

        setPendiente(true)
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''
        var fdff = props.servidor + '/CtabonoApi/estadodecuentapdf/' + fechaInicio + '/' + fechaFin + '/' + tipodocumentoinicioseleccionado + '/' + tipodocumentofinseleccionado + '/' + terceroinicioseleccionado + "/" + tercerofinseleccionado + "/" + tipotercero + "/" + dt.empresa.empresa.nit + '?llave=' + dt.usuario.llaveempresa + ''
        const api = fetch(props.servidor + '/CtabonoApi/movimientoscompletospdf/' + fechaInicio + '/' + fechaFin + '/' + tipodocumentoinicioseleccionado + '/' + tipodocumentofinseleccionado + '/' + terceroinicioseleccionado + "/" + tercerofinseleccionado + "/" + tipotercero + "/" + dt.empresa.empresa.nit + '?llave=' + dt.usuario.llaveempresa + '')
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                const bytes = atob(data.pdf);

                // Crear un arreglo de bytes a partir de la cadena decodificada
                const byteArray = new Uint8Array(bytes.length);
                for (let i = 0; i < bytes.length; i++) {
                    byteArray[i] = bytes.charCodeAt(i);
                }

                // Crear un Blob a partir del arreglo de bytes
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                // Crear una URL para el Blob
                const url = window.URL.createObjectURL(blob);
                // Crear un enlace para descargar el archivo PDF
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'estado_de_cuenta.pdf');
                // Simular clic en el enlace para iniciar la descarga
                document.body.appendChild(link);
                link.click();
                // Liberar el objeto URL creado
                window.URL.revokeObjectURL(url);
                setPendiente(false)
                if (data.datos.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                // if (consaldocero == "si") {
                //     setVentas(data.datos);

                // } else {
                //     var datasincero = buscarsaldosencero(data.datos)
                //     var datasincerofacturas = buscarsaldosencerofacturas(datasincero)

                //     setVentas(datasincerofacturas);

                // }
                console.log(data.datos)
            }).catch(async (error) => {
                console.log(error)
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }
    const totaltotalsaldo = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            tot = tot + (element.vlrfactur + element.debito - element.credito)
        }
        return tot
    }
    const totaltotaldb = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            tot = tot + (element.debito)
        }
        return tot
    }
    const totaltotalcr = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            tot = tot + (element.credito)
        }
        return tot
    }
    const totaltotaldbcr = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            tot = tot + Math.abs((element.debito - element.credito))
        }
        return tot
    }
    const totaltotal = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            tot = tot + (element.vlrfactur)
        }
        return tot
    }
    const totaltotalbrcrvivo = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            var saldo = (element.vlrfactur + element.debito - element.credito)
            if (saldo != 0) {
                tot = tot + Math.abs((element.debito - element.credito))
            }
        }
        return tot
    }

    const CustomOrden = ({ row }) => (
        <div>
            { }
            <div>
                <div style={{ color: 'blue' }} onClick={() => {
                    try {

                        let dt = JSON.parse(user)
                        var url = props.servidor + '/Movimientoapi/Buscarbuscarunico/' + row.numero + '?llave=' + dt.usuario.llaveempresa + ''
                        const api = fetch(props.servidor + '/Movimientoapi/buscarunico/' + row.tipodoc + '/' + row.doc + '?llave=' + dt.usuario.llaveempresa + '')
                            .then((response) => {
                                return response.ok ? response.json() : Promise.reject(response);
                            })
                            .then((dataJson) => {
                                setmovimientoenvio(dataJson)
                                seteditarorden(true)

                            }).catch((error) => {
                                Swal.fire(
                                    'Opps!',
                                    'No se encontro el movimiento',
                                    'error'
                                )
                            })
                    } catch (error) {
                        console.log(error)
                    }
                }}
                >
                    {row.doc}
                </div>
            </div>
        </div>
    );
    const columns = [
        {
            name: 'Tercero',
            selector: row => row.nombrecli,
            sortable: true,
            grow: 4,
        },
        {
            name: 'Concepto',
            selector: row => row.tipodoc,
            sortable: true,
            grow: 3,
            cell: row => <div>
                { }
                <div>
                    <div
                    >
                        { }
                        {row.tipodoc}
                    </div>
                </div>
            </div>,
        },
        {
            name: 'Consecutivo',
            selector: row => row.doc,
            sortable: true,
            cell: row => <CustomOrden row={row} />,
        },
        {
            name: 'Fecha',
            selector: row => row.fecha,
            sortable: true,
            grow: 4,
        },
        {
            name: 'Vencimiento',
            selector: row => row.fechavenc,
            sortable: true,
            grow: 4,
        },
        {
            name: 'Dias Vencida',
            selector: row => ((new Date(fechahoy) - new Date(row.fechavenc)) / 86400000),
            sortable: true,
        },
        {
            name: 'Valor Factura',
            selector: row => fm2.from(row.vlrfactur),
            sortable: true,
            grow: 4,
        },
        {
            name: 'Saldo',
            selector: row => fm2.from((row.vlrfactur + row.debito - row.credito)),
            sortable: true,
            grow: 4,
        },
        {
            name: 'DB-CR',
            selector: row => fm2.from(Math.abs((row.debito - row.credito))),
            sortable: true,
            grow: 4,
        },
        {
            name: 'Creditos',
            selector: row => fm2.from((row.credito)),
            sortable: true,
            grow: 4,
        },
        {
            name: 'Debitos',
            selector: row => fm2.from((row.debito)),
            sortable: true,
            grow: 4,
        }
    ];
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const exportarExcel = () => {
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(ventas);

        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, "Reporte Catalogo Productos.xlsx")
    }

    return (
        <>
            <Row>
                <Col sm={12}>
                    <Card>
                        <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                            Analisis de Cartera
                        </CardHeader>
                        <CardBody>
                            <Row className="align-items-end">

                                {/* <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Fecha Inicio:</Label>
                                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                                            selected={fechaInicio}
                                            value={fechaInicio}
                                            onChange={(e) => setFechaInicio(e.target.value)}></Input>

                                    </FormGroup>
                                </Col> */}

                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Fecha Fin:</Label>
                                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                                            selected={fechaFin}
                                            value={fechaFin}
                                            onChange={(e) => setFechaFin(e.target.value)}></Input>


                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                                        <Button style={{ backgroundColor: 'darkmagenta' }} size="sm" block onClick={() => {
                                            setfiltros(true)
                                        }}>
                                            <i className="fa fa-search" aria-hidden="true"></i> Filtros
                                        </Button>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Saldos en Cero</Label>
                                        <Input value={consaldocero} onChange={(e) => { setconsaldocero(e.target.value) }} type="select" style={{ fontSize: '13px' }} name="coniva"  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>

                                        </Input>


                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Button color="primary" size="sm" block onClick={buscar}>
                                            <i className="fa fa-search" aria-hidden="true"></i> Buscar
                                        </Button>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Button color="success" size="sm" block onClick={() => { buscarpdf() }}>
                                            <i className="fa fa-file-excel" aria-hidden="true"></i> PDF 2
                                        </Button>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Button color="success" size="sm" block onClick={exportarExcel}>
                                            <i className="fa fa-file-excel" aria-hidden="true"></i> Exportar
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3}>

                                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                                        <div className="card-body p-2">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col-12">
                                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                                        Movimientos Totales
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totaltotal())}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={3}>

                                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                                        <div className="card-body p-2">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col-12">
                                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                                        Total Saldo
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totaltotalsaldo())}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={3}>

                                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                                        <div className="card-body p-2">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col-12">
                                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                                        Total Credito
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totaltotalcr())}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={3}>

                                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                                        <div className="card-body p-2">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col-12">
                                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                                        Total Debito
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totaltotaldb())}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={3}>

                                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                                        <div className="card-body p-2">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col-12">
                                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                                        Total DBCR
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totaltotaldbcr())}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={3}>

                                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                                        <div className="card-body p-2">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col-12">
                                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                                        Total DBCR con saldo
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totaltotalbrcrvivo())}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                            <hr></hr>
                            <Row>

                                <Col sm="12">
                                    <DataTable
                                        progressPending={pendiente}
                                        columns={columns}
                                        data={ventas}
                                        customStyles={customStyles}
                                        pagination
                                        paginationComponentOptions={paginationComponentOptions}
                                    />


                                </Col>

                            </Row>

                        </CardBody>
                    </Card>
                </Col>
                <Modal size="lg" style={{ maxWidth: '1600px', width: '95%' }} isOpen={editarorden}>

                    <ModalBody >

                        <Movimientos caja={props.caja} permisosusuario={props.permisosusuario} movimiento={movimientoenvio} cerrar={() => {
                            seteditarorden(false)
                            buscar()
                        }} editarorden={editarorden} servidor={props.servidor} />

                    </ModalBody>
                    <ModalFooter>

                        <Button size="lg" color="primary" onClick={() => {
                            seteditarorden(false)
                            buscar()
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                <Filtro
                    settipodocumentoinicioseleccionado={(e) => { settipodocumentoinicioseleccionado(e) }}
                    settipodocumentofinseleccionado={(e) => { settipodocumentofinseleccionado(e) }}
                    setterceroinicioseleccionado={(e) => { setterceroinicioseleccionado(e) }}
                    settercerofinseleccionado={(e) => { settercerofinseleccionado(e) }}
                    settipotercero={(e) => { settipotercero(e) }}


                    tipodocumentoinicioseleccionado={tipodocumentoinicioseleccionado}
                    tipodocumentofinseleccionado={tipodocumentofinseleccionado}
                    tipotercero={tipotercero}
                    terceroinicioseleccionado={terceroinicioseleccionado}
                    tercerofinseleccionado={tercerofinseleccionado}

                    servidor={props.servidor} verModal={filtros} cerrarModal={() => { setfiltros(false) }} />

            </Row>


        </>
    )
}

export default ReporteVenta;