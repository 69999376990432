import React, { useState } from "react";
import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const HelpButton = (props) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div >
            <Button   color="warning" size="sm" style={{color:'black'}}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                Ayuda
            </Button>
            {showTooltip && (
                <div
                  
                    style={{
                        position: "absolute",
                        top: "100%",
                        left: "50%",
                        transform: "translateX(-50%)",
                        padding: "8px",
                        backgroundColor: "black",
                        color: "white",
                        borderRadius: "4px",
                        zIndex: 1
                    }}
                >
                    {props.mensaje}
                </div>
            )}
        </div>
    );
};

export default HelpButton;
