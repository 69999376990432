import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
import { Autocomplete, TextField } from '@mui/material';

const modeloPais = {
    codigo: "",
    nombre: "",
}
const Pais = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Pais, setPais] = useState(modeloPais);
    const [Paiss, setPaiss] = useState([]);
    const [codigo, setcodigo] = useState([]);
    const [codigos, setcodigos] = useState([]);
    const [nombre, setnombre] = useState([]);
    const [nombres, setnombres] = useState([]);
    const [fila, setfila] = useState(0);
    const [usuario, setDataUser] = useState([]);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [roles, setRoles] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [tipodocumentos, settipodocumentos] = useState([]);
    const [fechaInicio, setFechaInicio] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));

    const [verModalPais, setVerModalPais] = useState(false);
    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _Pais = { ...Pais }
        _Pais[e.target.name] = value
        setPais(_Pais)
        setRoles([])


    }
    const obtenerPaiss = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/TurnoApi/abiertosporusuario/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setPaiss(data)
                setPendiente(false)
            } else {
                setPendiente(false)

            }
        } catch (error) {
            console.log(error)
        }
    }
    const obtenercomprabante = async (coincidencia, row) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/TurnoApi/comprobantediario/' + row.id + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                const bytes = atob(data.comprobante);

                // Crear un arreglo de bytes a partir de la cadena decodificada
                const byteArray = new Uint8Array(bytes.length);
                for (let i = 0; i < bytes.length; i++) {
                    byteArray[i] = bytes.charCodeAt(i);
                }

                // Crear un Blob a partir del arreglo de bytes
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                // Crear una URL para el Blob
                const url = window.URL.createObjectURL(blob);
                // Crear un enlace para descargar el archivo PDF
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'comprobante_diario_' + fechaInicio + '.pdf');
                // Simular clic en el enlace para iniciar la descarga
                document.body.appendChild(link);
                link.click();
                // Liberar el objeto URL creado
                window.URL.revokeObjectURL(url);
                await obtenerPaiss(usuario.usuario.cedula);
                setPais(modeloPais)
                setVerModalPais(!verModalPais);
                setKey('Datos')
                Swal.fire(
                    'Guardado Exitosamente!',
                    'El Turno fue cerrado exitosamente',
                    'success'
                )
            } else {

            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        obtenerPaiss(dt.usuario.cedula);
        buscartipodocumentos();

    }, [])

    const buscartipodocumentos = async () => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Fatipodocapi/?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                settipodocumentos(data)
            }
        } catch (error) {
            console.log(error)
        }

    }
    const columns = [
        {
            name: 'Consecutivo',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Usuario',
            selector: row => row.usuario,
            sortable: true,
        },
        {
            name: 'Caja',
            selector: row => row.caja,
            sortable: true,
        },
        {
            name: 'Fecha Hora Inicio',
            selector: row => row.fechainicio,
            sortable: true,
        },
        {
            name: 'Fecha Hora Fin',
            selector: row => row.fechafin,
            sortable: true,
        },
        {
            name: 'Estado',
            selector: row => row.estado,
            sortable: true,
        },
        {
            name: 'Tipo de Documento',
            selector: row => row.tipodocumento,
            grow: 4,
            sortable: true,
            cell: row => (
                <>
                    <Autocomplete
                        onChange={(e, value) => {
                            if (value) {
                                var _Turnos = Paiss.map((dato, index) => {
                                    if (dato.id == row.id) {
                                        var dat = {
                                            ...dato,
                                            ['tipodocumento']: value.codigo
                                        }
                                        return dat
                                    } else {
                                        return dato
                                    }
                                })
                                setPaiss(_Turnos)
                            }


                        }}
                        options={tipodocumentos}
                        getOptionLabel={(clasi) => clasi.codigo + " - " + clasi.descripcion}
                        sx={{ width: 250 }}
                        value={tipodocumentos.find(p => p.codigo === row.tipodocumento) || null}
                        renderInput={(params) => <TextField {...params} label="Inicio" />}
                    />

                </>
            ),
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => {
                            if (row.id == 0) {
                                guardarCambios('abierto', row)
                            }
                        }}
                    >
                        <i className="fas fa-pen-alt">Abrir</i>
                    </Button>

                </>
            ),
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="danger" size="sm" className="mr-2"
                        onClick={() => {
                            if (row.id != 0) {
                                guardarCambios('cerrado', row)

                            }
                        }}
                    >
                        <i className="fas fa-pen-alt">Cerrar</i>
                    </Button>

                </>
            ),
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="info" size="sm" className="mr-2"
                        onClick={() => { }}
                    >
                        <i className="fas fa-pen-alt">PDF</i>
                    </Button>

                </>
            ),
        }
    ];
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const abrirEditarModalPais = (data) => {
        var enviodata = { ...data }
        var _Pais = {
            codigo: enviodata.codigo,
            nombre: enviodata.nombre,
        }
        setPais(_Pais);
        setVerModalPais(!verModalPais);
        seteditar(true)
    }
    const cerrarModalPais = () => {
        setPais(modeloPais)
        setVerModalPais(!verModalPais);
        seteditar(false)
        setKey('Datos')
    }
    const guardarCambios = async (tipo, row) => {
        let response;
        var _turno = Paiss[0]
        _turno.estado = tipo
        if (_turno.tipodocumento != "") {
            response = await fetch("" + props.servidor + "/TurnoApi?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(_turno)
            })
            if (response.ok) {
                if (tipo == "cerrado") {
                    await obtenercomprabante('', row);
                } else {
                    await obtenerPaiss(usuario.usuario.cedula);
                    setPais(modeloPais)
                    setVerModalPais(!verModalPais);
                    setKey('Datos')
                    Swal.fire(
                        'Guardado Exitosamente!',
                        'El Turno fue abierto exitosamente',
                        'success'
                    )
                }

            } else {

                Swal.fire(
                    'Error al Guardar!',
                    'mensaje',
                    'error'
                )
            }
        } else {
            Swal.fire(
                'Error al Guardar!',
                'Debe seleccionar un Tipo de Documento',
                'error'
            )
        }

    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Turnos
                </CardHeader>
                <CardBody>
                    <div className="col-sm-6" >
                        <div className="input-group input-group-sm mb-3" >
                            <div className="input-group-prepend" >

                                <input type="text" id="buscardataPaiss" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerPaiss(usuario.usuario.cedula)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                var turno = {
                                    id: 0,
                                    caja: props.caja,
                                    fechainicio: '',
                                    fechafin: '',
                                    estado: 'abierto',
                                    tipodocumento: '',
                                    campo: '',
                                    campo2: '',
                                    campo3: '',
                                    usuario: usuario.usuario.cedula
                                }
                                if (Paiss.length == 0) {
                                    setPaiss([turno])

                                }
                            }}>Nuevo Turno</Button>
                            <Col sm={1}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#FE0000', color: 'black' }} block onClick={() => { props.setcrearpais(false) }} >
                                    <i className="fa fa-times"></i></Button>
                            </Col>
                        </div>
                    </div>


                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={Paiss}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                        noDataComponent="No hay Turno Abierto, Abralo"
                    />
                </CardBody>
            </Card>

        </>
    )
}
export default Pais;
