import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { UserContext } from '../../../context/UserProvider';
import Swal from 'sweetalert2'
import { useState } from 'react';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import AnalisisdeCartera from './AnalisisdeCartera'
import EstadoDeCuenta from './EstadoDeCuenta'
import EstadoDeCuentainmobiliaria from './EstadoDeCuentainmobiliaria'
import EstadoDeCuentaProv from './EstadoDeCuentaProv'
import PagosCtabonoCompleto from './PagosCtabonoCompleto'
import Iniciobotones from './iniciobotones'

const modelo = {
    nombre: "",
    correo: "",
    idRolNavigation: {
        idRol: 0,
        descripcion: ""
    }
}

const Reportes = (props) => {
    const { user, cerrarSession } = useContext(UserContext)
    const [key, setKey] = React.useState('Inicio');
    const [producto, setproducto] = React.useState(false);
    const [usuario, setusuario] = React.useState(false);
    const [categoria, setcategoria] = React.useState(false);
    const [dashboard, setdashboard] = React.useState(false);
    const [reporteAnalisisdeCartera, setreporteAnalisisdeCartera] = React.useState(false);
    const [reportepagosctabonocompleto, setreportepagosctabonocompleto] = React.useState(false);
    const [estadodecuenta, setestadodecuenta] = React.useState(false);
    const [estadodecuentainmobiliaria, setestadodecuentainmobiliaria] = React.useState(false);
    const [estadodecuentaprov, setestadodecuentaprov] = React.useState(false);
    const [reporteCatalogoArticulos, setreporteCatalogoArticulos] = React.useState(false);
    const [reporteListadePrecio, setreporteListadePrecio] = React.useState(false);
    const [reporteCostoMercanciaVendida, setreporteCostoMercanciaVendida] = React.useState(false);
    const [reporteLibroAuxiliar, setreporteLibroAuxiliar] = React.useState(false);
    
    // const [reporteVenta, setreporteVenta] = React.useState(false);

    if (user == null) {
        return <Navigate to="/Login" />
    }



    return (
        <>
            {/*Content Wrapper*/}
            <div id="content-wrapper" className="d-flex flex-column">

                {/*Main Content*/}
                <div id="content">

                    <div className="container-fluid">

                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className=" pestañas"
                        >

                            <Tab eventKey="Inicio" title="Inicio">
                                <Iniciobotones setreporteAnalisisdeCartera={() => {
                                    setreporteAnalisisdeCartera(true)
                                    setKey('Analisis de Cartera')
                                }} setreportepagosctabonocompleto={() => {
                                    setreportepagosctabonocompleto(true)
                                    setKey('Recaudos')
                                }}
                                setestadodecuentainmobiliaria={() => {
                                    setestadodecuentainmobiliaria(true)
                                    setKey('Estado Cuenta Inmo')
                                }}
                                    setestadodecuenta={() => {
                                        setestadodecuenta(true)
                                        setKey('Estado de Cuenta')
                                    }}
                                    setestadodecuentaprov={() => {
                                        setestadodecuentaprov(true)
                                        setKey('Estado de Cuenta Proveedor')
                                    }} />
                            </Tab>
                            {reporteAnalisisdeCartera &&
                                <Tab eventKey="Analisis de Cartera" title="Analisis de Cartera">
                                    <AnalisisdeCartera servidor={props.servidor} permisosusuario={props.permisosusuario} setreporteAnalisisdeCartera={(reporteAnalisisdeCartera) => {
                                        setreporteAnalisisdeCartera(reporteAnalisisdeCartera)
                                    }} />
                                </Tab>
                            }
                            {estadodecuenta &&
                                <Tab eventKey="Estado de Cuenta" title="Estado de Cuenta">
                                    <EstadoDeCuenta servidor={props.servidor} permisosusuario={props.permisosusuario} setestadodecuenta={(reporteAnalisisdeCartera) => {
                                        setestadodecuenta(reporteAnalisisdeCartera)
                                    }} />
                                </Tab>
                            }
                            {estadodecuentainmobiliaria &&
                                <Tab eventKey="Estado Cuenta Inmo" title="Estado Cuenta Inmo">
                                    <EstadoDeCuentainmobiliaria servidor={props.servidor} permisosusuario={props.permisosusuario} setestadodecuentainmobiliaria={(reporteAnalisisdeCartera) => {
                                        setestadodecuentainmobiliaria(reporteAnalisisdeCartera)
                                    }} />
                                </Tab>
                            }
                             {estadodecuentaprov &&
                                <Tab eventKey="Estado de Cuenta Proveedor" title="Estado de Cuenta Proveedor">
                                    <EstadoDeCuentaProv servidor={props.servidor} permisosusuario={props.permisosusuario} setestadodecuenta={(reporteAnalisisdeCartera) => {
                                        setestadodecuentaprov(reporteAnalisisdeCartera)
                                    }} />
                                </Tab>
                            }
                            {reportepagosctabonocompleto &&
                                <Tab eventKey="Recaudos" title="Recaudos">
                                    <PagosCtabonoCompleto servidor={props.servidor} permisosusuario={props.permisosusuario} setreportepagosctabonocompleto={(setreportepagosctabonocompleto) => {
                                        setreportepagosctabonocompleto(reportepagosctabonocompleto)
                                    }} />
                                </Tab>
                            }


                        </Tabs>

                        {/* <Outlet /> */}

                    </div>
                </div>

            </div>


        </>
    )
}

export default Reportes